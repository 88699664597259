.header-subnav--wrapper {
  ~* {
    @include bp(lg) {
      position: relative;
      z-index: 1;
    }
  }
}

.header-subnav--container {
  min-height: 697px;
  padding: 0 0 60px;
  background-repeat: no-repeat;
  background-size: cover;
  color: $c-white;
  position: relative;

  &::before {
    background: $c-black-40;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &.black {

    &::before {
      background: rgba($c-white, 0.6);
    }

    .header-subnav--intro {

      .title {
        color: $c-black;
      }

      .text {
        color: $c-black;
      }
    }
  }

  &.no-overlay {
    &::before {
      display: none;
    }
  }

  @include bp(lg) {
    min-height: 500px;
    padding-bottom: 105px;
    //margin-bottom: -60px;
  }

  .breadcrumbs-wrapper {
    margin-left: 0px;

    .breadcrumbs--item {
      a {
        color: $c-white;
      }

      img {
        filter: brightness(0) invert(1);
      }

      &:last-child {
        background: -webkit-linear-gradient(180deg, transparent, white 30%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

.header-subnav--content {
  @include container-inset;
  padding-top: 161px;
  margin-top: -161px;
  position: relative;
}

.header-subnav--item {
  padding-top: 11px;
  display: flex;
  justify-content: space-between;

  >* {
    @include animate-element('text', '.header-subnav--item');
    @include animate-delay-exact(1 4);
  }
}

.header-subnav--intro {
  flex: 1;

  >* {
    @include animate-element('text', '.header-subnav--intro');
    @include animate-delay(3);
  }

  .title {
    font-family: Teodor;
    font-size: 60px;
    line-height: 62px;
    margin-bottom: 32px;
  }

  .text {
    font-family: Spezia;
    font-size: 16px;
    line-height: 28px;
    max-width: 501px;
    max-height: 112px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 43px;
  }

  .link {
    .btn {
      display: inline-block;
    }
  }
}

.header-subnav--nav-wrapper {
  display: none;
  width: 250px;

  @include bp(lg) {
    display: block;
  }

  .active {
    font-family: Spezia;
    font-size: 11px;
    font-weight: 600;
    text-align: right;
    letter-spacing: 1px;
    margin-right: 20px;
    margin-bottom: 20px;
    line-height: 20px;
    text-transform: uppercase;
  }
}

.header-subnav--nav {
  max-height: 250px;
  overflow-y: auto;
  padding-right: 20px;
  scrollbar-color: $c-white rgba($c-white, 0.3);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    width: 10px;
    padding: 0px;
    border-radius: 9999px;
    background-color: rgba($c-white, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border: 0px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: $c-white;
  }

  div {
    font-family: Spezia;
    font-size: 11px;
    text-align: right;
    letter-spacing: 1px;
    margin-bottom: 25px;
    line-height: 20px;
    text-transform: uppercase;

    a {
      @include animate-underline($c-white, 4px);
      color: $c-white;
      text-decoration: none;
      padding-bottom: 10px;
      text-transform: none;
      font-family: Spezia;
      font-size: 16px;
      line-height: 28px;

      &.active {
        background-image: linear-gradient($c-white, $c-white);
        background-size: 100% 4px;
        background-position: 0 100%;
        background-repeat: no-repeat;
        font-size: 16px;
        font-weight: normal;
        letter-spacing: normal;
        margin: 0;
        line-height: 28px;
        text-transform: none;
      }
    }
  }
}

.header-subnav--outside-nav {
  @include container;
  background: $c-gray-soft;
  margin: 0;
  padding: 60px 30px;
  display: flex;
  justify-content: center;

  @include bp(lg) {
    display: none;
  }

  >* {
    @include animate-element('text', '.header-subnav--outside-nav');
    @include animate-delay(1);

    @include bp(md) {
      @include animate-delay(1, 3);
    }
  }

  .header-subnav--filters {
    width: 100%;
    height: 38px;
    border: 0.5px solid $c-black;
    overflow: hidden;

    @include bp(lg) {
      position: relative;
      width: 85%;
      height: auto;
      overflow: hidden;
      display: flex;
      border: 0;

      // Fadeout edge/cutoff.
      &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: 100px;
        height: 100%;
        background: linear-gradient(90deg, transparent, $c-gray-soft 75%);
      }
    }

    button,
    a {
      @extend .btn-tag;
      @extend .btn-tag--black;

      @include bp(lg) {
        display: block;
        width: auto;
        margin-right: 13px;
        margin-bottom: 13px;
        flex-shrink: 0;

        &:last-of-type {
          margin-right: 0;
          border-bottom: 0.5px solid $c-black;
        }
      }

      &.mobile-label {
        @extend %btn;
        @include caret(12px);
        display: flex;
        color: $c-black;
        font-weight: $font-weight-medium  !important;
        font-size: 11px !important;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        text-transform: uppercase !important;
        width: 100%;

        ~button,
        ~a {
          width: 100%;

          @include bp(lg) {
            width: auto;
          }
        }

        &::after {
          position: static;
        }

        &:focus {
          color: $c-black;
        }

        &:hover {
          &::after {
            transform: none;
          }
        }
      }
    }

    &.active {
      button.mobile-label {
        &::after {
          transform: rotate(-180deg);
        }
      }
    }
  }
}

// Adjusted Padding 10.25.24 - BM
