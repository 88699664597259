.our-values--container {
  @include container;
  @include spacer-component;
  position: relative;
  padding-top: 108px;
  padding-bottom: 93px;
  margin-bottom: 0;

  @include bp(lg) {
    padding-top: 152px;
    padding-bottom: 140px;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
  }

  > .our-values--texts {
    > * {
      @include animate-element(
        'text',
        '.our-values--container > .our-values--texts'
      );
      @include animate-delay(2, 6);
    }
  }
}

.our-values--content {
  > * {
    @include animate-element('text', '.our-values--content');
    @include animate-delay(7);
  }
}

.our-values--wrapper {
  background-color: $c-gray-soft;
}

.our-values--item-title {
  &:not(:last-child) {
    margin-bottom: 32px;
  }

  @include bp(lg) {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  .our-values--texts {
    position: static;
    display: block;
    transition: max-height 0.175s;
    max-height: 0;
    max-width: none;
    overflow: hidden;

    > * {
      @include animate-element('text', '.our-values--texts');
      @include animate-delay(2);
    }

    br {
      display: none;
    }

    @include bp(lg) {
      display: none;
    }
  }

  .title {
    display: block;
    cursor: pointer;
    color: $c-gray-lighter;
    font-family: Teodor;
    font-size: 40px;
    line-height: 54px;
    position: relative;
    transition: all 0.28s ease;

    @include bp(lg) {
      font-size: 100px;
      line-height: 160px;
    }

    &:hover {
      color: black;
    }

    .drawline {
      height: 1px;
      position: absolute;
      width: 15px;
      margin-left: -30px;
      top: 50%;
      transform: translateY(-50%);

      @include bp(lg) {
        width: 100px;
        margin-left: -120px;
      }

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        width: 0;
      }

      &:before {
        transition: width 0s ease, background 0.28s ease;
        left: 0;
        right: 0;
      }

      &:after {
        right: 2.2%;
        background: $c-blue-primary;
        transition: width 0.28s ease;
      }
    }

    .counter {
      position: absolute;
      opacity: 0;
      transition: all 0.28s ease;
      font-family: Spezia;
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
      top: -30%;

      @include bp(lg) {
        left: 5px;
        top: 15px;
      }
    }
  }

  &.active {
    .title {
      color: $c-blue-primary;

      .drawline {
        &:before {
          width: 97.8%;
          background: $c-blue-primary;
          transition: width 0.28s ease;
        }

        &:after {
          width: 97.8%;
          background: 0 0;
          transition: all 0s ease;
        }
      }

      .counter {
        opacity: 1;
      }
    }

    .our-values--texts {
      max-height: 9999px;
      margin-top: 20px;
      transition: max-height 0.84s 0.14s ease;
    }
  }
}

.our-values--texts {
  position: absolute;
  display: none;
  max-width: 265px;
  right: 0;
  top: 525px;

  br {
    display: none;
  }

  @include bp(lg) {
    display: block;
  }

  .subtitle {
    overflow: hidden;
    font-family: $font-family-serif;
    font-size: 27px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 2 * 48px;
  }

  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    max-height: 6 * 28px;
  }
}
