.video-text--brightcove {
  max-width: 1147px;
  width: 100%;
  margin: 0 auto;
}

.video-text-wrapper {
  position: relative;
}

.video-text--embed-container {
  width: 100%;
  height: 247px;
  overflow: hidden;
  margin-left: 0;

  @include bp(xl) {
    height: 449px;
  }

  @include bp(xl-large) {
    margin-left: 0px;
  }

  .video-embed-wrapper {
    padding: 0px;
    height: 100%;

    a {
      height: 449px;
      justify-content: end;
    }
  }

  img {
    width: auto;
    height: 247px;

    @include bp(sm) {
      width: 987px;
      height: auto;
    }

    @include bp(xl-large) {
      width: 1147px;
    }
  }

  .video-embed__play {
    left: 30px;
    bottom: 254px;

    @include bp(sm) {
      bottom: 224px;
    }

    @include bp(xl) {
      left: 80px;
    }

    &-text {
      display: block;
    }
  }
}

.video-text--description {
  font-family: Spezia;
  background-color: $c-white;
  max-width: 1147px;
  padding: 30px;

  @include bp(xl) {
    margin-top: -125px;
    padding: 50px;
    padding-left: 40px;
    position: relative;
    width: 942px;
    z-index: 1;
  }

  @include bp(xl-large) {
    padding-left: 0;
    width: 1024px;
  }

  > * {
    @include animate-element('text', '.video-text--description');
    @include animate-delay(12, 1);
  }

  h3 {
    font-size: 24px;
    line-height: 40px;
  }

  p {
    font-size: 16px;
    line-height: 28px;
    max-width: 785px;
  }
}
