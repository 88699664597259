.social-share {
  padding: 0 20px;
  position: fixed;
    left: calc(((100vw - 1128px) / 2) + 1038px);
    top: 150px;
  z-index: 4;

  @media (max-width: 1127px){
    left: calc(100vw - 58px);
    right: 0;
  }

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin: 0 auto;
    max-width: 1128px;
    position: relative;
  }

  &__link-container {
    position: absolute;
    right: 0;
    top: 0;
  }

  a {
    border-radius: 50%;
    display: flex;
    height: 50px;
    width: 50px;

    @include bp(lg) {
      border: 0.5px solid $c-black;
      margin: 22px 0px;
    }

    img {
      margin: auto;
      filter: unset;
    }
  }

  &--white {
    @include bp(lg) {
      border: 0.5px solid $c-white !important;
    }

    img {
      filter: brightness(0) invert(1) !important;
    }
  }
}

.social-share--inner-content {
  .st-custom-button {
    margin: 10px 0;
    cursor: pointer;

    img {
      filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(94deg)
        brightness(110%) contrast(110%);
    }
  }
}

@each $section, $filter in $colorFilter {
  .page-container[data-section='#{$section}'] {
    .st-custom-button:hover {
      img {
        filter: $filter;
      }
    }
  }
}
