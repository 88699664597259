.map {
  @include spacer-component;
  color: $c-white;
  padding: 150px 0;

  @include bp(lg) {
    margin-bottom: 0;
  }

  &-container {
    background: $c-black;
  }
}

.map .map--wrapper {
  @include bp(xl) {
    @include container-inset;
  }
}

.map .map--wrapper .map--title {
  @include container-inset;

  @include bp(xl) {
    width: 100%;
    max-width: none;
  }
}

.map .map--wrapper .map--title .heading1 {
  @include animate-element('text', '.map--title');

  @include bp(lg) {
    display: block;
    max-width: 560px;
  }
}

.map .map--container {
  overflow: hidden;
  overflow-x: auto;
  scrollbar-color: $c-white $c-gray-light;
  scrollbar-width: thin;
  white-space: nowrap;

  @include bp(xl) {
    margin-bottom: 60px;
  }

  &::-webkit-scrollbar {
    background: $c-gray-light;
    border-radius: 30px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $c-white;
    border-radius: 30px;
  }

  @include bp(lg) {
    overflow: hidden;
  }
}

.map--overflow {
  transition-delay: 0.35s;
  margin-top: 30px;
  position: relative;
  display: block;
  width: 1332px;
  height: 650px;

  > img {
    transition: filter 0.14s linear;
    min-width: 960px;
    min-height: 454px;
  }

  #map {
    transition: opacity 0s 0.14s linear;
    // margin-top: 30px;
  }

  .map__close {
    @extend %button-txt;

    align-items: center;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    opacity: 0;
    position: relative;
    max-width: 100%;
    transition: opacity 0.3s;
    top: -30px;
    z-index: 1;

    img {
      margin: 0 0 0 10px;
    }

    .active & {
      // opacity: 1;
    }
  }

  &.active {
    width: auto;

    @include bp(md) {
      width: 100%;
    }

    > img {
      filter: blur(5px);
    }

    #map {
      opacity: 1 !important;
      pointer-events: all;
    }

    .map--marker {
      display: none;
    }
  }

  div[role='dialog'] {
    border-radius: 0px;
    padding: 32px !important;

    .gm-style-iw-d {
      overflow: unset !important;
    }

    > button {
      display: none !important;
    }
  }

  .map-tooltip {
    color: black;

    .city {
      font-family: Spezia;
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 17px;
    }

    .place {
      font-family: Spezia;
      font-size: 13.5px;
      line-height: 1.4;
      margin-bottom: 12px;

      a {
        @include animate-underline(black);
      }
    }

    .phone {
      font-family: Spezia;
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 25px;

      color: $c-blue-primary;

      a {
        @include animate-underline($c-blue-primary);
      }
    }

    .authors {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
      letter-spacing: 1px;
      display: flex;
      align-items: center;

      @include bp(md) {
        margin-top: 0px;
      }

      span {
        @extend %button-txt;
        display: block;
        margin-left: 25px;
      }

      .author {
        position: relative;
        width: 30px;
        height: 30px;
        margin-right: -15px;

        img {
          border-radius: 50%;
          height: 100%;
          object-fit: cover;
          width: 100%;
        }

        &:nth-of-type(1) {
          z-index: 3;
        }

        &:nth-of-type(2) {
          z-index: 2;
        }
      }
    }
  }
}

.map .map--container > img {
  @include animate-element('text', '.map--overflow');
  width: 100%;
  height: 100%;

  @include bp(xl) {
    position: static;
    width: auto;
    height: auto;
    object-fit: contain;
    object-position: unset;
  }
}

.map .map--drag {
  @extend %button-txt;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 80px;

  @include bp(xl) {
    display: none;
  }
}

.map .map--drag svg {
  width: 16px;
  height: 16px;

  &:first-of-type {
    transform: rotate(180deg);
  }
}

.map .map--drag p {
  font-size: 10px;
  line-height: 2;
  margin: 0 16px 0;
}

.map .map--content {
  @include intro-txt;
  @include container-inset;

  @include bp(xl) {
    width: 100%;
    max-width: none;
  }
}

.map .map--content > * {
  @include animate-element('text', '.map--content');
}

.map .map--content-txt > * {
  @include animate-element('text', '.map--content-txt');
}

.map .map--content p {
  @extend %copy--sm;
}

#map {
  position: absolute;
  opacity: 0 !important;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: opacity 0.7s ease;
}

.visually-hidden {
  visibility: hidden;
  width: 0;
  height: 0;
  overflow: hidden;
}

.map__marker-text {
  font-size: 20px;
  padding: 5px;
}

.map--marker {
  position: absolute;
  width: 10px;
  height: 10px;
  padding: 5px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    background-color: white;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: 0;
    left: 0;
    animation: pulse 1.4s infinite;
    transform-origin: center center;
  }

  &:before {
    content: '';
    position: absolute;
    border: 0px solid rgba(255, 255, 255, 0.5);
    width: 0px;
    height: 0px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: top 0.28s linear, border-width 0.28s linear, left 0.28s linear;
  }

  &:hover {
    &:before {
      border-width: 25px;
    }

    &:after {
      animation: none;
      width: 10px;
      height: 10px;
    }
  }
}

[data-tippy-root] {
  font-family: Spezia;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  25% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(4.5);
    opacity: 0;
  }
}
