.tabination--content {
  display: flex;
  @include container-inset;

  > * {
    @include animate-element('text', '.tabination--content');
    @include animate-delay(2);
  }

  nav {
    position: relative;
    padding-bottom: 24px;
    overflow-x: auto;

    .line {
      height: 4px;
      position: absolute;
      bottom: 0;
      margin: 24px 0 0 0;
      background: $c-blue-primary;
      transition: right 0.28s 0s ease, left 0.28s 0s ease;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      max-width: 100%;
      overflow-x: auto;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }

      li {
        cursor: pointer;
        display: inline-block;
        margin: 0 34px 0 0;
        font-family: Spezia;
        font-size: 16px;
        line-height: 28px;
        transition: all 0.28s ease;

        &:hover {
          color: $c-gray-light-fade;
        }

        &.active {
          color: $c-blue-primary;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.tabination--tabs {
  margin-top: 100px;

  .tab {
    display: none;

    &.active {
      display: block;
    }

    .statistics-content,
    .infographic-wrapper,
    .split-text-image-wrapper {
      padding-top: 0px;
    }
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .tabination--content {
      nav {
        .line {
          background: $color;
        }

        ul {
          li {
            &.active {
              color: $color;
            }
          }
        }
      }
    }
  }
}
