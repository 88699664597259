.about-press-release {
  background: $c-white;
  padding: 75px 0 0;
  position: relative;

  .layout-container {
    @include container-inset;

    > * {
      @include animate-element('text', '.layout-container');
      @include animate-delay(2);
    }
  }

  &__content {
    margin: 0 0 100px;
  }
}
