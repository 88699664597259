.header-podcast--wrapper {
  position: relative;

  @include bp(md) {
    margin-top: 30px;
  }
}

.header-podcast--content {
  @include bp(sm) {
    @include container-inset;
  }
}

.header-podcast--item {
  display: flex;
  flex-direction: column;

  @include bp(lg) {
    flex-direction: row;
  }

  iframe {
    max-width: 480px;
  }

  .text-container {
    > * {
      @include animate-element('text', '.text-container');
      @include animate-delay(6);
    }

    margin: 32px 0;
    padding: 0px 30px;
    width: 100%;

    @include bp(sm) {
      margin-bottom: 88px;
      padding: 0px;
    }

    @include bp(md) {
      margin-top: 0;

      padding: 0 30px 0 0;
    }

    @include bp(lg) {
      width: 500px;
    }

    @include bp(xxl) {
      width: 529px;
    }

    h1 {
      font-family: Teodor;
      color: $c-purple-primary;
      font-size: 34px;
      line-height: 42px;
      margin-bottom: 62px;

      @include bp(lg) {
        font-size: 60px;
        line-height: 62px;
        margin-bottom: 42px;
      }
    }

    .subtitle {
      margin-top: 41px;
      font-family: Spezia;
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 1px;
      text-transform: uppercase;

      svg {
        margin-right: 7px;
      }
    }
  }

  .image-container {
    > * {
      @include animate-element('text', '.image-container');
      @include animate-delay(3, 6);
    }

    position: relative;
    width: 100%;
    min-height: 235px;

    @include bp(sm) {
      min-height: 351px;
    }

    @include bp(lg) {
      width: 392px;
      min-height: 463px;
    }

    &__image {
      width: 100%;

      &--mobile {
        @include bp(lg) {
          display: none;
        }
      }

      &--desktop {
        display: none;
        height: 463px;

        @include bp(lg) {
          display: block;
        }
      }

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .image-desc {
      width: 225px;
      height: 198px;
      position: absolute;
      bottom: -100px;
      right: 29px;
      background-color: $c-purple-primary;
      color: $c-white;
      padding: 25px;

      @include bp(md) {
        bottom: auto;
        top: -95px;
        width: 270px;
        height: 189px;
        right: 0;
      }

      @include bp(lg) {
        top: calc(463px - 95px);
      }

      .image-title {
        font-family: Spezia;
        font-weight: 600;
        font-size: 11px;
        line-height: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 14px;
      }

      .image-txt {
        font-family: Spezia;
        font-size: 16px;
        line-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 112px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
      }
    }
  }
}

.header-podcast--hoster {
  margin-top: 26px;

  .hoster-title {
    margin-bottom: 10px;
    font-family: Spezia;
    font-size: 16px;
    line-height: 28px;
  }

  .hoster-person {
    cursor: pointer;
    display: flex;
    align-items: center;

    span {
      @extend %button-txt;
      display: block;
      margin-left: 25px;
    }

    .author {
      position: relative;
      width: 30px;
      height: 30px;
      margin-right: -15px;

      img {
        border-radius: 50%;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }

      &:nth-of-type(1) {
        z-index: 3;
      }

      &:nth-of-type(2) {
        z-index: 2;
      }
    }
  }

  .hoster-date {
    margin-top: 19px;
    font-family: Spezia;
    font-size: 13.5px;
    line-height: 24px;
  }
}

.header-podcast--category {
  margin-top: 22px;

  @include bp(lg) {
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      width: 100px;
      height: 100%;
      background: linear-gradient(90deg, transparent, $c-white 75%);
    }
  }

  .tag {
    display: inline-block;

    a {
      @extend .btn-tag;
      @extend .btn-tag--black;
      margin-bottom: 13px;
      margin-right: 13px;
    }
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .header-podcast--item {
      .text-container {
        h1 {
          color: $color;
        }
      }

      .image-container {
        .image-desc {
          background-color: $color;
        }
      }
    }
  }
}
