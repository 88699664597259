.square-spotlight {
  padding: 0 0 100px;

  @include bp(md) {
    padding-top: 0;
  }

  .layout-container {
    @include container-inset;
  }

  .spotlights {
    @include bp(lg) {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      padding: 0;
    }

    > * {
      @include animate-element('text', '.spotlights');
      @include animate-delay(4);
    }
  }

  .spotlight {
    margin: 40px 0;

    @include bp(lg) {
      flex: 0 0 50%;
      margin: 45px 0;
      padding: 0 50px;
      width: 50%;
    }

    &__image {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      margin: 0 0 16px;

      img {
        margin: 0 7px 0 0;

        @include bp(lg) {
          margin: 0 13px 0 0;
        }
      }

      h2 {
        margin: 0;

        @include bp(lg) {
          font-size: 27px;
          line-height: 48px;
        }
      }
    }

    &__content {
      @include bp(lg) {
        padding: 0 0 0 36px;
      }
    }
  }
}
