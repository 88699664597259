.pagination {
  background: $c-white;
  position: relative;

  > * {
    @include animate-element('text', '.pagination');
    @include animate-delay(1);
  }

  &__inner {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    min-height: 65px;
    padding: 80px 30px;

    @include bp(lg) {
      padding: 125px 30px;
    }
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 50px;
    margin: 0 5px;
    width: 50px;
    height: 50px;
    background: none;
    border: none;
    position: relative;
    transition: all 0.35s;

    > * {
      position: relative;
      z-index: 1;
    }

    &::after {
      border: 0.35px solid $c-gray-lighter;
      border-radius: 50%;
      content: '';
      display: block;
      height: 50px;
      left: 50%;
      position: absolute;
      transition: all 0.35s;
      transform: translate(-50%, -50%);
      top: 50%;
      width: 50px;
      z-index: 0;
    }

    @include bp(lg) {
      margin: 0 15px;
    }

    img,
    svg {
      filter: brightness(0%);
      width: 16px;
      height: 16px;
    }

    &:hover {
      &::after {
        height: 65px;
        width: 65px;
      }
    }

    &--right {
      img,
      svg {
        transform: rotate(180deg) !important;
      }
    }
  }

  &__items {
    @extend %button-txt;
    align-items: center;
    display: flex;
    color: $c-black;
    font-size: 15px;
    flex-flow: row nowrap;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 5px;
      padding: 0;
      text-align: center;
      width: 32px;

      @include bp(lg) {
        margin: 0 15px;
      }

      a {
        color: $c-black;
        display: block;
        position: relative;
        transition: all 0.35s;
        text-decoration: none;

        &:hover,
        &.selected {
          color: $c-burgundy-light;
        }
      }
    }
  }

  &__underline {
    background: $c-burgundy-light;
    height: 4px;
    opacity: 0;
    position: absolute;
    transition: all 0.35s;
    transform: translateY(32px);

    &.hover {
      transform: translateY(24px);
      opacity: 1;
    }
  }

  &.pagination--no-gutter-top {
    .pagination__inner {
      padding-top: 10px;

      @include bp(lg) {
        padding-top: 10px;
      }
    }
  }

  &.pagination--no-gutter-bottom {
    .pagination__inner {
      padding-bottom: 10px;

      @include bp(lg) {
        padding-bottom: 10px;
      }
    }
  }
}

@each $section, $color in $sectionLightColors {
  .page-container[data-section='#{$section}'] {
    .pagination {
      &__items {
        a {
          &:focus,
          &:hover,
          &.selected {
            color: $color;
          }
        }
      }

      &__underline {
        background: $color;
      }
    }
  }
}
