.button-row {
  position: relative;

  .layout-container {
    @include container-inset;
  }

  &__item {
    display: inline-block;
    padding: 0 26px 30px 0;

    &--black {
      background: $c-black;
    }
  }
}
