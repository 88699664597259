// Concierge trigger.
.concierge--trigger {
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: flex;
  align-items: center;
  z-index: 3;

  @include bp(lg) {
    bottom: 36px;
    right: 36px;
  }
}

.concierge--trigger button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: $c-blue-primary;
  border: 0;
  transition: transform 0.2s;

  &:hover,
  &:focus {
    transform: scale(1.3);
  }
}

.concierge--trigger button .dot {
  display: block;
  background: $c-white;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: pulse 1.75s infinite $easeOutCubic;
}

@keyframes pulse {
  0% {
    transform: scale(0.6);
  }

  50% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(0.6);
  }
}

// Concierge modal.
.concierge--wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $c-gray-soft;
  z-index: 500;
  opacity: 0;
  visibility: hidden;
  transition: all 0.35s $easeOutCubic;

  @include bp(lg) {
    padding: 175px 130px;
  }
}

.concierge--wrapper.is-open {
  opacity: 1;
  visibility: visible;
}

.concierge--wrapper .concierge--progress p {
  @extend %button-txt;
}

.concierge--wrapper .concierge--content {
  width: 100%;
}

.concierge--wrapper .concierge--question,
.concierge--wrapper .concierge--answer input {
  font-family: $font-family-serif;
  font-weight: $font-weight-light;
  font-size: 48px;
  line-height: 1.05;

  @include bp(lg) {
    font-size: 104px;
  }
}

.concierge--wrapper .concierge--answer input {
  background: transparent;
  color: $c-blue-primary;
  margin-top: 10px;
  padding: 10px 0;

  &::placeholder {
    opacity: 0.3;
  }
}

.concierge--wrapper .concierge--input-toggle button {
  width: 56px;
  height: 56px;
  border: 0;
  border-radius: 50%;
  background: none;
  margin-right: 15px;
  transition: all 0.2s $easeOutCubic;

  &:last-of-type {
    margin-right: 0;
  }
}

.concierge--wrapper .concierge--input-toggle button.active {
  background: $c-blue-primary;
}

.concierge--wrapper .concierge--input-toggle button.active svg {
  fill: $c-white;
}

.concierge--wrapper svg {
  width: 24px;
  height: 24px;
  fill: $c-gray-dark;
  pointer-events: none;
  transition: fill 0.2s $easeOutCubic;
}

.concierge--wrapper .concierge--close {
  position: absolute;
  bottom: 35px;
  right: 35px;
}

.concierge--wrapper .concierge--close svg {
  width: 16px;
  height: 16px;
}

.concierge--wrapper .concierge--close p {
  font-family: $font-family-sans;
  font-size: 13px;
  line-height: 2.15;
  margin: 0;
}

.concierge--wrapper .concierge--close button {
  width: 50px;
  height: 50px;
  background: $c-black;
  border-radius: 50%;
  border: 0;
  margin-left: 12px;
}
