.search-results-container {
  .search-filter,
  .page-container {
    transition: visibility 0s, height 0s, all 0.7s ease-in;
    visibility: hidden;
    height: 0;
    opacity: 0;
    overflow: hidden;
  }

  &.results-shown {
    .search-form {
      margin: 0;
      max-height: 240px;
      padding: 0 0 50px;

      @include bp(md) {
        max-height: 250px;
      }

      @include bp(lg) {
        max-height: 300px;
      }
    }

    .search-filter,
    .page-container {
      visibility: visible;
      height: auto;
      opacity: 1;
    }
  }
}
