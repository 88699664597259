.statistics-container {
  background: $c-white;
  color: $c-blue-primary;

  .layout-container {
    max-width: 1260px;
  }

  .statistics-content {
    padding: 100px 30px;
  }

  .stats {
    > * {
      @include animate-element('text', '.stats');
      // TAGRRBUILD-226 - added an arbitrary delay only because I don't know how many stats there will be - tdeluca
      @include animate-delay(6);
    }

    @include bp(lg) {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
    }
  }

  .stat {
    margin: 0 0 56px;

    @include bp-down(lg) {
      &:last-child {
        margin: 0;
      }
    }

    @include bp(lg) {
      flex: 0 0 272px;
      margin: 0 41px;
    }

    &__headline {
      align-items: center;
      display: flex;
      font-family: $font-family-serif;
      font-weight: $font-weight-light;
      font-size: 150px;
      letter-spacing: -10px;
      line-height: 1;

      @include bp(lg) {
        font-size: 200px;
      }
    }

    &__symbol {
      font-size: 50px;
      line-height: 0.8;
      margin: 16px 0 0 8px;

      @include bp(lg) {
        font-size: 80px;
      }
    }
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .statistics-container {
      color: $color;
    }
  }
}

.page-container[data-section='gray'] {
  .statistics-container {
    background-color: $c-gray-soft;
    color: $c-blue-primary;
  }
}

.page-container[data-section='dark'] {
  .statistics-container {
    background-color: $c-black;
    color: $c-white;
  }
}
