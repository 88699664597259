.landing-hero-tall--wrapper {
  position: relative;
}

.landing-hero-tall--container {
  margin: 0 auto;
  max-width: 1200px;
}

.landing-hero-tall--strip {
  height: 125px;
  width: 100%;
  display: none;
  position: absolute;
  top: 0px;
  z-index: -1;

  @include bp(lg) {
    display: block;
  }
}

.landing-hero-tall--image {
  @include animate-element('image', '.landing-hero-tall--image');

  .image {
    height: 463px;
    width: 100%;
    z-index: 1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: none;
    position: relative;
    padding: 40px;

    &.mobile {
      display: flex;

      @include bp(lg) {
        display: none;
      }
    }

    @include bp(md) {
      max-width: 1085px;
    }

    @include bp(lg) {
      display: flex;
      height: 500px;
    }

    a {
      display: block;
      margin-top: auto;
      margin-right: 33px;
      position: relative;
      z-index: 1;

      img {
        width: 21px;
        filter: brightness(0) invert(1);
      }
    }

    .backdrop {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.51) 67.86%
      );
      bottom: 0;
      content: '';
      display: block;
      height: 300px;
      left: 0;
      opacity: 1;
      position: absolute;
      transition: opacity 0.35s;
      right: 0;
      z-index: 0;
    }

    &:focus,
    &:hover {
      @include bp(lg) {
        &::after {
          opacity: 1;
        }
      }
    }
  }
}
