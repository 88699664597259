.consultant-directory--wrapper {
  position: relative;
  min-height: 300px;

  a:focus {
    border: 1px solid black;
  }
}

.consultant-directory--content {
  @include container-inset;

  position: relative;

  > * {
    @include animate-element('text', '.consultant-directory--content');
    @include animate-delay(1);
  }
}

.consultant-directory--input-search {
  background-color: $c-white;
  margin-top: 50px;

  @include bp(lg) {
    z-index: 1;
    top: -48px;
    margin-top: unset;
    width: calc(100% - 270px);
    position: absolute;
    padding: 44px 130px 0 40px;
  }

  .input--animation-container {
    display: block;
    position: relative;
    width: 100%;

    &.active {
      height: 70px;
    }

    @include bp(lg) {
      height: auto;
      margin-top: 5px;
      flex-grow: 1;
    }

    .input--wrapper {
      &:focus-within label,
      &.has-content label {
        margin-top: -25px;
        font-size: 13.5px;
      }

      label {
        pointer-events: none;
        position: absolute;
        font-size: 24px;
        line-height: 40px;
        font-family: Spezia;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: left;
        transition: margin-top 0.35s ease 0s, font-size 0.35s;
      }

      .input {
        padding-right: 25px;
        padding-top: 10px;
        border-bottom: 0.25px solid black;
        height: 60px;
        position: relative;
        font-size: 24px;
        line-height: 40px;
        font-family: Spezia;
        font-style: normal;
        font-weight: normal;

        &::after {
          content: '';
          width: 0;
          height: 3px;
          left: 0;
          background-color: $c-burgundy-primary;
          position: absolute;
          bottom: 0;
          transition: width 0.35s;
        }

        &:focus {
          outline: none;

          &::after {
            width: 100%;
          }
        }
      }
    }

    .icon {
      cursor: pointer;
      pointer-events: none;
      position: absolute;
      bottom: 23px;
      right: 0;
      height: 18px;
      width: 18px;
    }
  }
}

.consultant-directory--data-row {
  align-items: flex-start;
  display: flex;
  padding-top: 55px;

  @include bp(lg) {
    padding-top: 180px;

    > * {
      @include animate-element('text', '.consultant-directory--data-row');
      @include animate-delay(2, 1);
    }
  }
}

.consultant-directory--sidebar-filter {
  display: none;
  width: 275px;
  padding-left: 30px;
  padding-right: 5px;
  margin-left: -30px;
  margin-bottom: 50px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 100px);
  position: sticky;
  top: 50px;

  @include bp(lg) {
    padding-top: 50px;
    padding-bottom: 50px;
    border-right: 1px solid #c2c9d1;
    display: block;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #c2c9d1;
    border: 0px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .filter {
    display: flex;
    flex-wrap: wrap;

    .break {
      width: 100%;
    }

    &:focus,
    &:hover {
      .title {
        background-image: linear-gradient(
          #{$c-purple-primary},
          #{$c-purple-primary}
        );
        background-size: 100% 4px;
        background-position: 0 100%;
        color: $c-purple-primary;

        svg {
          path {
            fill: $c-purple-primary;
          }
        }
      }
    }

    &.active {
      .title {
        background-image: linear-gradient(
          #{$c-burgundy-primary},
          #{$c-burgundy-primary}
        );
        background-size: 100% 4px;
        background-position: 0 100%;
        color: $c-burgundy-primary;

        .item__expand-caret {
          transform: rotate(180deg);

          path {
            fill: $c-burgundy-primary;
          }
        }
      }

      .items {
        height: 100%;
        width: 100%;
        opacity: 1;
        animation: itemsFadeIn 0.2s 0s linear;
        animation-fill-mode: forwards;
      }
    }

    .title {
      // @include animate-underline($c-burgundy-primary, 4px);
      display: inline-block;
      font-family: Spezia;
      font-weight: 600;
      font-size: 11px;
      margin-bottom: 28px;
      padding-right: 8px;
      padding-bottom: 13px;
      line-height: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
      cursor: pointer;
      position: relative;
      border-radius: 0;
      text-decoration: none;
      background-size: 0 4px;
      background-position: 100% 100%;
      transition: background-size 0.1s ease;
      background-repeat: no-repeat;
      background-image: linear-gradient(
        #{$c-purple-primary},
        #{$c-purple-primary}
      );

      svg {
        transition: all 0.2s;
        position: absolute;
        right: 0;
        top: calc(50% - 8px);
        transform: translateY(-50%);
      }
    }

    .items {
      animation: itemsFadeOut 0.14s 0s ease;
      animation-fill-mode: forwards;

      .item {
        font-family: Spezia;
        font-size: 15px;
        line-height: 28px;
        margin-bottom: 30px;
        margin-right: 5px;
        position: relative;
        transition: all 0.2s;

        &__link-row {
          align-items: center;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          margin: 0 0 30px 0;
        }

        &__link {
          color: inherit;
          display: inline-link;
          padding: 0;
          position: relative;
          text-align: left;
          text-decoration: none;
        }

        &__children-items {
          margin-top: 30px;
          padding-left: 30px;
        }

        &__children-item {
          color: $c-black;

          .item__link {
            display: inline-block;
            font-size: 13px;
            line-height: 1.25;
            margin-bottom: 15px;
          }

          &:last-of-type {
            .item__link {
              margin-bottom: 0;
            }
          }

          &.item__children-item--selected {
            color: $c-burgundy-primary;

            .item__close-img {
              display: block;
            }
          }
        }

        &.not-hover {
          color: $c-gray-light-fade;
        }

        &.active {
          color: $c-burgundy-primary;
        }

        &__close-img {
          display: none;
          position: absolute;
          left: -30px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 10;
        }

        &.selected {
          color: $c-burgundy-primary;

          > .item__link {
            .item__close-img {
              display: block;
            }
          }
        }
      }
    }
  }
}

.consultant-directory--consultants {
  align-items: flex-start;
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  @include bp(lg) {
    padding-left: 52px;
    padding-top: 0px;
  }

  .consultant {
    width: 100%;
    margin-bottom: 34px;
    text-align: center;

    @include bp(md) {
      text-align: left;
      width: 50%;
    }

    @include bp(lg) {
      width: 33%;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    .image {
      margin: 0 auto;
      height: 130px;
      width: 130px;
      border-radius: 50%;
      overflow: hidden;

      @include bp(sm) {
        margin: 0;
      }

      img {
        height: 100%;
        object-fit: cover;
        transition: all 0.35s;
        width: 100%;
      }

      &:hover {
        img {
          transform: scale(110%) !important;
          // added !important because animate-element @include overwrites any transform - tdeluca - 07092021
        }
      }
    }

    .name {
      font-family: Spezia;
      font-size: 16px;
      line-height: 28px;
      margin-top: 11px;
    }

    .roles {
      font-family: Spezia;
      font-size: 13.5px;
      line-height: 24px;
      margin-top: 8px;
    }

    .location {
      font-family: Spezia;
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
      margin: 14px auto 0;
      letter-spacing: 1px;
      padding-left: 15px;
      position: relative;
      text-align: left;
      text-transform: uppercase;

      @include bp(md) {
        margin: 16px 0 0;
      }

      &-wrapper {
        display: flex;
        flex-flow: row nowrap;
      }

      img {
        filter: invert(1);
        left: 0;
        margin-right: 7px;
        position: absolute;
        top: 5px;
      }

      span {
        display: block;
      }
    }
  }
}

.consultant-directory--selected-filters {
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 70px;

  @include bp(md) {
    display: flex;
    flex-wrap: wrap;
    padding-right: 175px;
  }

  .filter {
    @extend .btn-tag;
    @extend .btn-tag--black;

    border: 0;
    display: inline-block;
    font-size: 16px;
    line-height: 28px;
    margin-right: 13px;
    margin-bottom: 13px;
    padding: 5px 10px;
    background: #efefef;
    text-align: left;

    a {
      text-decoration: none;
    }

    img {
      filter: invert(1);
      height: 10px;
      width: 10px;
      margin-right: 5px;
    }

    &:focus,
    &:hover {
      img {
        filter: invert(0);
      }
    }
  }

  .clear-filters {
    @include bp(md) {
      margin-left: auto;
      position: absolute;
      top: 0;
      right: 0;
    }

    &::after {
      display: none;
    }
  }
}

.consultant-directory--pagination {
  position: relative;

  @include bp(lg) {
    padding-left: 175px;
  }
}

.consultant-directory {
  &__filters {
    background: $c-black;
    color: $c-white;
    margin: 0 -30px;
    padding: 30px;
    position: relative;
    z-index: 1;

    @include bp(lg) {
      display: none;
    }
  }

  &__mobile-nav {
    background: $c-black;
    bottom: 0;
    color: $c-white;
    position: fixed;
    right: 0;
    top: 0;
    width: 0;
    z-index: 10;

    @include bp(lg) {
      display: none;
    }

    &__inner {
      display: flex;
      flex-flow: column nowrap;
      height: 100vh;
      position: relative;
    }

    &-close {
      @extend %button-txt;

      align-items: center;
      color: $c-white;
      display: flex;
      flex-flow: row nowrap;
      opacity: 0;
      right: 30px;
      position: absolute;
      text-decoration: none;
      top: 30px;
      visibility: hidden;
      z-index: 10;

      img {
        margin: 0 0 0 10px;
      }
    }

    *::-webkit-scrollbar {
      width: 1px;
    }

    /* Track */
    *::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    *::-webkit-scrollbar-thumb {
      background: $c-gray-light;
      border: 0px;
    }

    &__items {
      flex: 1;
      margin: 75px 30px 0;
      overflow: auto;
      scrollbar-color: $c-gray-light transparent;
      scrollbar-width: thin;
    }

    &__item-title {
      align-items: center;
      cursor: pointer;
      display: flex;
      font-family: $font-family-serif;
      font-weight: $font-weight-light;
      font-size: 30px;
      line-height: 48px;
      padding: 10px 0;
      position: relative;
      transition: all 0.2s;

      .consultant-directory__mobile-nav__item.active & {
        color: $c-purple-light;
      }

      svg {
        height: 12px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.2s;
        width: 18px;

        .consultant-directory__mobile-nav__item.active & {
          transform: translateY(-50%) rotate(180deg);
        }

        path {
          fill: $c-white;
          transition: all 0.2s;

          .consultant-directory__mobile-nav__item.active & {
            fill: $c-purple-light;
          }
        }
      }
    }

    &__item-filters {
      max-height: 0;
      overflow: auto;
      transition: max-height 0.2s;

      .consultant-directory__mobile-nav__item.active & {
        max-height: 35vh;
      }
    }

    &__bottom {
      border-top: 1px solid $c-gray-light;
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin: 0 30px;
      padding: 30px 0;
    }

    &__clear-filters {
      &::after {
        display: none;
      }
    }
  }

  &__mobile-nav-link {
    @include bp(lg) {
      display: none;
    }

    img {
      margin: 0 8px 0 0;
    }

    &::after {
      display: none;
    }
  }

  &__items {
    display: flex;
    flex-flow: row wrap;

    &:focus,
    &:hover {
      color: $c-gray-lighter-fade;

      span {
        color: $c-purple-light;
      }

      .consultant-directory__item {
        &:focus,
        &:hover {
          color: $c-white;

          span {
            color: $c-purple-light;
          }
        }
      }
    }

    .consultant-directory__mobile-nav__item & {
      display: block;

      &:focus,
      &:hover {
        color: $c-white;

        span {
          color: $c-purple-light;
        }

        .consultant-directory__item {
          &:focus,
          &:hover {
            color: $c-white;

            span {
              color: $c-purple-light;
            }
          }
        }
      }
    }
  }

  &__item {
    display: flex;
    cursor: pointer;
    flex-flow: column wrap;
    flex: 0 1 50%;
    margin: 0 0 30px;
    padding: 0 0 0 30px;
    position: relative;
    transition: all 0.35s;
    width: 50%;

    @include bp(lg) {
      flex: 0 1 33.33%;
      width: 33.33%;
    }

    svg {
      fill: $c-gray-dark;
      height: 10px;
      width: 10px;
      margin: 0 0 0 -20px;
      opacity: 0;
      position: absolute;
      transition: all 0.35s;
    }

    p {
      font-family: $font-family-sans;
      margin: 0;
      transition: all 0.35s;
    }

    span {
      @extend %copy--sm;
      color: $c-purple-light;
      margin: -15px 0 0 4px;
    }

    &--selected {
      svg {
        opacity: 1;

        line {
          stroke: $c-purple-light;
        }
      }

      p {
        color: $c-purple-light;
      }
    }

    .consultant-directory__mobile-nav__item & {
      flex: 0 0 100%;
      margin: 30px 0;
      padding: 0;
      width: 100%;

      svg {
        display: none;
        margin: 0 10px 0 0;
        opacity: 1;
        position: static;

        line {
          stroke: $c-purple-light;
        }
      }

      span {
        color: $c-purple-light;
      }

      &--selected {
        svg {
          display: block;
        }

        p {
          color: $c-purple-light;
        }
      }
    }
  }
}

@keyframes itemsFadeIn {
  0% {
    opacity: 0;
    height: 0px;
    width: 0px;
  }

  1% {
    opacity: 0;
    height: auto;
    width: 100%;
    margin-top: 50px;
  }

  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

@keyframes itemsFadeOut {
  0% {
    opacity: 1;
    margin-top: 0px;
  }

  99% {
    opacity: 0;
    height: auto;
    width: 100%;
    margin-top: 50px;
  }

  100% {
    opacity: 0;
    height: 0px;
    width: 0px;
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .consultant-directory--wrapper {
      .input--animation-container {
        .input--wrapper {
          .input {
            &::after {
              background-color: $color;
            }
          }
        }
      }

      .filter {
        &:focus,
        &:hover {
          .title {
            background-image: linear-gradient(#{$color}, #{$color});
            color: $color;

            svg {
              path {
                fill: $color;
              }
            }
          }
        }

        &.active {
          .title {
            background-image: linear-gradient(#{$color}, #{$color});
            color: $color;

            svg {
              path {
                fill: $color;
              }
            }
          }
        }

        .title {
          background-image: linear-gradient(#{$color}, #{$color});
        }

        .items {
          .item {
            &.active {
              color: $color;
            }

            &.selected {
              color: $color;
            }
          }
        }
      }
    }
  }
}

@each $section, $color in $sectionLightColors {
  .page-container[data-section='#{$section}'] {
    .consultant-directory {
      &__mobile-nav {
        &__item {
          &.active {
            .consultant-directory__mobile-nav__item-title {
              color: $color;

              svg {
                path {
                  fill: $color;
                }
              }
            }
          }
        }
      }

      &__items {
        &:focus,
        &:hover {
          span {
            color: $color;
          }

          .consultant-directory__item {
            &:focus,
            &:hover {
              span {
                color: $color;
              }
            }
          }
        }
      }

      &__item {
        span {
          color: $color;
        }

        &--selected {
          svg {
            line {
              stroke: $color;
            }
          }

          p {
            color: $color;
          }
        }
      }
    }
  }
}

.consultant-directory__mobile-nav__item {
  $thisMobileNavItem: &;

  &#{$thisMobileNavItem}--hidden {
    display: none;
  }
}

.mobile-nav {
  &__children-items {
    margin-top: 30px;
    padding-left: 30px;
  }

  &__children-item {
    font-size: 12px;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__button {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
  }
}
