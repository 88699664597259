.items-list {
  &__intro {
    margin: 0 0 60px;
    text-align: center;

    @include bp(lg) {
      margin: 0 100px 60px;
    }

    > * {
      @include animate-element('text', '.items-list__intro');
      @include animate-delay(2);
    }
  }
}

.link-items {
  @include bp(lg) {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -50px;

    &--three {
      margin: 0 -30px;
    }

    > * {
      @include animate-element('text', '.link-items');
      @include animate-delay(9);
    }
  }
}

.link-item {
  padding: 25px 0;

  @include bp(lg) {
    flex: 0 0 50%;
    padding: 25px 50px;
    width: 50%;

    .link-items--three & {
      flex: 0 0 33.33%;
      padding: 25px 30px;
      width: 33.33%;
    }
  }

  a {
    cursor: pointer;
  }

  h3 {
    margin: 0 0 10px;

    a {
      @include animate-underline($c-black);
      color: $c-black;
    }
  }
}

.items-list__link {
  margin: 65px 0 0;
  text-align: center;

  &-wrapper {
    > * {
      @include animate-element('text', '.items-list__link-wrapper');
      @include animate-delay(1);
    }
  }
}
