.scrollable {

	&-container {
		align-items: center;
		display: flex;

		@include bp('md'){
			margin-left: 0;
			margin-right: 0;
		}

		&__list {			
			overflow: hidden;

			li {
				left: 0;
				position: relative;
			}

			&--item {
				white-space: nowrap;
			}
		}

		&__left,
		&__right {
			display: block;
			opacity: 1;
			padding: 15px;
			transition: all 250ms;
			width: 92px;

			@include bp('md') {
				display: none;
			}

			&.disabled {
				opacity: 0;
			}

			img {
				height: 16px;
			}
		}		

		&__left {

			img {
				transform: rotate(90deg);
			}
		}

		&__right {
			
			img {
				transform: rotate(-90deg);
			}
		}	
	}
}