.rich-text-blockquote {
  background: $c-white;
  padding: 60px 0 0;
  position: relative;

  @include bp(lg) {
    padding: 80px 0 0;
  }

  .layout-container {
    @include container-inset;

    > * {
      @include animate-element(
        'text',
        '.rich-text-blockquote .layout-container'
      );
      @include animate-delay(2);
    }
  }

  blockquote {
    @extend %h3;
    color: $c-black;
    margin: 0;

    .icon {
      margin: 0 0 15px;

      @include bp(lg) {
        float: left;
        height: 38px;
        position: absolute;
        top: 0;
        width: 38px;

        ~ .rtb-paragraphs {
          > *:first-of-type {
            text-indent: 55px;
          }
        }
      }
    }

    p {
      @extend %h3;
    }
  }

  .rtb-paragraphs {
    > * {
      @include animate-element('text', '.rtb-paragraphs');
      @include animate-delay(4);
    }
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .rich-text-blockquote {
      blockquote {
        color: $color;
      }
    }
  }
}
