.search-results {
  .layout-container {
    @include container-inset;
  }

  &__results {
    > * {
      @include animate-element('text', '.search-results__results');
      @include animate-delay(20);
    }

    &[data-animation='true'] {
      .result {
        transform: none !important;
        // added !important because animate-element @include overwrites any transform - tdeluca - 07222021
        will-change: auto !important;
        // needed to overwrite animate-element @include rule so filter position is respected - tdeluca - 07222021
      }
    }
  }

  .result {
    border-bottom: 0.25px solid $c-black;
    margin: 0 0 32px;
    padding: 0 0 16px;

    @include bp(lg) {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      padding: 0 0 32px;
    }

    &:last-child {
      border: 0;
      margin: 0;
      padding: 0;
    }

    &__image {
      height: 212px;
      margin: 0 0 26px;
      overflow: hidden;
      position: relative;
      max-height: 212px;
      max-width: 315px;
      width: 100%;

      @include bp(md) {
        height: 302px;
        max-height: 302px;
        max-width: 450px;
      }

      @include bp(lg) {
        flex: 0 1 40%;
        height: 221px;
        margin: 0 0 0 42px;
        max-height: none;
        max-width: none;
        width: 40%;

        #cludo-search-results & {
          height: 221px !important;
        }
      }

      a {
        @extend %button-txt;
        color: $c-black;
        text-decoration: none;

        @include bp(lg) {
          color: $c-white;
        }
      }

      svg {
        fill: $c-black;
        margin: 0 6px 0 0;

        @include bp(lg) {
          fill: $c-white;
        }
      }

      img {
        display: none;
        height: 100%;
        left: 50%;
        object-fit: cover;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.35s;
        width: 100%;

        @include bp(lg) {
          display: block;
        }
      }

      &:hover,
      &--hover {
        img {
          transform: translate(-50%, -50%) scale(110%) !important;
          filter: brightness(0.9);
          // added !important because animate-element @include overwrites any transform - tdeluca - 07092021
        }
      }
    }

    &__type {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;

      span {
        margin: 0 0 0 7px;
      }

      @include bp(lg) {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.66) 67.86%
        );
        bottom: 0;
        left: 0;
        min-height: 75px;
        padding: 0 24px;
        position: absolute;
        right: 0;
      }
    }

    &__content {
      @include bp(lg) {
        flex: 1 0 60%;
        order: -1;
        width: 60%;
      }
    }

    &__meta {
      @include bp(lg) {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        margin: 0 0 22px;
      }
    }

    &__authors {
      cursor: pointer;
      margin: 0 0 20px;

      @include bp(lg) {
        margin: 0 20px 0 0;
      }
    }

    &__date-read {
      margin: 0 0 20px;

      @include bp(lg) {
        margin: 0;
      }

      p {
        margin: 0;
        &.no-bar {
          span {
            &:first-child {
              &::after {
                content: '' !important;
                display: inline-block;
                margin: 0 10px 0 15px;
              }
            }
          }
        }
      }

      span {
        &:first-child {
          &::after {
            content: '|';
            display: inline-block;
            margin: 0 10px 0 15px;
          }
        }
      }
    }

    &__title {
      margin: 0 0 30px;

      a {
        @include animate-underline($c-black);
        color: $c-black;
      }

      h2 {
        margin: 0;
      }
    }

    &__secondary-title {
      display: flex;
      flex-wrap: wrap;
      margin-right: 30px;
      margin-top: 20px;
      margin-bottom: 20px;

      > div {
        position: relative;
        font-family: $font-family-sans;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 1;
        margin: 0 0 10px;

        @include bp(lg) {
          margin: 0;

          &:not(:last-child) {
            padding-right: 8px;

            &::after {
              content: '';
              position: absolute;
              right: 0;
              width: 1;
              bottom: 0;
              top: 0;
              height: 16px;
              border-right: 2px solid black;
            }
          }
        }

        &:not(:first-child) {
          @include bp(sm) {
            padding-left: 8px;
          }
        }
      }
    }

    &__breadcrumb {
      .breadcrumbs-wrapper {
        margin: 0 0 20px;
        max-width: none;

        @include bp(lg) {
          .breadcrumbs--item {
            &:not(:first-child) {
              overflow: auto;
              width: auto !important;
            }

            &:nth-child(3) {
              background: none;
              -webkit-background-clip: border-box;
              -webkit-text-fill-color: currentColor;
            }
          }
        }
      }
    }

    &__tags {
      overflow: hidden;
      position: relative;

      &.has-overlay {
        max-height: 106px;

        &::after {
          content: '';
          display: block;
          position: absolute;
          z-index: 1;
          top: 0;
          right: 0;
          width: 100px;
          height: 100%;
          background: linear-gradient(90deg, $c-white-0, $c-white 75%);
        }

        @include bp(lg) {
          max-height: none;
        }
      }

      @include bp(lg) {
        display: flex;
        flex-flow: row nowrap;
        overflow: hidden;

        &::after {
          content: '';
          display: block;
          position: absolute;
          z-index: 1;
          top: 0;
          right: 0;
          width: 100px;
          height: 100%;
          background: linear-gradient(90deg, $c-white-0, $c-white 75%);
        }
      }

      button {
        @extend .btn-tag;
        @extend .btn-tag--black;
        display: inline-block;
        margin: 0 13px 13px 0;
        width: auto;

        a {
          color: inherit;
        }

        @include bp(lg) {
          margin: 0 13px 0 0;

          &:last-child {
            margin: 0;
          }
        }
      }

      &--disabled {
        overflow: hidden;
        position: relative;
        white-space: nowrap;

        &::after {
          content: '';
          display: block;
          position: absolute;
          z-index: 1;
          top: 0;
          right: 0;
          width: 100px;
          height: 100%;
          background: linear-gradient(90deg, $c-white-0, $c-white 75%);
        }

        button {
          background: none;
          border: 0;
          cursor: default;
          font-size: 16px !important;
          height: auto;
          padding: 0;

          &:focus,
          &:hover {
            color: $c-black;
          }

          &::after {
            background: none;
            content: ',';
            display: inline;
            position: static;
          }

          &:last-child {
            &::after {
              display: none;
            }
          }
        }
      }
    }

    &--featured {
      .result__image {
        height: 212px;

        #cludo-search-results & {
          height: 212px !important;
        }

        @include bp(md) {
          #cludo-search-results & {
            height: 302px !important;
          }
        }

        @include bp(lg) {
          flex: 0 1 50%;
          height: 302px !important;
          margin: 0 42px 0 0;
          width: 50%;
        }

        a {
          color: $c-white;
        }

        svg {
          fill: $c-white;
        }

        img {
          display: block;
        }
      }

      .result__type {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.66) 67.86%
        );
        bottom: 0;
        left: 0;
        min-height: 75px;
        padding: 0 24px;
        position: absolute;
        right: 0;
      }

      .result__content {
        @include bp(lg) {
          flex: 1 0 50%;
          order: 0;
          width: 50%;
        }
      }

      .result__title {
        a {
          @include animate-underline($c-purple-primary);
          color: $c-purple-primary;
        }
      }
    }

    &--with-breadcrumb {
      .result__type {
        @extend %button-txt;

        background: none;
        margin: 0 0 30px;
        min-height: auto;
        padding: 0;
        position: static;

        svg {
          fill: $c-black;
        }
      }
    }
  }
}

#cludo-search-results {
  .cludo-page-navigation__list-item {
    cursor: pointer;

    &.active {
      color: $c-burgundy-light;
    }

    a {
      color: inherit;
    }
  }
}
