.get-in-touch--container {
  position: relative;
  background-color: $c-gray-soft;
  padding-top: 110px;
  padding-bottom: 110px;

  @include bp(sm) {
    padding-top: 107px;
    padding-bottom: 135px;
  }

  @include bp(lg) {
    padding-top: 137px;
    padding-bottom: 137px;
  }

  @include bp(xxl) {
    padding: 137px 0 161px 0;
    overflow: hidden;
  }

  .get-in-touch--wrapper {
    @include container-inset;
  }

  .get-in-touch--form {
    > * {
      @include animate-element('text', '.get-in-touch--form');
      @include animate-delay(4);
    }
  }

  .get-in-touch--confirmation {

    div {

      .heading1 {
        display: block;
        margin-bottom: 40px;
      }

      &.consultant-info {
        align-items: flex-start;
        display: flex;
        flex-direction: column;

        @include bp(lg){
          flex-direction: row;
        }

        img {
          margin-bottom: 10px;
          margin-right: 10px;
          max-width: 142px;
        }

        p {
          font-family: Teodor;
          font-size: 24px;
          font-style: italic;
          font-weight: 400;
        }
      }
    }
  }

  .get-in-touch--header {
    display: flex;
    flex-direction: column;

    @include bp(lg) {
      flex-direction: row;
    }

    .title {
      color: $c-purple-primary;
      display: flex;
      justify-content: space-between;

      .heading1 {
        font-family: Teodor;
        font-style: normal;
        font-weight: 300;
        line-height: 42px;

        @include bp(lg) {
          font-size: 60px;
          line-height: 62px;
        }
      }

      img {
        width: 65px;

        &.mobile-only{
          display: block;

          @include bp(lg){
            display: none;
          }
        }
      }

      @include bp(lg) {
        margin-right: 74px;
      }
    }

    .contact-details:not(.mobile) {
      padding-top: 16px;
      width: 50%;
      display: none;

      @include bp(lg) {
        display: block;
      }

      p {
        line-height: 1;
        color: $c-purple-primary;
        margin: 0 0 30px;

        a {
          color: $c-purple-primary;
          display: flex;
          flex-flow: row nowrap;
          text-decoration: none;
        }

        img {
          margin: 0 10px 0 0;
        }

        span {
          word-wrap: break-word;
        }
      }
    }
  }

  .get-in-touch__body {
    display: flex;
    flex-direction: column;
    padding-top: 89px;

    @include bp(lg){
      flex-direction: row;
    }

    &-contact {
      display: flex;
      flex: 0 0 33%;
      flex-direction: column;
      padding-right: 40px;
      order: 2;

      @include bp(lg){
        order: 1;
      }

      > img {
        display: none;
        margin-bottom: 50px;
        max-width: 141px;

        &.desktop-only {
          display: none;

          @include bp(lg){
            display: block;
          }
        }
      }

      .social-media {
        margin-bottom: 34px;

        a {
          margin-right: 20px;

          img {
            width: 34px;
          }
        }
      }

      .contact-phone,
      .contact-email {
        color: $c-burgundy-primary;
        font-family: Spezia;
        font-size: 16px;
        font-weight: 400;
        margin: 10px 0;
        text-decoration: none;
      }
    }

    &-form {
      flex: 1 1 auto;
      margin-bottom: 75px;
      order: 1;

      @include bp(lg) {
        margin-bottom: 0;
        order: 2;
      }

      h4,
      .heading4 {
        align-items: center;
        border-bottom: 1px solid #000;
        display: flex;
        font-family: 'Spezia';
        font-weight: 400;
        font-size: 24px;
        line-height: 40px;
        padding-bottom: 10px;

        img {
          margin-right: 10px;
        }
      }
    }
  }

  .contact-details.mobile {
    display: flex;
    flex-wrap: wrap;
    margin-top: 63px;

    @include bp(lg) {
      display: none;
    }

    > div {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      a {
        color: $c-purple-primary;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        text-decoration: none;

        div:first-child {
          margin-right: 10px;
        }
      }

      p {
        width: calc(100% - 38px);
        margin-bottom: 30px;
        word-wrap: break-word;
        line-height: 1;
        color: $c-purple-primary;
      }
    }
  }

  .get-in-touch--fields {
    .mktoForm {
      margin-left: 0;
      margin-right: 0;
      width: 100% !important;
      max-width: 100%;
    }

    .mktoFormRow {
      width: 100%;
    }

    .mktoButtonRow button[type='submit'] {
      display: none !important;
    }

    .marketo-controls-container {
      width: 100%;
    }
  }

  .submit-btn {
    margin-top: 0px;
    display: none;
    transition-delay: 1.3s;

    @include bp(lg) {
      display: inline-block;
    }
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .get-in-touch--header {
      .title {
        color: $color;
      }

      .contact-details:not(.mobile) {
        p {
          color: $color;

          a {
            color: $color;
          }
        }
      }
    }

    .contact-details.mobile {
      > div {
        a {
          color: $color;
        }

        p {
          color: $color;
        }
      }
    }
  }
}
