.split-text-image {
  background: $c-white;
  position: relative;

  .layout-container {
    @include bp(xxl) {
      margin: 0 auto;
      max-width: 960px;
    }
  }

  &-wrapper {
    display: flex;
    flex-flow: column nowrap;
    padding: 80px 30px;

    @include bp(lg) {
      flex-flow: row nowrap;
      gap: 40px;
      padding: 160px 0 100px;
    }

    > * {
      @include animate-element('text', '.split-text-image-wrapper');
      @include animate-delay(2);
    }
  }

  &__content {
    @include bp(lg) {
      padding: 70px 0 70px 30px;
    }

    @include bp(xxl) {
      padding-left: 0;
    }

    &-container {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      height: 100%;
    }

    h2 {
      color: $c-purple-primary;
      margin: 0 0 46px;
    }

    h3 {
      margin: 0 0 46px;
    }

    a {
      color: $c-purple-primary;
      text-decoration: none;
    }
  }

  &__image {
    margin: 0 0 35px;
    order: -1;

    @include bp(lg) {
      flex: 0 0 470px;
      order: 0;
      width: 470px;

      &-container {
        position: sticky;
        top: 0;
      }
    }
  }

  &--left {
    @include bp(lg) {
      .split-text-image__content {
        padding: 70px 30px 70px 0;
      }

      .split-text-image__image {
        order: -1;
      }
    }

    @include bp(xxl) {
      .split-text-image__content {
        padding-right: 0;
      }
    }
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .split-text-image {
      &__content {
        h2 {
          color: $color;
        }

        a {
          color: $color;
        }
      }
    }
  }
}
