/**
 * Icon Styles
 * Variables for the icons are in the _variables partial
 * This mixin is invoked by the each loop in _icons.scss,
 * it should not be necessary to use it on its own.
 */
@mixin icon-styles($symbol) {
  @extend %icon;
  content: $symbol;
}

// sass-lint:disable no-warn
/**
 * Breakpoints
 * Accepts:
 * - $breakpoint: {String} Name from varible breakpoint map.
 *
 * Usage:
 * .thing {
 *   @include bp(md) { display: none }
 * }
 */
@mixin bp($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }

  @else {
    @warn "Breakpoint doesn't exist please check breakpoints map.";
  }
}

/**
 * Breakpoints (Down)
 * Accepts:
 * - $breakpoint: {String} Name from varible breakpoint map.
 *
 * Usage:
 * .thing {
 *   @include bp(md) { display: none }
 * }
 */
@mixin bp-down($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }

  @else {
    @warn "Breakpoint doesn't exist please check breakpoints map.";
  }
}

/**
 * Gradients
 * Accepts:
 * - $start-color: {String} Color variable.
 * - $end-color {String} Color variable.
 * - $orientation { String } vertical, horizonal, null.
 *
 * Usage:
 * .thing {
 *   @include gradient(red, blue, horizontal);
 * }
 */
@mixin gradient($start-color, $end-color, $orientation) {
  background: $start-color;

  @if $orientation=='vertical' {
    background: linear-gradient(to bottom, $start-color, $end-color);
  }

  @else if $orientation=='horizontal' {
    background: linear-gradient(to right, $start-color, $end-color);
  }

  @else if $orientation=='radial' {
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }

  @else {
    @warn "Invalid orientation";
  }
}

/**
 * Is active
 * Helper for native states
 *
 * Usage:
 * .thing {
 *   @include is-active;
 * }
 */
@mixin is-active {

  &:hover,
  &:active,
  &:focus {
    @content;
  }
}

/**
 * Placeholder text
 * Styles input placeholders
 *
 * Usage:
 * .thing {
 *   @include placeholder;
 * }
 */
@mixin placeholder {
  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

/**
 * State is...
 * For when state changes are made with js.
 * Accepts:
 * - $el: {String} Class added by js.
 * - $position: {String} Where is lies on the DOM.
 * - $parent: {String} Parent element (optional).
 *
 * Usage:
 * .thing::before {
 *   @include state-is('active', 'self');
 * }
 */
@mixin state-is($el, $position: 'self', $parent: '') {
  @if $position=='self' {
    #{$parent} &#{$el} {
      @content;
    }
  }

  @else if $position=='child' {
    #{$parent} & #{$el} {
      @content;
    }
  }

  @else if $position=='parent' {
    #{$parent} #{$el} & {
      @content;
    }
  }

  @else {
    @warn "Invalid position";
  }
}

/**
 * Animate underline links.
 *
 * Usage:
 * .thing {
 *   @include animate-underline(color, width);
 * }
 * From https://codepen.io/johnheiner/pen/EyaLQW..
 */
@mixin animate-underline($color, $width: 1px) {
  position: relative;
  border-radius: 0;
  text-decoration: none;
  background-image: linear-gradient(#{$color}, #{$color});
  background-size: 0 $width;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  transition: background-size 0.35s;

  &:hover,
  &:focus,
  &.hover,
  &.focus {
    background-position: 0 100%;
    background-size: 100% $width;
  }
}

@mixin animate-underline-richtext($color, $width: 2px, $spacing: 2px) {
  background-color: transparent;
  background-image: linear-gradient(#{$color}, #{$color}), linear-gradient(#{$color}, #{$color});
  background-position: 100% 100%, 0 0;
  background-size: 100% $width, 0 100%;
  background-repeat: no-repeat;
  border-radius: 0;
  color: $color !important;
  padding-bottom: 2px;
  position: relative;
  text-decoration: none !important;
  transition: background-size 0.35s, color 0.35s, margin 0.35s, padding 0.35s !important;

  span {
    text-decoration: none !important;
    color: $color !important;
  }

  &:not(.disabled) {

    &:hover {
      background-position: 0 100%, 0 100%;
      background-size: 100% $width, 100% 100%;
      color: $c-white !important;
      margin: 0 -$spacing;
      padding: 0 $spacing 2px;

      span {
        text-decoration: none !important;
        color: $c-white !important;
      }
    }

    &:focus {
      background-position: 0 100%, 0 100%;
      margin: 0 -$spacing;
      padding: 0 $spacing 2px;

      &:before {
        border-color: $color;
      }
    }

    &:before {
      border: 1px solid transparent;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      transition: border-color 0.35s;
    }
  }

  &.disabled {
    background-image: linear-gradient(#{$c-gray-lighter}, #{$c-gray-lighter});
    background-size: 100% $width;
    background-position: 100% 100%;
    color: $c-gray-lighter;
  }
}

/**
 * Reverse animate underline links.
 *
 * Usage:
 * .thing {
 *   @include animate-underline-reverse(color, width);
 * }
 */
@mixin animate-underline-reverse($color, $width: 1px) {
  position: relative;
  padding: 0 0 8px;
  border-radius: 0;
  text-decoration: none;
  font-weight: $font-weight-regular;
  background-image: linear-gradient(#{$color}, #{$color});
  background-position: 0 100%;
  background-size: 100% $width;
  background-repeat: no-repeat;
  transition: background-size 0.35s;

  &:hover,
  &:focus,
  &.hover,
  &.focus {
    background-size: 0 $width;
    background-position: 100% 100%;
  }
}

/**
 * Page margins
 * Usage: Global page margins
 *
 * Usage:
 * .thing {
 *   @include page-margins;
 * }
 */
@mixin page-margins {
  margin-left: 30px;
  margin-right: 30px;

  @include bp(lg) {
    margin-left: 36px;
    margin-right: 36px;
  }
}

/**
 * Container
 * Usage: Global container
 *
 * Usage:
 * .thing {
 *   @include container;
 * }
 */
@mixin container {
  margin-left: 30px;
  margin-right: 30px;

  @include bp(lg) {
    width: calc(100% - (2 * 60px));
    max-width: 1080px;
    margin-left: 120px;
    margin-right: auto;
  }
}

/**
 * Container Inset
 * Usage: Global inset container
 *
 * Usage:
 * .thing {
 *   @include container-inset;
 * }
 */
@mixin container-inset {
  margin-left: 30px;
  margin-right: 30px;

  @include bp(lg) {
    width: calc(100% - (2 * 115px));
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }
}

/**
 * Container Reset
 * Usage: Reset container for use in breakpoint overrides.
 *
 * Usage:
 * .thing {
 *   @include container-reset;
 * }
 */
@mixin container-reset {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  max-width: unset;
}

/**
 * Inset Component Spacer
 * Usage: Global spacing for components
 *
 * Usage:
 * .thing {
 *   @include spacer-component;
 * }
 */
@mixin spacer-component {
  margin-bottom: 120px;

  @include bp(lg) {
    margin-bottom: 230px;
  }
}

/**
 * Intro Txt
 * Usage: Global styling/spacing for intro text.
 *
 * Usage:
 * .thing {
 *   @include intro-txt;
 * }
 */
@mixin intro-txt {
  @include bp(lg) {
    display: flex;
  }

  h3,
  .heading3 {
    @include bp(lg) {
      width: 560px;
      margin-top: -8px;
      margin-right: 60px;
      flex-shrink: 0;
    }

    @include bp(xxl) {
      margin-right: 100px;
    }
  }

  p {
    @extend %copy--sm;
    margin-bottom: 45px;
    transition-delay: 0.35s;
  }

  .intro-button {
    transition-delay: 0.7s;
  }
}

/**
 * Add a caret to an element.
 *
 * Usage:
 * .thing {
 *   @include caret (margin);
 * }
 */
@mixin caret($margin: 5px) {
  &::after {
    content: '';
    display: block;
    background: url('/images/caret.png') no-repeat;
    width: 8px;
    height: 5px;
    margin-left: $margin;
    transition: transform 0.2s;
  }
}

// sass-lint:enable no-warn

/**
 * Global Element Animation
 * Accepts:
 * - $type: {String} Text or Image.
 * - $selector: {String} Parent selector.
 *
 * Usage:
 * .thing {
 *   @include animate-element(image);
 * }
 */
/* sass-lint:disable no-important */
/* prettier-ignore */
@mixin animate-element($type: 'text', $selector: 'div') {
  opacity: 1;

  // @media screen and (prefers-reduced-motion: no-preference) {
  //   @if $type=='text' {
  //     opacity: 0;
  //     transform: translateY(3rem);
  //     transition: opacity 0.6s cubic-bezier(0.34, 0.615, 0.4, 0.985), transform 0.6s cubic-bezier(0.34, 0.615, 0.4, 0.985);
  //     will-change: opacity, transform;

  //     @at-root {
  //       #{$selector}[data-animation='true']>* {
  //         opacity: 1 !important;
  //         transform: translateY(0) !important;
  //       }
  //     }
  //   }

  //   @if $type=='image' {
  //     position: relative;

  //     &>* {
  //       position: relative;
  //       transform: translateX(-100%);
  //       transition: transform 0.4s cubic-bezier(0.565, 0.43, 0.24, 0.92);
  //       overflow: hidden;
  //       will-change: transform;

  //       @at-root #{selector-append('[data-animation=true]', &)} {
  //         transform: translateX(0);
  //       }
  //     }

  //     &>*::after {
  //       content: '';
  //       position: absolute;
  //       bottom: 0;
  //       left: 0;
  //       right: 0;
  //       top: 0;
  //       background-color: $c-gray-soft;
  //       transform: scaleX(1);
  //       transform-origin: right center;
  //       transition: transform 0.4s cubic-bezier(0.565, 0.43, 0.24, 0.92) 0.315s;
  //       will-change: transform;
  //       z-index: 6;

  //       @at-root #{selector-append('[data-animation=true]', &)} {
  //         transform: scaleX(0);
  //       }
  //     }

  //     &>*>* {
  //       position: relative;
  //       transform: scale(1.2);
  //       transition: transform 0.9s cubic-bezier(0.34, 0.615, 0.4, 0.985) 0.2s;
  //       will-change: transform;
  //       overflow: hidden;

  //       @at-root #{selector-append('[data-animation=true]', &)} {
  //         transform: scale(1);
  //       }
  //     }

  //     img {
  //       position: relative;
  //       transform: translateX(100%);
  //       transition: transform 0.4s cubic-bezier(0.565, 0.43, 0.24, 0.92);
  //       will-change: transform;
  //       overflow: hidden;
  //       height: 100%;
  //       object-fit: cover;
  //       width: 100%;

  //       @at-root #{selector-append('[data-animation=true]', &)} {
  //         transform: translateX(0);
  //       }
  //     }
  //   }
  // }
}

/* sass-lint:enable no-important */

/**
 * Global Element Animation Delay
 * Accepts:
 * - $count: {Number} Depth of child elements.
 * - $offset: {Number} An optional delay for sequencing.
 *
 * Usage:
 * .thing {
 *   @include animate-delay(5);
 * }
 */
@mixin animate-delay($count, $offset: 0, $exact: []) {
  @for $i from 1 through $count {
    $delay: $i;

    @if (length($exact) >=$i) {
      @if (nth($exact, $i)) {
        $delay: nth($exact, $i);
      }
    }

    &:nth-child(#{$i}) {
      transition-delay: #{($delay + $offset) * 0.2}s;
    }
  }
}

/**
 * Global Element Animation Delay
 * Accepts:
 * - $count: {Number} Depth of child elements.
 * - $offset: {Number} An optional delay for sequencing.
 *
 * Usage:
 * .thing {
 *   @include animate-delay(5);
 * }
 */
@mixin animate-delay-exact($exact: []) {
  @for $i from 1 through length($exact) {
    $delay: nth($exact, $i);

    @if ($delay) {
      &:nth-child(#{$i}) {
        transition-delay: #{$delay * 0.2}s;
      }
    }
  }
}

/*
  Global Background Offset
  Accepts:
  - $color: background color

  Usage:
  .thing {
    @include background-offset($c-white);
  }
*/
@mixin background-offset($color) {
  background: $color;

  @include bp(lg) {
    background: linear-gradient(to right, transparent 50%, $color 50%);
    position: relative;

    &::after {
      background: $color;
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 120px;
      z-index: 0;
    }

    >* {
      background: $color;
      margin: -120px 0 0;
      padding: 82px 0 0 35px !important;
      position: relative;
      z-index: 1;
    }
  }

  @include bp(xl) {
    &::after {
      top: 180px;
    }

    >* {
      margin: -180px 0 0;
      padding: 77px 66px 0 96px !important;
    }
  }
}

/*
  Global Background Offset (Right)
  Accepts:
  - $color: background color

  Usage:
  .thing {
    @include background-offset($c-white);
  }
*/
@mixin background-offset-right($color) {
  background: $color;

  @include bp(lg) {
    background: linear-gradient(to left, transparent 50%, $color 50%);
    position: relative;

    &::after {
      background: $color;
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 120px;
      z-index: 0;
    }

    >* {
      background: $color;
      margin: -120px 0 0;
      padding: 82px 0 0 35px !important;
      position: relative;
      z-index: 1;
    }
  }

  @include bp(xl) {
    &::after {
      top: 180px;
    }

    >* {
      margin: -180px 0 0;
      padding: 77px 66px 0 96px !important;
    }
  }
}
