.related-articles {
  display: flex;
  position: relative;
  flex-direction: column;

  @include bp(md) {
    flex-direction: row;
  }
}

.related-article {
  overflow: hidden;

  @include bp(md) {
    background: $c-black;
    flex: 0 1 50%;
    min-width: 375px;
  }

  @include bp(lg) {
    min-height: 525px;
  }

  a {
    display: block;
    position: relative;
    color: $c-white;
    text-decoration: none;
    height: 100%;

    &::after {
      background: $c-black-70;
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    display: block;
    align-self: center;
    width: 100%;
    height: 100%;
    opacity: 1;
    overflow: hidden;
    transition: all 0.35s;
    z-index: 0;

    @include bp(md) {
      opacity: 0;
    }
  }

  &.hover,
  &:hover {
    img {
      opacity: 1;
      transform: scale(120%) !important;
      // added !important because animate-element @include overwrites any transform - tdeluca - 07092021
    }
  }

  &.no-overlay {
    a::after {
      display: none;
    }
  }

  &__info {
    padding: 54px 23px 37px 23px;
    width: auto;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 1;

    &-inner {
      > * {
        @include animate-element('text', '.related-article__info-inner');
        @include animate-delay(2);
      }
    }

    @include bp(lg) {
      padding: 96px 60px 168px 60px;

      &-inner {
        max-width: 390px;
      }
    }
  }

  &__tags {
    margin-bottom: 23px;
    padding: 0;

    @include bp(lg) {
      display: flex;
      flex-flow: row nowrap;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        opacity: 0.5;
        width: 100px;
        height: 100%;
        background: linear-gradient(90deg, transparent, $c-black-70 75%);
      }
    }

    button {
      @extend .btn-tag;
      @extend .btn-tag--white;

      display: inline-block;
      margin: 0 13px 13px 0;

      @include bp(lg) {
        margin: 0 13px 0 0;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  &__title {
    color: $c-white;
    font-family: $font-family-serif;
    font-size: 34px;
    line-height: 42px;
    font-weight: 400;
    margin: 0;
    text-transform: none;

    span {
      @include animate-underline($c-white);
      color: $c-white;
    }

    @include bp(md) {
      font-weight: 300;
    }

    @include bp(lg) {
      font-size: 60px;
      line-height: 62px;
      font-family: 'Teodor';
      font-weight: 300;
    }
  }
}
