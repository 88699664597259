.related-consultants-list-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $c-white;
  padding: 135px 0 0;

  > * {
    @include animate-element('text', '.related-consultants-list-container');
    @include animate-delay(4);
  }

  @include bp(lg) {
    > * {
      @include animate-delay-exact(1 null 2 6);
    }
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    #related-consultants-root[data-depends-on-section='true'] {
      .related-consultants-list-container {
        background-color: $color;
        color: $c-white;
        a {
          color: $c-white !important;
          &:hover,
          &:visited,
          &:link,
          &:active {
            color: $c-white;
          }

          &::after,
          span::before {
            filter: invert(1);
          }

          &.link--button {
            background-image: linear-gradient($c-white, $c-white);
          }
        }
      }

      .pagination {
        background-color: $color;
        color: $c-white;

        &__items {
          color: $c-white;

          a {
            color: $c-white;

            &:focus,
            &:hover,
            &.selected {
              color: $c-white;
            }
          }
        }

        &__arrow {
          &::after {
            border-color: $c-white;
            color: $c-white;
          }

          img {
            filter: brightness(0) invert(1);
          }
        }

        &__underline {
          background: $c-white;
        }
      }
    }
  }
}

.related-consultants-list--carousel-title {
  .heading1 {
    margin: 0 auto;
    padding: 0 68px;
    text-align: center;
    font-family: $font-family-serif;
    font-weight: $font-weight-light;
    font-size: 28px;
    line-height: 36px;

    @include bp(lg) {
      font-weight: $font-weight-regular;
      font-size: 36px;
      line-height: 48px;
    }
  }
}

.related-consultants-list-container .related-consultants-list--carousel-title {
  margin: 0 0 30px;

  @include bp(md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 60px;
  }
}

.related-consultants-list-container .glide__bullets {
  margin: 0;

  @include bp(md) {
    display: none;
  }
}

.related-consultants-list-container .event p {
  @extend %copy--sm;
}

.related-consultants-list--card-row {
  max-width: 957px;
  width: 100%;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    list-style-type: none;

    li {
      padding: 0 30px;
      width: 50%;

      @include bp(md) {
        width: 33.33%;
      }

      @include bp(lg) {
        width: 25%;
      }
    }

    > * {
      @include animate-element(
        'text',
        '.related-consultants-list--card-row ul'
      );
      @include animate-delay(16);
    }

    @include bp(lg) {
      > * {
        @include animate-delay(16, 1);
      }
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-family: $font-family-sans;
      font-weight: $font-weight-regular;
      margin-bottom: 40px;
      text-decoration: none;
      color: $c-black;

      &:hover,
      &:visited,
      &:link,
      &:active {
        text-decoration: none;
        color: $c-black;
      }
    }

    .related-consultants-list--picture {
      height: 130px;
      width: 130px;
      border-radius: 50%;
      overflow: hidden;

      img {
        height: 100%;
        object-fit: cover;
        transition: all 0.35s;
        width: 100%;
      }

      &:hover {
        img {
          transform: scale(110%) !important;
          // added !important because animate-element @include overwrites any transform - tdeluca - 07092021
        }
      }
    }

    .related-consultants-list--info {
      display: flex;
      flex-flow: column wrap;
      margin-top: 10px;

      h4,
      .heading4 {
        margin: 0 0 15px;
        padding: 0;
        font-weight: $font-weight-regular;
        font-size: 16px;
      }

      > span {
        // city
        display: block;
        font-size: 11px;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        line-height: 20px;
        padding-left: 15px;
        position: relative;
        text-align: left;

        &::before {
          content: '';
          display: inline-block;
          background: url('../images/pinpoint.svg') no-repeat;
          width: 8px;
          height: 10px;
          left: 0;
          margin-right: 7px;
          position: absolute;
          top: 5px;
        }

        span {
          display: block;
        }
      }

      .related-consultants-list--description {
        margin: 8px 0 14px 0;

        span {
          display: block;
          font-size: 13.5px;
          line-height: 24px;
        }
      }

      @include bp(lg) {
        align-items: flex-start;
      }
    }
  }
}
