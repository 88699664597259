.carousel--controls {
  display: flex;
  align-items: center;
  min-height: 65px;
}

.carousel--controls .glide__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: none;
  border: none;
  position: relative;
  transition: all 0.35s;

  > * {
    position: relative;
    z-index: 1;
  }

  &::after {
    border: 0.5px solid $c-gray-lighter;
    border-radius: 50%;
    content: '';
    display: block;
    height: 50px;
    left: 50%;
    position: absolute;
    transition: all 0.35s;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 50px;
    z-index: 0;
  }

  img {
    filter: brightness(0%);
  }

  &:first-of-type {
    margin-right: 32px;
  }

  &:hover {
    &::after {
      width: 65px;
      height: 65px;
    }
  }
}

.carousel--controls .glide__arrow img,
.carousel--controls .glide__arrow svg {
  width: 16px;
  height: 16px;
}

// We need to override the animation transform here.
// sass-lint:disable no-important
.carousel--controls .glide__arrow--right img,
.carousel--controls .glide__arrow--right svg {
  transform: rotate(180deg) !important;
}
// sass-lint:enable no-important

.glide__bullets {
  @include container;
  margin-top: 32px;
  display: flex;
  align-items: center;
  height: 15px;

  @include bp(lg) {
    display: none;
  }
}

.glide__bullets > button {
  @extend %btn-reset;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: $c-black;
  margin-right: 24px;
  border: 0;
  transition: all 0.2s $easeOutCubic;

  &:last-of-type {
    margin-right: 0;
  }
}

.glide__bullets > .glide__bullet--active {
  width: 15px;
  height: 15px;
  border: 0.5px solid $c-blue-light;
  background: transparent;
}
