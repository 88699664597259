.testimonial-quote--wrapper {
  margin-top: 141px;
  margin-bottom: 78px;

  .layout-container {
    @include container-inset;
  }
}

.testimonial-quote--container {
  max-width: 754px;
  margin: 0px auto;
  text-align: left;

  @include bp(lg) {
    text-align: center;
  }
}

.testimonial-quote--content {
  > * {
    @include animate-element('text', '.testimonial-quote--content');
    @include animate-delay(3);
  }

  .icon {
    margin-bottom: 28px;
  }

  .quote {
    color: $c-purple-primary;
    font-family: Spezia;
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 28px;

    p {
      font-family: Spezia;
      font-size: 24px;
      line-height: 40px;
    }
  }

  .person-details {
    width: 100%;

    @include bp(lg) {
      display: flex;
      justify-content: center;
    }

    div {
      width: 100%;

      @include bp(md) {
        width: 190px;
      }
    }

    :last-child {
      @include bp(md) {
        padding-left: 12px;
        text-align: left;
      }
    }

    :not(:last-child) {
      position: relative;

      @include bp(md) {
        padding-right: 12px;
        text-align: right;
      }

      &::after {
        content: '';
        display: none;
        height: 12px;
        top: 4px;
        width: 1px;
        right: 0;
        border-right: 1px solid black;

        @include bp(md) {
          display: block;
          position: absolute;
        }
      }
    }
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .testimonial-quote--container {
      .quote {
        color: $color;
      }
    }
  }
}
