.header-feature-consultant-directory {
  position: relative;
  margin-top: -161px;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 1);
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
  padding: 40px 0 0;
  display: flex;
  color: $c-white;
  flex-direction: column;
  position: relative;

  @include bp(sm) {
    height: 512px;
  }

  &::before {
    background: $c-black-40;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &.no-overlay {
    &::before {
      display: none;
    }
  }

  .layout-container {
    @include container-inset;

    @include bp(md) {
      position: relative;
      height: 100%;
    }

    > * {
      @include animate-element('text', '.layout-container');
      @include animate-delay(2);
    }
  }

  &--content {
    position: relative;
    z-index: 1;
  }

  &--description {
    margin-top: 132px;

    @include bp(sm) {
      width: calc(100% - 285px);
    }

    @include bp(md) {
      margin-top: 117px;
      position: absolute;
      top: calc(50% - 220px);
      width: calc(100% - 150px);
    }

    @include bp(xl-large) {
      width: 100%;
    }
  }

  .breadcrumbs {
    &-wrapper {
      margin-bottom: 10px;
      margin-left: 0;
    }

    &--item {
      img {
        filter: invert(1);
      }

      a {
        color: $c-white;
      }

      &:nth-child(3) {
        background: -webkit-linear-gradient(180deg, transparent, #fff 30%);
        -webkit-background-clip: text;
      }
    }
  }

  &--title {
    font-family: $font-family-sans;
    font-weight: $font-weight-bold;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &--subtitle {
    font-family: $font-family-serif;
    font-weight: 300;
    font-size: 34px;
    line-height: 42px;

    @include bp(lg) {
      font-size: 60px;
      line-height: 62px;
    }
  }
}

// For the cloned navbar
.navigation-blend-header-feature-consultant-directory {
  z-index: 2;
  position: absolute;
  color: $c-white;
  overflow-x: hidden;
  left: 0;
  right: 0;

  .navigation {
    width: calc(100vw - 60px);

    @include bp(lg) {
      width: calc(100vw - 72px);
    }

    border-bottom-color: white;
    fill: white;

    svg * {
      filter: brightness(0) invert(1);
    }

    > .menu--trigger span {
      background: white;
    }

    nav a {
      color: white;

      &.expanded::after {
        filter: brightness(0) invert(1);
      }
    }
  }
}

.header-feature-consultant-directory--summary {
  width: 270px;
  height: 245px;
  overflow: hidden;
  padding: 32px 26px;
  background-color: $c-purple-primary;
  color: white;
  font-family: Spezia;
  bottom: 0;

  @include bp(md) {
    position: absolute;
    margin-left: auto;
    right: 0;
  }

  @include bp(lg) {
    bottom: -72px;
  }

  .title {
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .text {
    margin-top: 14px;
    font-size: 16px;
    line-height: 28px;
    height: 166px;
    max-height: 166px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// Updated to adjust margin 10.25.24 BM
