.placeholder-to-work-in-storybook {
  min-height: 100vh;
  min-width: 100vw;
}

.authors-list--authors {
  > * {
    @include animate-element('text', '.authors-list--authors');
    @include animate-delay(10);
  }
}

.authors-list--wrapper {
  background-color: rgba(0, 0, 0, 0.15);
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  cursor: pointer;
  display: block;
  z-index: 5;

  a {
    text-decoration: none;
    color: inherit;
  }

  *::-webkit-scrollbar {
    width: 1px;
  }

  /* Track */
  *::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  *::-webkit-scrollbar-thumb {
    background: #888;
    border: 0px;
  }

  /* Handle on hover */
  *::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &.close {
    animation: AuthorsListFadeOut 0.7s 0.7s;
    animation-fill-mode: forwards;
    opacity: 1;
    width: 100%;
    height: 100%;
  }

  &.open {
    animation: AuthorsListFadeIn 0.7s;
    animation-fill-mode: forwards;
    opacity: 1;
    width: 100%;
    height: 100%;
  }
}

.authors-list--wrapper:not(.open) .authors-list--container,
.authors-list--wrapper.close .authors-list--container {
  bottom: -100%;
  transition-delay: 0s;
  display: block;
}

.authors-list--wrapper.open .authors-list--container {
  transition-delay: 0.7s;
  display: block;
  bottom: 0;
}

.authors-list--container {
  cursor: auto;
  background-color: black;
  color: white;
  padding: 32px;
  max-width: 980px;
  width: 100%;
  height: 100vh;
  transition: all 0.7s;
  bottom: -100%;
  display: none;
  position: absolute;

  @include bp(md) {
    height: calc(100vh - 200px);
  }
}

.authors-list--first-line {
  display: flex;
  justify-content: flex-end;
}

.authors-list--btn-close {
  background-color: transparent;
  border: 0px;
  color: white;
  font-family: Spezia;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  height: 20px;
  text-align: center;
  display: flex;
  align-items: center;

  img {
    margin-left: 10px;
    width: 20px;
    height: 20px;
  }
}

.authors-list--authors {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  font-family: Spezia;
  margin-top: 28px;
  overflow: auto;
  height: calc(100vh - 100px);
  scrollbar-color: #888 transparent;
  scrollbar-width: thin;

  @include bp(md) {
    margin-top: 10px;
    height: calc(100vh - 300px);
  }

  .author {
    // flex-grow: 1;
    width: 100%;
    display: flex;
    margin-bottom: 25px;

    @include bp(md) {
      width: 50%;
    }

    @include bp(lg) {
      width: 33%;
    }

    .image {
      border-radius: 50%;
      height: 60px;
      overflow: hidden;
      width: 60px;
      margin-right: 19px;
      margin-left: 16px;
      flex: 0 0 60px;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
        border-radius: 50%;
        transition: transform 0.35s;
      }

      &:hover {
        img {
          transform: scale(110%) !important;
          // added !important because animate-element @include overwrites any transform - tdeluca - 07092021
        }
      }

      @include bp(lg) {
        margin-left: 0;
      }
    }

    .details {
      padding: 0 10px 0 0;
    }

    .name {
      font-size: 16px;
    }

    .about {
      font-size: 13.5px;
      line-height: 24px;
      margin-top: 12px;
    }

    .location {
      margin-top: 16px;
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
      letter-spacing: 1px;
      padding-left: 15px;
      position: relative;
      text-transform: uppercase;

      img {
        left: 0;
        position: absolute;
        top: 5px;
      }

      span {
        display: block;
      }
    }
  }
}

@keyframes AuthorsListFadeOut {
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0.01;
    width: 100%;
    height: 100%;
  }

  100% {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

@keyframes AuthorsListFadeIn {
  100% {
    opacity: 1;
  }

  1% {
    opacity: 0.01;
    width: 100%;
    height: 100%;
  }

  0% {
    opacity: 0;
    width: 0;
    height: 0;
  }
}
