.rta-transition {
  transition: all 0.175s ease-in-out;
}

.rta-flipIn {
  animation: flipdown 0.35s ease both;
}

.rta-no-select {
  -webkit-tap-highlight-color: $c-black;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rta-tags {
  display: flex;
  flex-flow: column wrap;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
  max-height: 800px;
  @extend .rta-transition;
  opacity: 1;
  transform: translate(0, 0);
  margin-top: 30px;
  z-index: 2;

  @include bp(lg) {
    flex-direction: row;
  }

  .tag {
    cursor: default;
    margin: 0 13px 13px 0;
    font-size: 13px;
    line-height: 22px;

    @include bp(lg) {
      flex: calc(50% - 13px);
    }
  }
}

.rta-title {
  color: $c-black;
  border-bottom: 0.25px solid $c-black;
  padding-bottom: 16px;
  font-size: 30px;
  line-height: 48px;
  font-weight: 300;
  letter-spacing: 1px;
  display: block;
  background-color: $c-white;
  margin: 0;
  @extend .rta-no-select;
}

.rta-rich-text {
  color: $c-black;
  margin-top: 36px;
  width: 100%;
  font-family: $font-family-sans;
  font-size: 16px;
  line-height: 28px;

  > * {
    @include animate-element('text', '.rta-rich-text');
    @include animate-delay-exact(2 3 3);
  }

  .rich-text-basic {
    .rich-text-basic__text {
      margin: 0 0 36px;
    }
  }

  .rich-text-blockquote {
    padding: 0 0 16px;
  }

  .rich-text-basic,
  .rich-text-blockquote {
    .layout-container {
      margin: 0;
      width: 100%;
    }
  }

  #rta-more {
    display: none;
    opacity: 0;
    visibility: hidden;
    animation: fade 0.2s;

    > * {
      @include animate-element('text', '#rta-more');
      @include animate-delay(2);
    }
  }

  #rta-btn-more,
  #rta-btn-less {
    display: inline-block;
    cursor: pointer;
    width: -moz-fit-content;
    width: fit-content;
    color: $c-purple-primary;
    border-bottom: 1px solid $c-purple-primary;
  }
}

.rich-text-accordion {
  margin: 0 auto;
  max-width: 1128px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 95px;

  > * {
    @include animate-element('text', '.rich-text-accordion');
    @include animate-delay-exact(1 1);
  }

  > input[type="checkbox"] {
    display: none;
  }

  #rta-btn-less,
  #rta-more {
    display: none;
    opacity: 0;
    visibility: hidden;
  }

  &--open {
    #rta-more {
      display: block;
    }

    #rta-btn-less {
      display: inline-block;
    }

    #rta-more,
    #rta-btn-less {
      opacity: 1;
      visibility: visible;
    }

    #rta-btn-more {
      display: none;
      opacity: 0;
      visibility: hidden;
    }
  }

  @include bp(lg) {
    padding-bottom: 130px;
    padding-left: 454px;
    padding-right: 135px;
  }

  ul {
    list-style: none;
    perspective: 900;
    padding: 0;
    margin: 0;

    input {
      cursor: pointer;
    }

    li {
      position: relative;
      padding: 0;
      margin: 0;
      cursor: pointer;

      &:nth-of-type(1) {
        animation-delay: 0.5s;
      }

      &:nth-of-type(2) {
        animation-delay: 0.75s;
      }

      &:nth-of-type(3) {
        animation-delay: 1s;
      }

      &:last-of-type {
        padding-bottom: 0;
      }

      i {
        position: absolute;
        transform: translate(-6px, 0);
        margin-top: 16px;
        right: 0;
        z-index: 1;

        &:before,
        &:after {
          content: '';
          @extend .rta-transition;
          position: absolute;
          background-color: $c-black;
          width: 3px;
          height: 9px;
        }

        &:before {
          transform: translate(-10px, 0) rotate(45deg);
        }

        &:after {
          transform: translate(-6px, 0) rotate(-45deg);
        }
      }

      input[type='checkbox'] {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0 !important;

        &:checked {
          & ~ p {
            margin-top: 0;
            max-height: 0;
            opacity: 0;
            transform: translate(0, 50%);
          }

          & ~ i {
            &:before {
              transform: translate(-6px, 0) rotate(45deg);
            }

            &:after {
              transform: translate(-10px, 0) rotate(-45deg);
            }
          }
        }
      }
    }
  }
}

@keyframes flipdown {
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg);
  }

  5% {
    opacity: 1;
  }

  80% {
    transform: rotateX(8deg);
  }

  83% {
    transform: rotateX(6deg);
  }

  92% {
    transform: rotateX(-3deg);
  }

  100% {
    transform-origin: top center;
    transform: rotateX(0deg);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
