.footer-container {
  &.bg-color {

    &.gray {
      background-color: $c-gray-soft;

      .footer--top {

        .heading5,
        h5 {
          color: $c-gray-light;
        }
      }
    }

    &.black {
      background-color: $c-black;

      .footer--top {

        .heading5,
        h5 {
          color: $c-gray-lighter;
        }
      }
    }

    &.blue {
      background-color: $c-blue-primary;

      .footer--top {

        .heading5,
        h5 {
          color: $c-gray-pale;
        }
      }
    }

    &.purple {
      background-color: $c-blue-primary;

      .footer--top {

        .heading5,
        h5 {
          color: $c-gray-pale;
        }
      }
    }

    &.green {
      background-color: $c-green-primary;

      .footer--top {

        .heading5,
        h5 {
          color: $c-white;
        }
      }
    }

    &.burgundy {
      background-color: $c-burgundy-primary;

      .footer--top {

        .heading5,
        h5 {
          color: $c-gray-pale;
        }
      }
    }

    &:not(.gray){
      footer {
        border-top: .5px solid $c-white;

        .footer--quick-links {
          a {
            color: $c-white;
          }
        }

        .footer--office {

          p {
            color: $c-white;
          }
        }

        .footer--social {

          a {

            img {
              filter: brightness(0) invert(1);
            }
          }
        }

        .mktoForm .mktoFieldWrap {

          label.mktoLabel {
            color: $c-white;
          }

          input[type=email] {
            border-bottom-color: $c-white;
            color: $c-white;
          }
        }

        .multi-step-form .marketo-controls-container button img {
          filter: none;
        }

        .footer--bottom span,
        .footer--bottom span a {
          color: $c-white;
        }
      }

      .email-only--container {

        .email-only--header {

          .title  {

            p,
            .heading1 {
              color: $c-white;
            }
          }
        }
      }
    }
  }
}

footer {
  @include container-inset;

  > * {
    @include animate-element('text', 'footer');
    @include animate-delay(2);
  }
}

footer .footer--top {
  display: flex;
  flex-direction: column;
  border-bottom: 0.5px solid rgba($c-gray-lighter, 0.25);
  padding: 95px 0 0;

  @include bp(lg) {
    flex-direction: row;
  }
}

footer .footer--top .footer--top-column {
  margin: 0 0 75px;

  @include bp(lg) {
    width: 30%;

    &:last-of-type {
      width: 40%;
    }
  }
}

footer .footer--top h2 {
  font-size: 36px;
  line-height: 1.33;
}

footer .footer--top {

  h5,
  .heading5 {
    @extend %button-txt;
    color: $c-gray-lighter;
    font-weight: 600;
    margin: 0 0 25px;
  }
}

.footer--quick-links a {
  @include animate-underline($c-black);
  color: $c-black;
  font-family: $font-family-sans;
  display: inline;
  width: auto;
  font-size: 20px;
  line-height: 1.5;
  margin: 0 0 20px;
}

.footer--office p {
  font-size: 12px;
  line-height: 3;
}

.footer--office .change-location {
  @include animate-underline-reverse($c-blue-primary);
  color: $c-blue-primary;
  font-size: 12px;
  padding: 0 0 5px;
}

.footer--social {
  margin-top: 60px;

  @include bp(lg) {
    margin-top: 50px;
  }
}

.footer--social a {
  text-decoration: none;
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }
}

.footer--social svg {
  width: 24px;
  height: 24px;
}

.footer--contact {
  margin: 0 0 100px;
}

.footer--bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  transition-delay: 1s;
  padding: 40px 0 86px;
  font-size: 12px;
  line-height: 2.3;
  letter-spacing: 1.75px;
  font-family: $font-family-sans;
  font-weight: $font-weight-medium;
  text-align: center;
  color: $c-gray-lighter;
}

.footer--bottom span {
  display: block;
  font-weight: 400;
  margin: 0 10px;

  @include bp(lg) {
    flex-grow: 0;
  }

  &:last-child {
    flex: 0 0 100%;
  }

  a {
    color: $c-gray-lighter;
    padding: 0 0 8px;
    text-decoration: none;
    text-transform: uppercase;
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .footer--office .change-location {
      @include animate-underline-reverse($color);
      color: $color;
    }
  }
}
