.landing-subnav--container {
  background-repeat: no-repeat;
  background-size: cover;
  color: $c-black;

  .breadcrumbs-wrapper {
    margin-left: 0px;
  }
}

.landing-subnav--content {
  @include container-inset;
  padding-top: 161px;
  margin-top: -161px;
}

.landing-subnav--item {
  padding-top: 11px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include bp(lg){
    flex-direction: row;
  }

  // > * {
  //   @include animate-element('text', '.landing-subnav--item');
  //   @include animate-delay-exact(1 3);
  // }
}

.landing-subnav--intro {
  flex: 1;
  margin-bottom: 40px;

  @include bp(lg) {
    flex-direction: row;
    margin-bottom: 0;
  }

  // > * {
  //   @include animate-element('text', '.landing-subnav--intro');
  //   @include animate-delay(2);
  // }

  .title {
    color: $c-green-primary;
    font-family: Teodor;
    font-size: 34px;
    line-height: 42px;
    margin-bottom: 32px;

    @include bp(lg) {
      font-size: 60px;
      line-height: 62px;
    }
  }

  .text {
    font-family: Spezia;
    font-size: 16px;
    line-height: 28px;
    max-width: 501px;
    max-height: 112px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.landing-subnav--nav-wrapper {

  @include bp(lg) {
    display: block;
    width: 250px;
  }

  .active {
    font-family: Spezia;
    font-size: 11px;
    font-weight: 600;
    text-align: right;
    letter-spacing: 1px;
    margin-right: 20px;
    margin-bottom: 20px;
    line-height: 20px;
    text-transform: uppercase;
  }
}

.landing-subnav--nav {
  max-height: 250px;
  overflow-y: auto;
  padding-right: 20px;
  scrollbar-color: $c-black rgba($c-black, 0.3);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    width: 10px;
    padding: 0px;
    border-radius: 9999px;
    background-color: rgba($c-black, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border: 0px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: $c-black;
  }

  div {
    font-family: Spezia;
    font-size: 11px;
    text-align: right;
    letter-spacing: 1px;
    margin-bottom: 20px;
    line-height: 20px;
    text-transform: uppercase;

    a {
      @include animate-underline($c-green-primary, 4px);
      color: $c-black;
      text-decoration: none;
      padding-bottom: 10px;
      text-transform: none;
      font-family: Spezia;
      font-size: 16px;
      line-height: 28px;

      &.active {
        background-image: linear-gradient($c-green-primary, $c-green-primary);
        background-size: 100% 4px;
        background-position: 0 100%;
        background-repeat: no-repeat;
        font-size: 16px;
        font-weight: normal;
        letter-spacing: normal;
        margin: 0;
        line-height: 28px;
        text-transform: none;
      }
    }
  }
}

.landing-subnav--outside-nav {
  @include container;
  padding: 0px 30px 60px;
  display: flex;
  justify-content: center;

  @include bp(lg) {
    display: none;
  }

  > * {
    @include animate-element('text', '.landing-subnav--outside-nav');
    @include animate-delay(1, 2);
  }

  .landing-subnav--filters {
    width: 100%;
    height: 38px;
    border: 0.5px solid $c-black;
    overflow: hidden;

    @include bp(lg) {
      position: relative;
      width: 85%;
      height: auto;
      overflow: hidden;
      display: flex;
      border: 0;

      // Fadeout edge/cutoff.
      &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: 100px;
        height: 100%;
        background: linear-gradient(90deg, transparent, $c-gray-soft 75%);
      }
    }

    button,
    a {
      @extend .btn-tag;
      @extend .btn-tag--black;

      @include bp(lg) {
        display: block;
        width: auto;
        margin-right: 13px;
        margin-bottom: 13px;
        flex-shrink: 0;

        &:last-of-type {
          margin-right: 0;
          border-bottom: 0.5px solid $c-black;
        }
      }

      &.mobile-label {
        @extend %btn;
        @include caret(12px);
        display: flex;
        color: $c-black;
        font-weight: $font-weight-medium !important;
        font-size: 11px !important;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        text-transform: uppercase !important;
        width: 100%;

        ~ button,
        ~ a {
          width: 100%;

          @include bp(lg) {
            width: auto;
          }
        }

        &::after {
          position: static;
        }

        &:focus {
          color: $c-black;
        }

        &:hover {
          &::after {
            transform: none;
          }
        }
      }
    }

    &.active {
      button.mobile-label {
        &::after {
          transform: rotate(-180deg);
        }
      }
    }
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .landing-subnav--intro {
      .title {
        color: $color;
      }
    }

    .landing-subnav--nav {
      div {
        a {
          @include animate-underline($color, 4px);

          &.active {
            background-image: linear-gradient($color, $color);
          }
        }
      }
    }
  }
}
