@use "sass:map";

.navigation-main-container {
  background-color: $c-white;
  left: 0;
  margin-bottom: 56px;
  padding: 0 30px;
  position: fixed;
    right: 0;
    top: 0;
  transition: all 0.28s ease-in-out;
  z-index: 6;

  &.white-over-image {
    background-color: transparent;
  }

  &.hide-nav {
    background-color: transparent;
    z-index: 4;

    .navigation {

      @include bp-down('lg'){
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

.navigation.show-initialAfterElement {
  &:before,
  &.sticky-nav--white:before {
    background: transparent;
  }
  &::before,
  &::after {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.28s ease-in-out;
  }

  nav {
    transition: opacity 0.28s ease-in-out;
  }
}

.navigation.show-afterElement {
  &:before {
    background: $c-white;
  }
  &.sticky-nav--white:before {
    // background: $c-black;
  }
  &::before,
  &::after {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.28s ease-in-out;
  }

  nav {
    transition: opacity 0.28s ease-in-out;
  }
}

.navigation {
  align-items: center;
  border-bottom: 0;
  display: flex;
  line-height: 1.15;
  margin-left: auto;
  margin-right: auto;
  max-width: 1128px;
  min-height: 103px;;
  padding: 32px 0;
  transition: all 0.35s;
  width: 100%;
  z-index: 5;

  .full-width & {
    max-width: 100%;
  }

  &::before {
    // only if input is not checked
    // background: $c-white;
    bottom: -1px;
    content: '';
    display: block;
    left: 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    transition: opacity 0.28s ease-in-out, visibility 0s ease-in-out 0.28s;
    right: 0;
    top: 0;
  }

  &::after {
    border-bottom: 0.5px solid $c-black;
    bottom: 0;
    content: '';
    display: block;
    margin: 0 auto;
    max-width: 1128px;
    opacity: 0;
    transition: opacity 0.28s ease-in-out, visibility 0s ease-in-out 0.28s;
    visibility: hidden;
    left: 30px;
    position: absolute;
    right: 30px;

    @include bp(lg) {
      left: 0;
      right: 0;
    }
  }

  &-header--open {
    &::before,
    &::after {
      display: none;
    }
  }

  &.mega-menu--open {
    &::before {
      opacity: 1;
      transition-delay: 0s;
    }
  }

  .search-mobile {
    display: block;

    @include bp(lg){
      display: none;
    }
  }
}

.navigation .logo {
  z-index: 1;
  svg {
    width: 95px;
    height: 36px;
  }

  > .icon.logo {
    &.is-black {
      filter: brightness(0);
    }
  }
}

.navigation nav {
  display: none;
  line-height: 1.15;
  transition: opacity 0.28s ease-in-out, visibility 0s ease-in-out 0.28s;
  position: relative;

  @include bp(lg) {
    display: flex;
    margin-left: auto;
  }

  .menu--dropdown {
    display: flex;
    line-height: 1.15;
    margin-right: 48px;
    padding-right: 15px;
    position: relative;
    width: -moz-fit-content;
    width: fit-content;

    @include bp(lg) {
      margin-right: 28px;
    }

    @include bp(xl) {
      margin-right: 48px;
    }

    & > input[type='checkbox'] {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0 !important;

      &:checked {
        & ~ div {
          // utility_navigation_expanded
          margin-top: 0;
          max-height: 0;
          opacity: 0 !important;
          transform: translate(0, -100%);
          visibility: hidden;
        }

        & ~ a {
          &:before {
            transform: translate(18px, 8px) rotate(45deg);
          }

          &:after {
            transform: translate(14px, 8px) rotate(-45deg);
          }
        }
      }
    }

    & > a {
      font-family: -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Helvetica,
        Arial,
        sans-serif,
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol";
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 1.15;
      width: -moz-fit-content;
      width: fit-content;
      text-transform: uppercase;
      white-space: nowrap;

      &,
      &:link,
      &:active,
      &:visited {
        text-decoration: none;
        color: inherit;
      }
    }

    & > input ~ a {
      // Only for submenus
      &:before,
      &:after {
        content: '';
        @extend .navigation-header--transition;
        position: absolute;
        background-color: $c-black;
        width: 1px;
        height: 7px;
        right: 21px;
        top: -4px;
        pointer-events: none;
      }

      &:before {
        transform: translate(14px, 8px) rotate(45deg);
      }

      &:after {
        transform: translate(18px, 8px) rotate(-45deg);
      }
    }

    &:hover > input,
    &:hover > a {
      cursor: pointer;
    }

    &.language {
      margin-left: 48px;
      margin-right: 28px;

      &:before {
        border-left: 1px solid $c-black;
        content: '';
        height: 1em;
        position: absolute;
          left: -48px;
        width: 1px;

        .white-over-image & {
          border-color: $c-white;
        }
      }
    }

    &.login {
      margin-right: 28px;

      .navigation-header--title img {
        min-width: 14px;
      }
    }

    .utility_navigation_expanded {
      transition: all 0.35s ease-in-out;
    }

    .utility_dropdown {
      background-color: $c-white;
      padding: 20px 30px;
      position: absolute;
        right: 0;
        top: 100%;
      z-index: 9;

      @include bp('xl-large'){
        left: -30px;
        right: initial;
      }

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {

          a {
            color: $c-black;
            display: block;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 1px;
            padding: 10px 0;
            text-decoration: none;
            text-transform: uppercase;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.navigation button {
  @extend %btn;
  @extend %btn-reset;
}

.navigation > nav > .search--trigger,
.navigation > .search-mobile > .search--trigger {
  border: none;
  margin-left: auto;
  position: relative;
  z-index: 0;

  svg {
    width: 16px;
    height: 16px;
  }

  &--open-menu {
    display: none;
    @include bp(lg) {
      svg {
        filter: brightness(0) invert(1);
      }
    }
  }
}

.navigation > .search-mobile {
  margin-left: auto;

   > .search--trigger {

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.nav--open-close-menu > button {
  border: none;
  position: relative;
  width: 27px;
  height: 20px;
  margin-left: 24px;
}

.nav--open-close-menu > .menu--trigger span {
  position: absolute;
  right: 5px;
  display: block;
  width: 100%;
  height: 1px;
  background: $c-black;

  .white-over-image & {
    background: $c-white;
  }
}

.nav--open-close-menu > .menu--trigger span:nth-child(2) {
  top: 0;
  width: 20px;
}

.nav--open-close-menu > .menu--trigger span:nth-child(3) {
  top: 8px;
  width: 12px;
}

.nav--open-close-menu > .menu--trigger span:nth-child(4) {
  top: 16px;
  width: 6px;
}

.nav--open-close-menu {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;

  @include bp(lg){
    display: none;
  }
}

button.menu--trigger--close {
  visibility: hidden;
  height: 20px;
  display: none;
  span {
    transform: translate(-6px, 0);
    position: absolute;
    right: 5px;
    top: -17px;
    @include bp(lg) {
      background-color: $c-white !important;
    }

    content: '';
    position: absolute;
    background-color: $c-black;
    width: 1px;
    height: 0px; // 16px

    &:nth-child(2) {
      transform: translate(-4px, 16px) rotate(-45deg);
    }

    &:nth-child(3) {
      transform: translate(-4px, 16px) rotate(45deg);
    }
  }
}

.utility_navigation_expanded {
  border-top: 103px solid $c-white;
  position: fixed;
  top: 0;
  padding: 0 24px;
  left: 0;
  width: 100%;
  height: auto;
  max-height: 800px;
  overflow: hidden;
  z-index: -1;

  @include bp('xl-large') {
    padding: 0;
  }

  &--content {
    column-gap: 10px;
    display: grid;
    grid-template-columns: repeat(12, calc((100% - 110px) / 12));
    margin: 0 auto;

    @include bp('xl-large'){
      column-gap: 20px;
      grid-template-columns: 1fr repeat(12, calc((1128px - 220px) / 12)) 1fr;
    }

    > ul {
      grid-column: 1 / span 9;
      grid-row: 1 / 2;

      @include bp('xl-large') {
        grid-column: 2 / span 9;
      }


      .menu--dropdown:not(.capabilities) .utility_navigation_expanded & {
        box-sizing: border-box;
        margin: 60px 0;
        max-width: inherit;
        position: relative;

        > li {
          align-items: flex-start;
          display: flex;
          width: 100%;

          &.hasChild {

            .link-wrapper {
              align-items: center;
              cursor: pointer;
              display: flex;
              flex: 0 0 43%;
              justify-content: space-between;
              margin-bottom: 13px;

              > a {
                padding: 10px;
              }

              i {
                height: 16px;
                padding: 10px;
                position: relative;
                width: 16px;

                &:before,
                &:after {
                  content: '';
                  @extend .navigation-header--transition;
                  position: absolute;
                  background-color: $c-gray-light;
                  width: 1px;
                  height: 7px;
                  right: 21px;
                  top: -4px;
                  pointer-events: none;
                }

                &:before {
                  transform: translate(18px, 5px) rotate(135deg);
                }

                &:after {
                  transform: translate(18px, 10px) rotate(-135deg);
                }
              }
            }

            &.active {

              .link-wrapper {
                color: $c-green-primary;

                i{
                  &:before {
                    color: $c-green-primary;
                    transform: translate(18px, 5px) rotate(45deg);
                  }

                  &:after {
                    color: $c-green-primary;
                    transform: translate(18px, 10px) rotate(-45deg);
                  }
                }
              }

              .level-3 {
                opacity: 1;
                  left: 382px;
                  top: 0;
              }
            }
          }

          > ul {
            flex: 1 1 50%;
            max-width: inherit;
            overflow: hidden;
            width: 50%;

            &.level-3 {
              opacity: 0;
              position: absolute;
                left: 200%;
              transition: opacity 250ms;

              .menu-header {
                border-bottom: 1px solid $c-black;
                margin-bottom: 7px;
                padding-bottom: 20px;
                width: 100%;
              }
            }

            li {

              a {
                font-size: 16px;
                font-family: $font-family-sans;
              }
            }
          }

          > a {
            flex: 0 0 48%;
            margin-right: 2%;

              &.menu-title {
                font-family: $font-family-serif;
                font-size: 27px;
                font-weight: 300;
                line-height: 32px;
                margin-top: 0;
              }
          }
        }
      }

      .menu--dropdown.capabilities .utility_navigation_expanded & {
        column-gap: 20px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin: 24px 0;
        max-width: none;

        > li {
          margin-top: 30px;

          .link-wrapper:first-child {

            a {
              color: $c-gray-dark;
              font-family: $font-family-serif;
              font-size: 27px;
              font-weight: 300;
              line-height: 32px;
            }
          }

          ul.level-3 {

            li {

              &.menu-header {
                display: none;
              }

              &.more {

                .link--button {
                  display: inline-flex;
                  font-size: 11px;
                  font-weight: 600;
                  line-height: 20px;
                }
              }
            }
          }
        }
      }

      .menu--dropdown .utility_navigation_expanded & {

        + div:not(.banner) {
          aspect-ratio: 56/95;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          flex: 0 0 33%;
          grid-column: 10 / span 3;
          height: 100%;
          padding: 0;
          position: relative;

          @include bp('xl-large') {
            grid-column: 11 / span 4;
          }
        }
      }
    }

    .banner {
      aspect-ratio: 56/95;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      flex: 0 0 33%;
      flex-direction: column;
      grid-column: 10 / span 3;
      height: 100%;
      max-width: 100%;
      padding: 38px;
      position: relative;
      text-decoration: none;

      @include bp('xl-large') {
        grid-column: 11 / span 4;
      }

      &:before {
        background-color: rgba(0,0,0,0.7);
        content: '';
        position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
        z-index: 1;
      }

      > * {
        color: $c-white;
        position: relative;
        z-index: 9;
      }

      &-title h2 {
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-family: $font-family-serif;
        font-size: 48px;
        font-weight: 300;
        line-height: 56px;
      }

      &:not(.page-link) {

        > a {
          text-decoration: none;

          .banner-title h2,
          .banner-body p {
            background-image: linear-gradient($c-white, $c-white);
            background-size: 0 1px;
            background-position: 100% 100%;
            background-repeat: no-repeat;
            display: inline;
            transition: background-size .35s;
            color: $c-white;
            text-decoration: none;
          }

          img {
            margin-top: 10px;
          }
        }

        &:hover {

          > a {

            .banner-title h2,
            .banner-body p {
              background-position: 0 100%;
              background-size: 100% 1px;
            }
          }
        }
      }

      p {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      a {
        height: 100%;

        &.insight-link,
        &.insight-link-alt {
          border: 1px solid $c-white;
          display: inline-block;
          font-family: $font-family-sans;
          font-size: 13px;
          margin-right: 5px;
          padding: 10px 20px;
          text-decoration: none;
        }
      }

      .banner__date {
        align-self: flex-start;
        display: inline-grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        column-gap: 10px;
        margin-bottom: 22px;

        .date-day,
        .date-month,
        .date-year {
          font-family: $font-family-serif;
          font-weight: 300;
        }

        .date-month,
        .date-year {
          font-size: 18px;
          line-height: 26px;
        }

        .date-day {
          grid-column: 1/2;
          grid-row: 1/4;
          font-size: 90px;
          line-height: 80px;
          letter-spacing: -2px;
        }

        .date-month {
          grid-column: 2/3;
          grid-row: 1/2;
        }

        .date-year {
          grid-column: 2/3;
          grid-row: 2/3;
        }
      }
    }
  }

  ul {
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-right: 100px;
    max-width: 400px;

    &:last-child {
      margin-right: 0;
    }

    li {
      font-family: $font-family-serif;
      font-weight: 300;
      line-height: 40px;
      text-decoration: none;
      color: $c-gray-dark;
      font-size: 27px;
      text-transform: none;
      padding: 1px;

      &:first-child {
        margin-top: 0;
      }

      a {
        cursor: pointer;
        text-decoration: none;
        display: inline-block;
        font-family: $font-family-sans;
        font-weight: 400;
        font-size: 17px;
        line-height: 30px;
        color: $c-gray-light;

        &.menu-title {
          font-family: $font-family-serif;
          font-weight: 300;
          line-height: 23px;
          text-decoration: none;
          color: $c-black;
          font-size: 22px;
          text-transform: none;
        }
      }
    }

    .utility_navigation_expanded--small-title {
      font-family: $font-family-sans;
      font-size: 11px;
      line-height: 20px;
      letter-spacing: 1px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}

.badge {
  content: '101';
  font-size: 13.5px;
  font-weight: 400px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(100%, -50%);
}

.menu-marker {
  display: none;
  height: 6px;
  position: absolute;
  bottom: -42px;
  width: 87px;
  z-index: 2;
  transition: all 0.525s;
}

$filter-blue-primary: invert(16%) sepia(75%) saturate(2905%) hue-rotate(222deg)
  brightness(95%) contrast(112%);
$filter-green-primary: invert(30%) sepia(95%) saturate(5566%) hue-rotate(177deg)
  brightness(96%) contrast(87%);
$filter-purple-primary: invert(7%) sepia(68%) saturate(7409%) hue-rotate(277deg)
  brightness(89%) contrast(111%);
$filter-burgundy-primary: invert(9%) sepia(56%) saturate(7499%)
  hue-rotate(326deg) brightness(92%) contrast(94%);

$colorFilter: (
  'homepage': $filter-blue-primary,
  'capabilities': $filter-green-primary,
  'expertise': $filter-green-primary,
  'insights': $filter-purple-primary,
  'people': $filter-burgundy-primary,
);
$sectionColors: (
  'homepage': $c-blue-primary,
  'capabilities': $c-blue-primary,
  'dark': $c-black,
  'expertise': $c-green-primary,
  'gray': $c-gray-soft,
  'insights': $c-purple-primary,
  'people': $c-burgundy-primary,
  'contact': $c-blue-primary,
);

$sectionMidColors: (
  'homepage': $c-blue-mid,
  'capabilities': $c-blue-mid,
  'expertise': $c-green-mid,
  'insights': $c-purple-mid,
  'people': $c-burgundy-mid,
  'contact': $c-blue-mid,
);

$sectionLightColors: (
  'homepage': $c-blue-light,
  'capabilities': $c-blue-light,
  'expertise': $c-green-light,
  'insights': $c-purple-light,
  'people': $c-burgundy-light,
  'contact': $c-blue-light,
);

$sectionPaleColors: (
  'homepage': $c-blue-pale,
  'capabilities': $c-blue-pale,
  'expertise': $c-green-pale,
  'insights': $c-purple-pale,
  'people': $c-burgundy-pale,
  'contact': $c-blue-pale,
);

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .navigation-main-container:not(.white-over-image){
      // .menu--dropdown.selected,
      // .navigation-header--utility-navigation li.selected,
      // .navigation-header--utility-navigation li.selected a {
      //   color: $color !important;
      //   background-position: 0 100%;
      //   background-size: 100% 1px;
      // }

      // .navigation-header--utility-navigation li.selected a,
      // .navigation-header--utility-navigation li.selected span[data-child] {
      //   background-image: linear-gradient($color, $color);
      // }

      // .menu--dropdown.selected a::before,
      // .menu--dropdown.selected a::after,
      // .navigation-header--utility-navigation li.selected i::after,
      // .navigation-header--utility-navigation li.selected i::before {
      //   background-color: $color !important;
      // }

      .menu--dropdown input:not(:checked) ~ a {
        color: $color !important;
      }

      .menu--dropdown input:not(:checked) ~ a::before,
      .menu--dropdown input:not(:checked) ~ a::after {
        background-color: $color !important;
      }

      .menu--dropdown:hover > a {
        color: $color !important;
      }

      .menu--dropdown:hover > a::before,
      .menu--dropdown:hover > a::after {
        background-color: $color !important;
      }

      .utility_navigation_expanded--content ul li a {
        position: relative;
        border-radius: 0;
        text-decoration: none;

        background-image: linear-gradient($color, $color);
        background-size: 0 1px;
        background-position: 100% 100%;
        background-repeat: no-repeat;
        transition: background-size 0.35s;
      }

      // .utility_navigation_expanded--content ul li a.selected,
      .utility_navigation_expanded--content ul li a:hover {
        color: $color !important;
        background-position: 0 100%;
        background-size: 100% 1px;
      }

      .navigation-header--breadcrumbs li {
        color: $color !important;
        position: relative;
        border-radius: 0;
        text-decoration: none;
        transition: background-size 0.35s;
      }

      .navigation-header--breadcrumbs li::before {
        color: $color !important;
      }
    }

    span.badge {
      color: $color;
    }

    div.menu-marker {
      background-color: $color;
    }

    .nav--open-close-menu > .menu--trigger:hover {
      span {
        background-color: $color;
      }
    }

    .search--trigger svg:hover {
      & * {
        //filter: map.get($colorFilter, $section);
      }
    }

    .tabs-list li .tabs-list__link.active {
      border-color: $color;
      color: $color;
    }

    /* video play button */
    .video-embed.v2.black {

      .video-embed__media-wrapper--title {

        .video-embed__play {
          border-color: $color;

          &:before {
            background-color: $color;
          }

          .video-embed__play-icon {

            svg {
              fill: $c-white;
            }
          }

          .video-embed__play-text {
            color: $c-white;
          }

          &:hover {

            .video-embed__play-icon {

              svg {
                fill: $color;
              }
            }

            .video-embed__play-text {
              color: $color;
            }
          }
        }
      }
    }
  }

  /* navigation */
  .page-container .navigation-main-container:not(.white-over-image) .navigation nav .menu--dropdown[data-section='#{$section}'],
  .page-container .navigation-main-container.white-over-image .navigation.mega-menu--open:not(.login--open):not(.language--open) nav .menu--dropdown[data-section='#{$section}'] {
    &:hover > a,
    input:not(:checked) ~ a {
      color: $color !important;

      &::before,
      &::after {
        background-color: $color !important;
      }
    }

    .utility_navigation_expanded .utility_navigation_expanded--content > ul > li.hasChild.active .link-wrapper {
      color: $color !important;

      i {

        &:before,
        &:after {
          background-color: $color !important;
        }
      }
    }

    //.utility_navigation_expanded--content ul li a.selected,
    .utility_navigation_expanded--content ul li a:hover {
      color: $color !important;
    }

    .utility_navigation_expanded--content ul li a {
      background-image: linear-gradient($color, $color);
    }

    // &.selected a::before,
    // &.selected a::after {
    //   background-color: $color !important;
    // }
  }

  .page-container .navigation-main-container:not(.white-over-image) .navigation div.menu-marker[data-section='#{$section}'],
  .page-container .navigation-main-container.white-over-image .navigation.mega-menu--open div.menu-marker[data-section='#{$section}'] {
    background-color: $color !important;
  }
}

#menu--dropdown {
  &--expertise {
    .utility_navigation_expanded--content {
      ul {
        margin-right: 50px;
        max-width: 240px;
      }
    }
  }
}
// Default color
.menu--dropdown input:not(:checked) ~ a {
  //color: map.get($sectionColors, 'homepage') !important;
}

.menu--dropdown input:not(:checked) ~ a::before,
.menu--dropdown input:not(:checked) ~ a::after {
  //background-color: map.get($sectionColors, 'homepage') !important;
}

.menu--dropdown:hover > a {
  //color: map.get($sectionColors, 'homepage') !important;
}

.menu--dropdown:hover > a::before,
.menu--dropdown:hover > a::after {
  // background-color: map.get($sectionColors, 'homepage') !important;
}

.utility_navigation_expanded--content ul li {
  display: block;
}

.utility_navigation_expanded--content ul li:nth-child(n+11) {
  display: none;
}

.utility_navigation_expanded--content ul li a {
  position: relative;
  border-radius: 0;
  text-decoration: none;
  display: inline;
  // background-image: linear-gradient(
  //   map.get($sectionColors, 'homepage'),
  //   map.get($sectionColors, 'homepage')
  // );
  background-size: 0 1px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  transition: background-size 0.35s;
}

.utility_navigation_expanded--content ul li a:hover,
.utility_navigation_expanded--content ul li a.selected {
  // color: map.get($sectionColors, 'homepage') !important;
  background-position: 0 100%;
  background-size: 100% 1px;
}

.navigation-header--breadcrumbs li {
  // color: map.get($sectionColors, 'homepage') !important;
  position: relative;
  border-radius: 0;
  text-decoration: none;
  transition: background-size 0.35s;
}

.navigation-header--breadcrumbs li::before {
  // color: map.get($sectionColors, 'homepage') !important;
}

span.badge {
  //color: map.get($sectionColors, 'homepage');
  color: $c-gray-light;
}

div.menu-marker {
  // background-color: map.get($sectionColors, 'homepage');
}

.page-container .white-over-image div.menu-marker,
.page-container[data-section] .white-over-image div.menu-marker {
  background-color: #ffffff;
}

.nav--open-close-menu > .menu--trigger:hover {
  span {
    // background-color: map.get($sectionColors, 'homepage');
  }
}

.search--trigger svg:hover {
  & * {
    filter: $filter-blue-primary;
  }
}

.utility_navigation_expanded {
  background-color: $c-gray-soft;
  // background-color: $c-white;
}

.sticky-nav--white {
  // used on .navigation
  // border-bottom: 0.5px solid $c-white;

  @include bp(lg) {
    border-bottom: 0;
  }

  // .menu--trigger,
  // .search--trigger--open-menu {
  //   span {
  //     background-color: $c-white;
  //   }
  // }

  // .menu--trigger:hover span {
  //   background-color: $c-white;
  // }

  // .icon.logo {
  //   filter: brightness(0) invert(1);
  // }

  // .search--trigger svg:hover {
  //   & * {
  //     filter: brightness(0) invert(1);
  //   }
  // }

  // .icon.search {
  //   filter: brightness(0) invert(1);
  // }

  // .menu {
  //   color: $c-white;

  //   &--dropdown {
  //     a::before,
  //     a::after {
  //       background-color: $c-white !important;
  //     }

  //     &:hover a {
  //       color: $c-white !important;
  //     }
  //   }
  // }

  // &::after {
  //   border-bottom: 0.5px solid $c-white !important;
  // }
}

.white-over-image {

  .navigation > nav > .search--trigger,
  .navigation > .search-mobile > .search--trigger {

    svg {
      filter: brightness(0) invert(1);
    }
  }

  &.full-width {

    svg {
      filter: brightness(0);
    }

    .navigation > nav > .search--trigger,
    .navigation > .search-mobile > .search--trigger {

      svg {
        filter: brightness(0);
      }
    }

    .navigation {

      &:after {
        border-bottom-color: $c-black;
      }
    }
  }

  .navigation {

    &:after {
      border-bottom-color: $c-white;
    }

    nav {

      .menu--dropdown {

        & > input ~ a {
          // Only for submenus
          &:before,
          &:after {
            background-color: $c-white;
          }
        }

        & > a {
          color: $c-white;
        }

        &:hover {

          > a {
            color: $c-gray-pale !important;
          }

          > input ~ a {
            color: $c-gray-pale !important;

            &:before,
            &:after {
              background-color: $c-gray-pale !important;
            }
          }
        }
      }
    }

    .logo {
      > .icon.logo {
        filter: brightness(0) invert(1);

        &.is-black {
          filter: brightness(0);
        }
      }
    }

    @include bp('md'){
      &::after {
        border-bottom-color: $c-white;
      }
    }

    &.mega-menu--open:not(.login--open):not(.language--open) {

      nav {

        .menu--dropdown {

          & > input ~ a {
            // Only for submenus
            &:before,
            &:after {
              background-color: $c-black;
            }
          }

          & > a {
            color: $c-black;
          }
        }
      }

      .logo {

        > .icon.logo,
        > .icon.logo.is-black {
          filter: none;
        }
      }

      &::after {
        border-bottom-color: $c-black;
      }
    }

    &.login--open,
    &.language--open {

      nav {

        .menu--dropdown {

          &>input~a {

            // Only for submenus
            &:before,
            &:after {
              background-color: $c-white !important;
            }
          }

          &>a {
            color: $c-white !important;
          }

          &:hover {

            >a {
              color: $c-gray-pale !important;
            }

            >input~a {
              color: $c-gray-pale !important;

              &:before,
              &:after {
                background-color: $c-gray-pale !important;
              }
            }
          }
        }
      }
    }
  }

  &.hide-nav {

    .navigation {

      .logo {

        > .icon.logo {
          filter: unset;
        }
      }
    }
  }
}
