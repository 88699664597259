.single-hero-image--container {
  position: relative;
}

.single-hero-image--strip {
  height: 230px;
  width: 100%;
  display: none;
  position: absolute;
  top: 120px;
  z-index: -1;

  @include bp(md) {
    display: block
  }

  @include bp(xl-large) {
    height: 380px;
  }
}

.single-hero-image--image {
  @include animate-element('image', '.single-hero-image--image');

  .image {
    height: 350px;
    width: 100%;
    z-index: 1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include bp(md) {
      max-width: 674px;
    }

    @include bp(lg) {
      height: 350px;
      max-width: 954px;
    }

    @include bp(xl-large) {
      height: 500px;
      width: 80%;
      max-width: 1400px;
    }
  }
}
