.infographic {
  background: $c-white;
  position: relative;

  .layout-container {
    margin: 0 auto;
    max-width: 1380px;
  }

  &__media {
    a {
      display: block;
      text-align: center;
    }
  }

  &-wrapper {
    padding: 60px 30px 70px;

    @include bp(lg) {
      padding: 120px 30px 80px;
    }

    > * {
      @include animate-element('text', '.infographic-wrapper');
      @include animate-delay(1);
    }
  }
}
