.podcast-carousel--wrapper {
  margin-top: -177px;
  position: relative;

  .carousel--controls-wrapper {
    height: 0;

    > * {
      @include animate-element('text', '.carousel--controls-wrapper');
      @include animate-delay(3, 2);
    }
  }

  .carousel--controls {
    display: none;

    @include bp(lg) {
      bottom: 275px;
      display: flex;
      justify-content: flex-end;
      position: relative;
      z-index: 3;
    }

    .glide__arrow {
      &::after {
        background-color: $c-white;
      }
    }

    &-wrapper {
      @include container-inset;
    }
  }
}

.podcast-carousel {
  display: flex;
  min-height: 761px;
  padding-bottom: 100px;
  padding-top: 37px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  &::before {
    background: $c-black-40;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &.no-overlay {
    &::before {
      display: none;
    }
  }

  @include bp(lg) {
    min-height: 661px;
    max-height: 661px;
    padding-bottom: 0;
  }
}

.podcast-carousel--content {
  @include container-inset;
  color: $c-white;
  padding-top: 152px;
  z-index: 9;

  > * {
    @include animate-element('text', '.podcast-carousel--content');
    @include animate-delay(3);
  }
}

.podcast-carousel--txt {
  font-family: Spezia;
  width: 100%;

  @include bp(md) {
    width: 85%;
  }

  @include bp(xl) {
    width: 70%;
  }

  .image-title {
    display: none;

    @include bp(md) {
      display: block;
    }
  }

  small a {
    text-decoration: none;
    color: $c-white;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .heading {
    font-family: Spezia;
    font-size: 16px;
    line-height: 28px;
    margin: 0 0 24px;
  }

  h2 {
    margin-top: 32px;

    @include bp(md) {
      margin-top: 16px;
    }

    a {
      @include animate-underline($c-white);
      text-decoration: none;
      font-family: Teodor;
      font-weight: 300;
      font-size: 34px;
      line-height: 42px;
      font-style: normal;
      font-weight: normal;
      color: $c-white;
      max-width: 646px;

      @include bp(md) {
        font-size: 60px;
        line-height: 62px;
      }
    }
  }

  h3 {
    font-family: Spezia;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: $c-white;

    .map-marker {
      margin-right: 8px;
    }
  }

  .image-mobile {
    display: block;

    @include bp(md) {
      display: none;
    }
  }
}

.podcast-carousel--hosted-by {
  p {
    width: 100%;
    font-family: Spezia;
    font-size: 13.5px;
    line-height: 24px;
    margin: 0 0 14px;
  }

  .hoster-container {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    @include bp(sm) {
      flex-direction: row;
    }
  }

  .hoster {
    display: flex;
    align-items: center;
    margin-bottom: 13px;

    @include bp(sm) {
      margin-right: 19px;
      margin-bottom: 0px;
    }

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 8px;
    }

    .hoster-name {
      font-family: Spezia;
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
}

.podcast-carousel--container {
  .glide__bullets {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 65px;
    z-index: 1;
  }

  .glide__bullets > .glide__bullet--active {
    border-color: $c-white;
    background-color: transparent;
  }

  .glide__bullets > button {
    background: $c-white;
  }
}

// For the cloned navbar
.navigation-blend-podcast-carousel {
  z-index: 2;
  position: absolute;
  color: $c-white;
  overflow-x: hidden;
  left: 0;
  right: 0;

  .navigation {
    width: calc(100vw - 60px);

    @include bp(lg) {
      width: calc(100vw - 72px);
    }

    border-bottom-color: $c-white;
    fill: $c-white;

    svg * {
      filter: brightness(0) invert(1);
    }

    > .menu--trigger span {
      background: $c-white;
    }

    nav a {
      color: $c-white;

      &.expanded::after {
        filter: brightness(0) invert(1);
      }
    }
  }
}

// Updated to adjust margin 10.25.24 BM
