.tabs {
	padding: 40px 0;

	.scrollable-container {
		margin: 0 -30px 62px;

		@include bp('md'){
			margin-left: 0;
			margin-right: 0;
		}
	}

	&-list {
		display: flex;
		list-style-type: none;
		padding: 0;

		@include bp('md'){
			overflow: hidden;
		}

	    > * {
	      @include animate-element('text', '.tabs-list');
	      @include animate-delay(50);
	    }

		li {
			padding-bottom: 20px;
			transition: all 250ms;
			transition-delay: inherit !important;

			.tabs-list__link {
        border-width: 1px;
        border-top-width: 4px;
        border-style: solid;
        border-color: transparent;
        border-bottom-color: $c-black;
        color: $c-black;
				display: block;
        line-height: 28px;
				padding: 14px 15px;
				text-decoration: none;

        &:not(.active):hover {
          background-color: $c-gray-soft;
          border-color: $c-gray-soft;
          border-bottom-color: $c-black;
        }

				&.active {
          border-left-color: $c-black !important;
          border-right-color: $c-black !important;
          border-bottom-color: transparent !important;
				}
			}
		}
	}

	&-content {
		display: none;

	    > * {
	      @include animate-element('text', '.tabs-content');
	      @include animate-delay(50);
	    }

		&.active {
			display: block;
		}
	}

	.layout-container {
	    max-width: 960px;
	    margin-left: 30px;
	    margin-right: 30px;

	    @include bp('md'){
		    margin-left: auto;
		    margin-right: auto;
		    width: calc(100% - (2 * 115px));
		}
	}
}
