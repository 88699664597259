.what-we-do {
  padding: 94px 0;

  @include bp(lg) {
    margin: -127px 0 0;
    padding: 225px 0 150px;
  }
}

.what-we-do-container {
  background: $c-gray-soft;
}

.what-we-do--content {
  @include container-inset;
}

.what-we-do .what-we-do--intro {
  @include intro-txt;
}

.what-we-do .what-we-do--intro > * {
  @include animate-element('text', '.what-we-do--intro');
  @include animate-delay(2);
}

.what-we-do .what-we-do--intro-txt > * {
  @include animate-element('text', '.what-we-do--intro-txt');
  @include animate-delay(2, 1);
}

.what-we-do--intro-txt p {
  font-size: 16px;
  line-height: 28px;
  @include bp(md) {
    max-width: 292px;
    font-size: 13.5px;
    line-height: 24px;
  }
}

.what-we-do {

  h3,
  .heading3 {
    margin-bottom: 50px;

    @include bp(md) {
      max-width: 564px;
    }

    @include bp(lg) {
      margin-bottom: 0;
    }
  }
}

.what-we-do .what-we-do--stats {
  margin-top: 100px;
  color: $c-blue-primary;
  transition-delay: 0.35s;

  @include bp(lg) {
    display: flex;
    justify-content: space-between;
    margin-top: 125px;
  }
}

.what-we-do .what-we-do--stats > * {
  @include animate-element('text', '.what-we-do--stats');
  @include animate-delay(2, 3);

  @include bp(lg) {
    width: calc(50% - 100px);
  }
}

.what-we-do .what-we-do--stats .stat {
  margin: 0 0 56px;

  &:last-child {
    margin: 0;
  }

  @include bp(lg) {
    margin: 0;
  }
}

.what-we-do .what-we-do--stats .stat .stat__headline {
  display: flex;
  align-items: center;
  font-family: $font-family-serif;
  font-weight: $font-weight-light;
  font-size: 150px;
  letter-spacing: -10px;
  line-height: 1;

  .stat__symbol {
    font-size: 50px;
    line-height: 0.8;
    margin: 16px 0 0 8px;
  }

  @include bp(md) {
    font-size: 200px;

    .stat__symbol {
      font-size: 80px;
    }
  }
}

.what-we-do .what-we-do--stats .stat .stat__body {
  @include bp(md) {
    max-width: 368px;
  }
}
