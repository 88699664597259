.expertise-header {
  background-color: $c-black;
  padding: 48px 30px;

  @include bp(xl) {
    padding: 48px 0;
  }

  &.teal {
    background-color: $c-green-primary;
  }

  &.burgundy {
    background-color: $c-burgundy-primary;
  }

  &.blue {
    background-color: $c-blue-primary;
  }

  &.purple {
    background-color: $c-purple-primary;
  }

  &.gray {
    background-color: $c-gray-soft;
  }

  &.white {
    background-color: $c-white;
  }

  &.gray,
  &.white {

    h2,
    p,
    .stat-columns__item a,
    .stat-columns__item--value {
      color: $c-black;
    }

    .link--button-white {
      background-image: linear-gradient($c-black, $c-black);

      &:after {
        filter: brightness(.5) sepia(1) hue-rotate(-70deg) saturate(5);
      }
    }
  }

  &.image {
    background-color: $c-black;
    position: relative;

    .image-container {
      box-sizing: border-box;
      container-type: size;
      height: 100%;
      width: 100%;
      min-height: 320px;
      min-width: 320px;
      max-height: 100%;
      max-width: 100%;
      opacity: 0.5;
      overflow: hidden;
      position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
      resize: both;

      > img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .layout-container {
      position: relative;
    }
  }

  .layout-container {
    max-width: 956px;
    z-index: 99;

    > p {
      max-width: 858px;
    }
  }

  h2 {
    color: $c-white;
    font-family: $font-family-serif;
    font-weight: $font-weight-light;
    font-size: 36px;
    line-height: 1.33;
  }

  p {
    color: $c-white;
  }

  .stat-columns {
    display: flex;
    flex-wrap: wrap;

    @include bp(md) {

      &[data-count="3"],
      &[data-count="5"] {
        .stat-columns__item {
          margin-right: 5%;
          max-width: 30%;

          &:nth-child(3n) {
            margin-right: 0;
          }
        }
      }

      &[data-count="4"] {

        .stat-columns__item {
          margin-right: 10%;

          &:nth-child(2n) {
            margin-right: 0;
          }
        }

        &.small {

          .stat-columns__item {
            margin-right: calc(16% / 3);
            max-width: 21%;

            &:nth-child(4n) {
              margin-right: 0;
            }

            &--value {
              font-size: 56px;
            }
          }
        }
      }
    }

    &__item {
      margin: 30px 0;

      @include bp(md) {
        margin: 30px 10% 30px 0;
        max-width: 45%;
      }

      &:last-child {
        margin-right: 0;
      }

      &--value {
        color: $c-white;
        font-family: $font-family-serif;
        font-weight: $font-weight-light;
        font-size: 132px;
      }

      a {
        color: $c-white;
      }
    }
  }
}
