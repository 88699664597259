.entry-points {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 60px 0 40px;

	&.blue {
		background-color: $c-blue-primary;
	}

	&.purple {
		background-color: $c-purple-primary;
	}

	&.maroon {
		background-color: $c-burgundy-primary;
	}

	&.teal {
		background-color: $c-green-primary;
	}

	&.blue,
	&.purple,
	&.maroon,
	&.teal {

		h2 {
			color: $c-white;

			+p {
				color: $c-white;
			}
		}
	}

	.layout-container {
		margin-left: auto;
		margin-right: auto;
		width: 90%;

		@include bp('lg') {
			max-width: 960px;
			width: calc(100% - (2 * 115px));
		}

		h2 {

			&.blue {
				color: $c-blue-primary;
			}

			&.purple {
				color: $c-purple-primary;
			}

			&.maroon {
				color: $c-burgundy-primary;
			}

			&.teal {
				color: $c-green-primary;
			}
		}
	}

	&__row {
		display: flex;
		flex-wrap: wrap;

		@include bp('md') {
			flex-wrap: nowrap;
		}

		.scEmptyPlaceholder {
			width: 100%;
		}

		> a {

      &:not(.has-button) {

        .entry-point__item--body {
          background-image: url('/images/arrow.svg');
          background-position: 24px calc(100% - 24px);
          background-repeat: no-repeat;
          padding-bottom: 48px;
        }
      }

      &.has-button {

        .entry-point__item--body {
          padding-bottom: 48px;
        }
      }

			&:hover {

				.entry-point__item--img {

					img {
						transform: scale(110%) translate(-45%, -45%);
					}
				}

				.entry-point__item--body {

					h3,
					p {
						background-position: 0 100%;
						background-size: 100% 1px;
					}
				}
			}

			&.blue,
			&.purple,
			&.maroon,
			&.teal {
				.entry-point__item--body {
					background-image: url('/images/arrow-white.svg');
				}
			}
		}

		.entry-point__item {
			background-color: $c-gray-soft;
			color: #000000;
			display: flex;
			flex-direction: column;
			flex: 1 1 0px;
			margin-bottom: 20px;
			text-decoration: none;

			&:not(:first-child) {
				@include bp(md) {
					margin-left: 20px;
				}
			}

			&.blue,
			&.purple,
			&.maroon,
			&.teal {

				color: #ffffff;

				.entry-point__item--body {

					h3 {
						background-image: linear-gradient($c-white, $c-white);
					}
				}
			}

			&.blue {
				background-color: $c-blue-primary;
			}

			&.purple {
				background-color: $c-purple-primary;
			}

			&.maroon {
				background-color: $c-burgundy-primary;
			}

			&.teal {
				background-color: $c-green-primary;
			}

			&--img {
				aspect-ratio: 112/75;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				overflow: hidden;
				position: relative;

				img {
					max-width: 102%;
					object-fit: cover;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: scale(100%) translate(-50%, -50%);
					transition: all .35s;
				}
			}

			&--body {
				box-sizing: border-box;
				padding: 24px;

				h3 {
					background-image: linear-gradient($c-black, $c-black);
					background-size: 0 1px;
					background-position: 100% 100%;
					background-repeat: no-repeat;
					display: inline;
					font-size: 24px;
					font-weight: 400;
					line-height: 1.67;
					transition: background-size .35s;
				}

				p {
					margin: 15px 0 0;
				}

        .link-button {
          background-color: $c-white;
          color: $c-black;
          display: inline-block;
          font-family: $font-family-sans;
          font-size: 11px;
          font-weight: $font-weight-bold;
          line-height: 20px;
          margin-top: 16px;
          padding: 10px 40px;
          text-decoration: none;
          text-transform: uppercase;
        }
			}

			&.img-quarter {
				margin-bottom: 40px;

				@include bp(md) {
					flex-direction: row;
				}

				.entry-point__item--img {
					aspect-ratio: 112/75;
					overflow: hidden;
					position: relative;

					@include bp(md) {
						flex: 0 0 25%;
					}
				}
			}

			&.img-third {
				margin-bottom: 40px;

				@include bp(md) {
					flex-direction: row;
				}

				.entry-point__item--img {
					aspect-ratio: 112/75;
					flex: 0 0 33.3%;
					overflow: hidden;
					position: relative;

					@include bp(md) {
						flex: 0 0 33.3%;
					}
				}
			}

			&.img-half {
				margin-bottom: 40px;

				@include bp(md) {
					flex-direction: row;
				}

				.entry-point__item--img {
					aspect-ratio: 112/75;
					overflow: hidden;
					position: relative;

					@include bp(md) {
						flex: 0 0 50%;
					}
				}
			}

			&.img-right {

				@include bp(md) {
					.entry-point__item--img {
						margin-left: auto;
						order: 2;
					}

					.entry-points__row--body {
						order: 1;
					}
				}
			}

			&.icon {
				.entry-point__item--img {
					background-color: $c-gray-light;
					background-size: 50%;
				}
			}
		}
	}

	&.full-overlay {
		padding: 40px 0;
		position: relative;

		&:before {
			background-color: rgba(0, 0, 0, 0.7);
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;
		}

		.layout-container {
			align-items: center;
			display: flex;
			min-height: 270px;
		}

		&.image-only {

			.layout-container {
				min-height: 420px;
			}
		}

		&.main-column {

			&:before {
				background-color: transparent;
				position: initial;
			}

			.layout-container {
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;

				&:before {
					background-color: rgba(0, 0, 0, 0.7);
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					top: 0;
					z-index: -1;
				}
			}
		}

		&.multi-column {

			&:before {
				background-color: transparent;
				position: initial;
			}

			.layout-container {

				.entry-points__row {

					.entry-point__item {
						position: relative;

						&:before {
							background-color: rgba(0, 0, 0, 0.7);
							content: '';
							position: absolute;
							bottom: 0;
							left: 0;
							right: 0;
							top: 0;
						}

						.entry-point__item--body {
							position: relative;
							z-index: 1;
						}
					}
				}
			}
		}

		.layout-container {
			position: relative;
			z-index: 1;

			.entry-points__row {

				.entry-point__item {
					background-color: transparent;
					background-image: none;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;

					&--body {

						h3,
						p {
							color: $c-white;
						}

						h3 {
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							overflow: hidden;
						}
					}
				}
			}
		}
	}
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {

    .entry-points__row .entry-point__item--body .link-button {
      background-color: $color;
      color: $c-white;
    }
  }
}
