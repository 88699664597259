.filter {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;

  $this: &;

  p {
    font-family: $font-family-serif;
    font-size: 18px;
    line-height: 26px;
    margin: 0 20px 0 0;
  }

  &__button {
    @extend %button-txt;
    cursor: pointer;
    position: relative;
    transition: all 0.5s;

    &::after {
      background: $c-purple-primary;
      bottom: calc(0% - 31px);
      content: '';
      height: 0;
      left: 0;
      position: absolute;
      transition: height 0.245s;
      right: 0;
    }

    svg {
      margin: 0 0 0 5px;
      transition: all 0.35s;
      vertical-align: middle;
    }

    &--active {
      color: $c-purple-primary;

      &::after {
        height: 4px;
      }

      svg {
        transform: rotate(180deg);

        path {
          fill: $c-purple-primary;
        }
      }
    }
  }

  &__container {
    left: 0;
    max-height: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    transition: max-height 0.7s;
    top: calc(100% + 28px);
    z-index: 10;

    @include bp(lg) {
      top: calc(50% + 41px);
    }

    &--show {
      max-height: 500px;
    }

    &-inner {
      background: $c-white;
      border-top: 0.5px solid $c-gray-light;
    }
  }

  &__content {
    opacity: 0;
    padding: 55px 0 25px;
    transition: opacity 0.7s;

    @include bp(lg) {
      padding: 55px 10% 25px;
    }

    &--show {
      opacity: 1;
    }
  }

  &__items {
    display: flex;
    flex-flow: row wrap;

    &:focus,
    &:hover {
      color: $c-gray-light-fade;

      span {
        color: $c-gray-light-fade;
      }

      .filter__item {
        &:focus,
        &:hover {
          color: $c-black;

          span {
            color: $c-purple-primary;
          }
        }
      }
    }
  }

  &__item {
    align-items: center;
    display: flex;
    cursor: pointer;
    flex-flow: row nowrap;
    flex: 0 1 50%;
    margin: 0 0 30px;
    position: relative;
    transition: all 0.35s;
    width: 50%;

    @include bp(lg) {
      padding-right: 30px;
      flex: 0 1 33.33%;
      width: 33.33%;
    }

    svg {
      fill: $c-gray-dark;
      height: 10px;
      width: 10px;
      margin: 0 0 0 -20px;
      opacity: 0;
      position: absolute;
      transition: all 0.35s;
    }

    p {
      font-family: $font-family-sans;
      margin: 0;
      transition: all 0.35s;
    }

    span {
      @extend %copy--sm;
      color: $c-purple-primary;
      margin: -15px 0 0 4px;
    }

    &--selected {
      padding: 0 0 0 30px;

      @include bp(lg) {
        margin-left: -30px;
        margin-right: 30px;
      }

      svg {
        opacity: 1;
        stroke: $c-purple-primary;
      }

      p {
        color: $c-purple-primary;
      }
    }
  }

  &__date-fields {
    display: flex;
    flex-flow: column nowrap;

    @include bp(lg) {
      align-items: center;
      flex-flow: row nowrap;
      margin: 0 0 80px;
    }

    h3,
    .heading3 {
      color: $c-gray-light-fade !important;
      font-size: 24px;
      line-height: 40px;
      margin: 15px 0 0;

      @include bp(lg) {
        margin: 0 30px;
      }
    }
  }

  &__text-link-nav-item {
    transition: all 0.35s;
    &.hover {
      color: $c-purple-light;
    }
  }

  &__date-field-label {
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    path {
      fill: $c-white;
    }
  }

  &__date-input {
    appearance: none;
    background: $c-black;
    border-bottom: 1px solid $c-white;
    color: $c-white;
    font-size: 24px;
    line-height: 40px;
    outline: none;
    padding: 8px 30px;
    transition: all 0.3s;

    &:focus {
      border-bottom: 4px solid $c-purple-light;
    }

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      height: auto;
      color: transparent;
      background: transparent;
    }
  }

  &__date-field {
    position: relative;
  }

  &#{$this}--hidden {
    display: none;
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .filter {
      &__button {
        &::after {
          background: $color;
        }

        &--active {
          color: $color;

          svg {
            path {
              fill: $color;
            }
          }
        }
      }

      &__items {
        &:focus,
        &:hover {
          .filter__item {
            &:focus,
            &:hover {
              span {
                color: $color;
              }
            }
          }
        }
      }

      &__item {
        span {
          @extend %copy--sm;
          color: $color;
          margin: -15px 0 0 4px;
        }

        &--selected {
          svg {
            stroke: $color;
          }

          p {
            color: $color;
          }
        }
      }
    }
  }
}

@each $section, $color in $sectionLightColors {
  .page-container[data-section='#{$section}'] {
    .filter {
      &__date-fields {
        input[type='date'] {
          &:focus {
            border-color: $color;
          }
        }
      }
    }
  }
}
