.header-carousel--wrapper {
  margin-top: -177px;
  position: relative;

  .carousel--controls-wrapper {
    height: 0;

    > * {
      @include animate-element('text', '.carousel--controls-wrapper');
      @include animate-delay(3, 1);
    }
  }

  .carousel--controls {
    display: none;

    @include bp(lg) {
      bottom: 275px;
      display: flex;
      justify-content: flex-end;
      position: relative;
      z-index: 3;
    }

    .glide__arrow {
      &::after {
        background-color: $c-white;
      }
    }

    &-wrapper {
      @include container-inset;
    }
  }
}

.header-carousel {
  align-items: center;
  display: flex;
  min-height: 761px;
  padding-bottom: 100px;
  padding-top: 161px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  &::before {
    background: $c-black-40;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &.no-overlay {
    &::before {
      display: none;
    }
  }

  @include bp(lg) {
    min-height: 661px;
    max-height: 661px;
    padding-bottom: 0;
  }
}

.header-carousel--content {
  @include container-inset;
  color: $c-white;
  z-index: 9;

  > * {
    @include animate-element('text', '.header-carousel--content');
    @include animate-delay(3);
  }

  @include bp(md) {
    display: flex;
    flex-wrap: wrap;
  }

  @include bp(lg) {
    display: flex;
    flex-wrap: wrap;
  }
}

.header-carousel--txt {
  font-family: Spezia;
  min-height: 260px;
  width: 100%;

  @include bp(md) {
    width: 65%;
  }

  @include bp(lg) {
    width: 85%;
  }

  @include bp(xl) {
    width: 70%;
  }

  small a {
    text-decoration: none;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $c-white;
  }

  h1,
  h2 {
    margin-top: 32px;

    @include bp(md) {
      margin-top: 16px;
    }

    a {
      @include animate-underline($c-white);
      text-decoration: none;
      font-family: Teodor;

      font-weight: 300;
      font-size: 34px;
      line-height: 42px;
      font-style: normal;
      font-weight: normal;
      color: $c-white;

      @include bp(md) {
        font-size: 48px;
        line-height: 52px;
      }

      @include bp(lg) {
        font-size: 60px;
        line-height: 62px;
      }

      @media only screen and (min-width: 1024px) and (orientation: landscape) {
        font-size: 48px;
        line-height: 52px;
      }
    }
  }

  h3 {
    font-family: Spezia;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $c-white;

    .map-marker {
      margin-right: 8px;
    }
  }
}

.header-carousel-date {
  display: none;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;

  @include bp(lg) {
    display: flex;
    margin-top: 50px;
  }

  &.mobile {
    display: flex;
    margin-bottom: 24px;
    margin-top: 34px;

    @include bp(lg) {
      display: none;
    }

    .day {
      margin-right: 16px;
    }

    .month-year {
      text-align: left;
    }
  }

  .month-year {
    font-family: Teodor;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    text-align: right;
    color: $c-white;
  }

  .day {
    font-family: Teodor;
    font-style: normal;
    font-weight: 300;
    font-size: 90px;
    line-height: 40px;
    letter-spacing: -2px;
    color: $c-white;
  }
}

.header-carousel--carousel {
  .glide__bullets {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 65px;
    z-index: 1;
  }

  .glide__bullets > .glide__bullet--active {
    border-color: $c-white;
    background-color: transparent;
  }

  .glide__bullets > button {
    background: $c-white;
  }
}

// For the cloned navbar
.navigation-blend-header-carousel {
  z-index: 2;
  position: absolute;
  color: $c-white;
  overflow-x: hidden;
  left: 0;
  right: 0;

  .navigation {
    width: calc(100vw - 60px);

    @include bp(lg) {
      width: calc(100vw - 72px);
    }

    border-bottom-color: $c-white;
    fill: $c-white;

    svg * {
      filter: brightness(0) invert(1);
    }

    > .menu--trigger span {
      background: $c-white;
    }

    nav a {
      color: $c-white;

      &.expanded::after {
        filter: brightness(0) invert(1);
      }
    }
  }
}
