.search-filter {
  &__tags {
    display: block;
    margin: 45px 0 31px;
    position: relative;
    margin-bottom: 60px;

    @include bp(md) {
      display: flex;
      flex-wrap: wrap;
      margin: 50px 0 64px;
      padding-right: 175px;
    }
  }

  &__tag {
    @extend .btn-tag;
    @extend .btn-tag--black;

    border: 0;
    font-size: 16px;
    display: inline-block;
    line-height: 28px;
    margin-right: 13px;
    margin-bottom: 13px;
    padding: 5px 10px;
    background: #efefef;

    a {
      text-decoration: none;
    }

    img {
      filter: invert(1);
      height: 10px;
      width: 10px;
      margin-right: 5px;
    }

    &:focus,
    &:hover {
      img {
        filter: invert(0);
      }
    }
  }

  &__clear-tags {
    @include bp(md) {
      margin-left: auto;
      position: absolute;
      top: 0;
      right: 0;
    }

    &::after {
      display: none;
    }
  }
}

#cludo-search-results {
  .facet-list-chosen svg line {
    stroke: $c-blue-primary !important;
  }

  .search-filter__clear-tags {
    display: block !important;
    width: 125px;

    @include bp(md) {
      margin-top: 0 !important;
      width: auto;
    }
  }

  .cludo-facet__value-list {
    padding-left: 0 !important;
  }

  .cludo-facet__value-list-item {
    justify-content: flex-start !important;

    a {
      color: inherit;
    }
  }

  .cludo-search-results__search-result-count {
    @extend %p;
  }

  .cludo-search-results__did-you-mean,
  .cludo-no-search-term {
    display: none !important; // hiding unless styles are needed - tdeluca - 091521
  }
}
