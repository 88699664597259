// Typography
// sass-lint:disable indentation
@font-face {
  font-family: 'Teodor';
  src: url('../fonts/teodor/Teodor-Light.otf') format('opentype');
  font-weight: $font-weight-light;
  font-style: normal;
}

@font-face {
  font-family: 'Teodor';
  src: url('../fonts/teodor/Teodor-Regular.otf') format('opentype');
  font-weight: $font-weight-regular;
  font-style: normal;
}

@font-face {
  font-family: 'Spezia';
  src: url('../fonts/spezia/Spezia-Regular.otf') format('opentype');
  font-weight: $font-weight-regular;
  font-style: normal;
}

@font-face {
  font-family: 'Spezia';
  src: url('../fonts/spezia/Spezia-SemiBold.otf') format('opentype');
  font-weight: $font-weight-medium;
  font-style: normal;
}

@font-face {
  font-family: 'Spezia';
  src: url('../fonts/spezia/Spezia-Bold.otf') format('opentype');
  font-weight: $font-weight-bold;
  font-style: normal;
}
// sass-lint:enable indentation

html {
  font-size: 16px;
}

body {
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading1,
.heading2,
.heading3,
.heading4,
.heading5,
.heading6 {
  margin-top: 0;
}

h1,
.heading1,
%h1 {
  font-size: 34px;
  line-height: 42px;
  font-family: $font-family-serif;
  font-weight: $font-weight-light;

  @include bp(md) {
    font-size: 60px;
    line-height: 62px;
  }

  &.large {
    @include bp(lg) {
      font-size: 100px;
      line-height: 85px;
    }
  }
}

h2,
.heading2,
%h2 {
  font-family: $font-family-serif;
  font-weight: $font-weight-light;
  font-size: 28px;
  line-height: 36px;

  @include bp(md) {
    font-size: 36px;
    line-height: 48px;
  }
}

h3,
.heading3,
%h3 {
  font-family: $font-family-sans;
  font-weight: $font-weight-regular;
  font-size: 20px;
  line-height: 32px;

  @include bp(lg) {
    font-size: 24px;
    line-height: 40px;
  }
}

h4,
.heading4,
%h4 {
  font-family: $font-family-sans;
  font-weight: $font-weight-bold;
  font-size: 18px;
  line-height: 30px;
}

.heading3 {
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.heading4 {
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
}

h5,
.heading5,
%h5 {
}

h6,
.heading6,
%h6 {
}

p,
%p {
  margin: 0 0 20px;
  font-family: $font-family-sans;
  font-size: 16px;
  line-height: 28px;
}

ul,
ol {
  .rich-text-accordion .rta-rich-text &,
  .rich-text-basic__text &,
  .rich-text-event-details__text &,
  .rich-text-related-article__text &,
  .split-text-image__text & {
    font-family: $font-family-sans;
    font-size: 16px;
    line-height: 28px;
    padding-left: 26px;
  }
}

ul {
  .rich-text-accordion .rta-rich-text & {
    list-style-type: disc;
  }
}

p.copy--sm,
%copy--sm {
  @include bp(lg) {
    font-family: $font-family-sans;
    font-size: 13.5px;
    line-height: 24px;
  }
}

%button-txt {
  font-family: $font-family-sans;
  font-weight: $font-weight-medium;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

blockquote {
}

cite {
}

code {
}

dl {
}

dt {
}

dd {
}

b,
strong {
}

i,
em {
  font-style: italic;
}

u {
  text-decoration: underline;
}

sup {
  position: relative;
  font-size: 0.8em;
  top: -0.2em;
}

sub {
  position: relative;
  font-size: 0.8em;
  top: 0.2em;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus {
}

a:focus {
}
