.single-consultant-profile {
  > * {
    @include animate-element('text', '.single-consultant-profile');
    @include animate-delay(3);
  }

  &-right {
    display: flex;
    flex-direction: column;
  }
}

.single-consultant-profile--content {
  margin: 0 auto;
  max-width: 1128px;
}

.single-consultant-profile .single-consultant-profile--image {
  margin-top: -161px;
  height: 512px;
  width: 100%;

  @include bp(lg) {
    position: fixed;
    margin-bottom: 0;
    margin-top: 0;
    top: 0;
    width: 392px;
    height: 100%;
    max-height: 784px;
    overflow: hidden;
    z-index: 0;
  }
}

.single-consultant-profile-content {

  &.bio-section {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1020px;

    @include bp(lg){
      flex-direction: row;
    }
  }

  &--bio {
    flex: 1 1 auto;
    padding: 0 30px;

    .bio {

    }

    .languages {

    }

    .certifications {

    }

    &.rich-text-accordion {

      #rta-btn-more,
      #rta-btn-less {
        border-color: $c-burgundy-primary;
        color: $c-burgundy-primary;
        display: block;        
        margin: 0 auto;
      }

      @include bp(lg){

        #rta-more {
          display: block;
          opacity: 1;
          visibility: visible;
        }

        input[type="checkbox"],
        #rta-btn-more,
        #rta-btn-less {
          display: none;
        }
      }
    }
  }

  &--expertise {
    flex: 0 0 392px;
    margin-top: 0;
    padding: 0 30px;

    h2 {

    }

    .rta-tags {
      flex-direction: column;

      .tag {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
}

.single-consultant-profile--image-background {
  width: 100%;
  height: 100%;
  padding: 40px;
  display: flex;
  position: relative;

  .image-background {
    background-position: center;
    background-size: cover;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;

    &--tablet-portrait,
    &--tablet-landscape,
    &--desktop {
      display: none;
    }

    @include bp(md) {
      &--mobile,
      &--tablet-landscape,
      &--desktop {
        display: none;
      }

      &--tablet-portrait {
        display: block;
      }
    }

    @include bp(xl) {
      &--mobile,
      &--tablet-portrait,
      &--desktop {
        display: none;
      }

      &--tablet-landscape {
        display: block;
      }
    }

    @include bp(xl-large) {
      &--mobile,
      &--tablet-portrait,
      &--tablet-landscape {
        display: none;
      }

      &--desktop {
        display: block;
      }
    }
  }

  @include bp(lg) {
    &::after {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.51) 67.86%
      );
      bottom: 0;
      content: '';
      display: block;
      height: 126px;
      left: 0;
      opacity: 0;
      position: absolute;
      transition: opacity 0.35s;
      right: 0;
      z-index: 0;
    }
  }

  a {
    display: none;
    margin-top: auto;
    margin-right: 33px;
    position: relative;
    z-index: 1;

    @include bp(lg) {
      display: inline;
    }

    img {
      width: 21px;
      filter: brightness(0) invert(1);
    }
  }

  &:focus,
  &:hover {
    @include bp(lg) {
      &::after {
        opacity: 1;
      }
    }
  }
}

.single-consultant-profile .single-consultant-profile--txt {
  padding: 0 30px;

  @include bp(lg) {
    padding-left: 454px;
    padding-right: 135px;
  }

  .single-consultant-profile--name {
    font-size: 60px;
    line-height: 62px;
    color: $c-purple-primary;
    margin-top: 34px;

    @include bp(lg) {
      margin-top: unset;
    }

    h1,
    h2 {
      line-height: 1;
      margin-bottom: 16px;
    }
  }
}

.single-consultant-profile--tags {
  display: flex;
  margin-right: 30px;
  margin-bottom: 34px;
  padding-left: 20px;
  position: relative;

  img {
    transform: scale(1.5);
    filter: invert(1);
    left: 0;
    position: absolute;
    top: 10px;
  }

  @include bp(lg) {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }

  .location {
    font-family: $font-family-sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    text-transform: uppercase;

    a {
      color: $c-black;
      display: inline-block;
      text-decoration: none;

      &:visited {
        color: $c-black;
      }

      span {
        display: block;
      }
    }
  }
}

.single-consultant-profile--social-network-links {
  margin-bottom: 31px;

  @include bp(lg) {
    display: none;
  }

  > a {
    margin-right: 33px;
    text-decoration: none;

    img {
      width: 21px;
      height: 21px;
    }
  }
}

.single-consultant-profile--get-in-touch {
  @extend .btn--black;

  color: white;
  display: inline-block;
  margin-bottom: 70px;
  margin-top: 16px;

  @include bp(lg) {
    margin-top: 0;
  }
}

.single-consultant-profile--social-share {
  position: absolute;
  // display: flex;
  display: none; // temporarily hide until integration - tdeluca - 20210825
  right: 0;
  margin: 50px 0;
  top: 0;
  width: 56px;

  @include bp(sm) {
    width: 97px;
  }

  @include bp(lg) {
    bottom: 0;
    height: calc(100vh - 194px);
    margin: 104px 0px;
    width: 212px;
  }

  > div {
    margin: auto;
  }

  a {
    display: flex;
    width: 19px;
    height: 24px;
    margin: 44px 0px;

    @include bp(lg) {
      border: 0.5px solid $c-gray-lighter;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    img {
      margin: auto;
      filter: brightness(0) invert(1);

      @include bp(lg) {
        filter: unset;
      }
    }
  }
}

// For the cloned navbar
.navigation-blend-single-consultant-profile {
  z-index: 2;
  position: absolute;
  color: $c-white;
  overflow-x: hidden;
  left: 0;
  right: 0;

  @include bp(lg) {
    width: 392px;
  }

  .navigation {
    width: calc(100vw - 60px);

    @include bp(lg) {
      width: calc(100vw - 72px);
    }

    border-bottom-color: white;
    fill: white;

    svg * {
      filter: brightness(0) invert(1);
    }

    > .menu--trigger span {
      background: white;
    }

    nav a {
      color: white;

      &.expanded::after {
        filter: brightness(0) invert(1);
      }
    }
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .single-consultant-profile--name {
      color: $color;
    }
  }
}
