/*
  Disable animations when prefers-reduced-motion enabled
  https://web.dev/prefers-reduced-motion/#(bonus)-forcing-reduced-motion-on-all-websites
*/
@media (prefers-reduced-motion: reduce) {
  *, ::before, ::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}
