@use "sass:map";

.latest-insights-insights {
  &-container {
    background: $c-white;

    .layout-container {
      @include container-inset;
    }
  }

  &__filters-container {
    > * {
      @include animate-element(
      'text',
        '.latest-insights-insights__filters-container'
      );
      @include animate-delay(2);
    }
  }

  &__filters {
    background: $c-black;
    color: $c-white;
    //margin-top: -16px;
    padding: 30px 0;
    position: relative;
    z-index: 3;

    @include bp(lg) {
      background: linear-gradient(to right, transparent 50%, $c-black 50%);
      padding: 0;

      .layout-container {
        background: $c-black;
      }
    }
  }

  &__mobile-nav {
    background: $c-black;
    bottom: 0;
    color: $c-white;
    position: fixed;
    right: 0;
    top: 0;
    width: 0;
    z-index: 10;

    @include bp(lg) {
      display: none;
    }

    &__inner {
      display: flex;
      flex-flow: column nowrap;
      height: 100vh;
      position: relative;
    }

    &-close {
      @extend %button-txt;

      align-items: center;
      color: $c-white;
      display: flex;
      flex-flow: row nowrap;
      opacity: 0;
      right: 30px;
      position: absolute;
      text-decoration: none;
      top: 30px;
      visibility: hidden;
      z-index: 10;

      img {
        margin: 0 0 0 10px;
      }
    }

    *::-webkit-scrollbar {
      width: 1px;
    }

    /* Track */
    *::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    *::-webkit-scrollbar-thumb {
      background: $c-gray-light;
      border: 0px;
    }

    &__items {
      flex: 1;
      margin: 75px 30px 0;
      overflow: auto;
      scrollbar-color: $c-gray-light transparent;
      scrollbar-width: thin;
    }

    &__item-title {
      align-items: center;
      cursor: pointer;
      display: flex;
      font-family: $font-family-serif;
      font-weight: $font-weight-light;
      font-size: 30px;
      line-height: 48px;
      padding: 10px 0;
      position: relative;
      transition: all 0.2s;

      .latest-insights-insights__mobile-nav__item.active & {
        color: $c-purple-light;
      }

      svg {
        height: 12px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.2s;
        width: 18px;

        .latest-insights-insights__mobile-nav__item.active & {
          transform: translateY(-50%) rotate(180deg);
        }

        path {
          fill: $c-white;
          transition: all 0.2s;

          .latest-insights-insights__mobile-nav__item.active & {
            fill: $c-purple-light;
          }
        }
      }
    }

    &__item-filters {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s;

      .latest-insights-insights__mobile-nav__item.active & {
        max-height: 500px;
      }
    }

    &__bottom {
      border-top: 1px solid $c-gray-light;
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin: 0 30px;
      padding: 30px 0;
    }

    &__clear-filters {
      &::after {
        display: none;
      }
    }
  }

  &__mobile-nav-link {
    @include bp(lg) {
      display: none;
    }

    img {
      margin: 0 8px 0 0;
    }

    &::after {
      display: none;
    }
  }

  &__filters-nav {
    display: none;

    @include bp(lg) {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
    }

    .filter__nav-item {
      @extend %button-txt;

      cursor: pointer;
      margin: 0 0 0 35px;
      padding: 30px 0;
      position: relative;
      transition: all 0.35s;

      &::after {
        background: $c-white;
        bottom: 0;
        content: '';
        height: 0;
        left: 0;
        position: absolute;
        transition: height 0.35s;
        right: 0;
      }

      svg {
        margin: 0 0 0 5px;
        transition: all 0.35s;
        vertical-align: middle;

        path {
          fill: $c-white;
        }
      }

      &.hover {
        color: $c-purple-light;

        &::after {
          height: 4px;
        }

        svg {
          transform: rotate(180deg);

          path {
            fill: $c-purple-light;
          }
        }
      }

      [role='listbox'] {
        display: inline-block;
        color: inherit;
      }
    }

    .filter__image-link-nav-item {
      min-width: 30px;
      text-align: center;

      img[src*='.svg'] {
        max-height: 20px;
        filter: $f-white;
      }
    }
  }

  &__filters-dropdown {
    background: linear-gradient(to right, transparent 50%, $c-black 50%);
    left: 0;
    max-height: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    transition: max-height 0.7s;
    top: 100%;

    &.hover {
      max-height: 500px;
    }

    &-inner {
      background: $c-black;
      border-top: 0.5px solid $c-gray-light;
      margin: 0 0 0 100px;
    }
  }

  &__content {
    opacity: 0;
    padding: 55px 0 25px;
    transition: opacity 0.7s;

    @include bp(lg) {
      padding: 55px 10% 25px;
    }

    &.show {
      opacity: 1;
    }
  }

  &__items {
    display: flex;
    flex-flow: row wrap;

    &:focus,
    &:hover {
      color: $c-gray-lighter-fade;

      span {
        color: $c-purple-light;
      }

      .latest-insights-insights__item {
        &:focus,
        &:hover {
          color: $c-white;

          span {
            color: $c-purple-light;
          }
        }
      }
    }

    &.hover-selected {
      color: $c-gray-lighter-fade;

      span {
        color: $c-gray-lighter-fade;
      }

      .item-selected {
        color: $c-white !important;

        span {
          color: $c-purple-light !important;
        }
      }
    }

    .latest-insights-insights__mobile-nav__item & {
      display: block;

      &:focus,
      &:hover {
        color: $c-white;

        span {
          color: $c-purple-light;
        }

        .latest-insights-insights__item {
          &:focus,
          &:hover {
            color: $c-white;

            span {
              color: $c-purple-light;
            }
          }
        }
      }
    }
  }

  &__item {
    align-items: center;
    display: flex;
    cursor: pointer;
    flex-flow: row nowrap;
    flex: 0 1 50%;
    margin: 0 0 30px;
    position: relative;
    transition: all 0.35s;
    width: 50%;

    @include bp(lg) {
      padding-right: 30px;
      flex: 0 1 33.33%;
      width: 33.33%;
    }

    svg {
      fill: $c-gray-dark;
      height: 10px;
      width: 10px;
      margin: 0 0 0 -20px;
      opacity: 0;
      position: absolute;
      transition: all 0.35s;
    }

    p {
      font-family: $font-family-sans;
      margin: 0;
      transition: all 0.35s;
    }

    span {
      @extend %copy--sm;
      color: $c-purple-light;
      margin: -15px 0 0 4px;
    }

    &--selected {
      padding: 0 0 0 30px;

      @include bp(lg) {
        margin-left: -30px;
        margin-right: 30px;
      }

      svg {
        opacity: 1;

        line {
          stroke: $c-purple-light;
        }
      }

      p {
        color: $c-purple-light;
      }
    }

    .latest-insights-events__mobile-nav__item &,
    .latest-insights-insights__mobile-nav__item &,
    .latest-insights-newsroom__mobile-nav__item & {
      flex: 0 0 100%;
      margin: 30px 0;
      padding: 0;
      width: 100%;

      svg {
        display: none;
        margin: 0 10px 0 0;
        opacity: 1;
        position: static;

        line {
          stroke: $c-purple-light;
        }
      }

      span {
        color: $c-purple-light;
      }

      &--selected {
        svg {
          display: block;
        }

        p {
          color: $c-purple-light;
        }
      }
    }
  }

  &__tags {
    display: block;
    margin: 45px 0 31px;
    position: relative;
    margin-bottom: 60px;

    @include bp(lg) {
      display: flex;
      flex-wrap: wrap;
      margin: 50px 0 64px;
      padding-right: 175px;
    }
  }

  &__tag {
    @extend .btn-tag;
    @extend .btn-tag--black;

    border: 0;
    font-size: 16px;
    line-height: 28px;
    margin-right: 13px;
    margin-bottom: 13px;
    padding: 5px 10px;
    background: #efefef;

    a {
      text-decoration: none;
    }

    img {
      filter: invert(1);
      height: 10px;
      width: 10px;
      margin-right: 5px;
    }

    &:focus,
    &:hover {
      img {
        filter: invert(0);
      }
    }
  }

  &__clear-tags {
    @include bp(lg) {
      display: block;
      margin-left: auto;
      position: absolute;
      top: 0;
      right: 0;
    }

    &::after {
      display: none;
    }
  }

  &__insights {
    > * {
      @include animate-element('text', '.latest-insights-insights__insights');
      @include animate-delay(20, 2);
    }

    &[data-animation='true'] {
      .insight {
        transform: none !important;
        // added !important because animate-element @include overwrites any transform - tdeluca - 07222021
        will-change: auto !important;
        // needed to overwrite animate-element @include rule so filter position is respected - tdeluca - 07222021
      }
    }
  }

  .insight {
    border-bottom: 0.25px solid $c-black;
    margin: 0 0 32px;
    padding: 0 0 16px;

    @include bp(lg) {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      padding: 0 0 32px;
    }

    &:last-child {
      border: 0;
      margin: 0;
      padding: 0;
    }

    &__image {
      margin: 0 0 26px;
      overflow: hidden;
      position: relative;

      @include bp(lg) {
        flex: 0 1 40%;
        height: 221px;
        margin: 0 0 0 42px;
        width: 40%;
      }

      a {
        @extend %button-txt;
        color: $c-black;
        text-decoration: none;

        @include bp(lg) {
          color: $c-white;
        }
      }

      svg {
        fill: $c-black;
        margin: 0 6px 0 0;

        @include bp(lg) {
          fill: $c-white;
        }
      }

      img {
        display: none;
        height: 100%;
        left: 50%;
        object-fit: cover;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.35s;
        width: 100%;

        @include bp(lg) {
          display: block;
        }
      }

      &--inverted {
        svg {
          fill: $c-white;

          @include bp(lg) {
            fill: $c-black;
          }
        }
      }

      &:hover,
      &--hover {
        img {
          transform: translate(-50%, -50%) scale(110%) !important;
          filter: brightness(0.9);
          // added !important because animate-element @include overwrites any transform - tdeluca - 07092021
        }
      }

      .insight__podcast-icon {
        display: none;
        position: absolute;
          left: 15px;
          top: 15px;
        z-index: 9;

        .play {
          align-items: center;
          background-color: $c-white;
          display: flex;
          justify-content: center;
          padding: 18px;

          svg {
            height: 20px;
            margin: 0;
            width: 20px;

            path {
              fill: $c-black;
            }
          }
        }

        .close {
          display: none;
        }
      }
    }

    &__type {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;

      span {
        margin: 0 0 0 7px;
      }

      @include bp(lg) {
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.66) 67.86%
        );
        bottom: 0;
        left: 0;
        min-height: 75px;
        padding: 0 24px;
        position: absolute;
        right: 0;
      }
    }

    &__content {
      @include bp(lg) {
        flex: 1 0 60%;
        order: -1;
        width: 60%;
      }
    }

    &__meta {
      @include bp(lg) {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        margin: 0 0 22px;
      }
    }

    &__authors {
      margin: 0 0 20px;

      @include bp(lg) {
        margin: 0 20px 0 0;
      }
    }

    &__date-read {
      margin: 0 0 20px;

      @include bp(lg) {
        margin: 0;
      }

      p {
        margin: 0 0 3px;

        &.no-bar {
          span {
            &:first-child {
              &::after {
                content: '' !important;
                display: inline-block;
                margin: 0 10px 0 15px;
              }
            }
          }
        }
      }

      span {
        &:first-child {
          &::after {
            content: '|';
            display: inline-block;
            margin: 0 10px 0 15px;
          }
        }
      }
    }

    &__title {
      margin: 0 0 30px;

      a {
        @include animate-underline($c-black);
        color: $c-black;
      }

      h2,
      h3,
      .heading2,
      .heading3 {
        margin: 0;
      }
    }

    &__tags {
      position: relative;

      @include bp(lg) {
        display: flex;
        flex-flow: row nowrap;
        overflow: hidden;

        &::after {
          content: '';
          display: block;
          position: absolute;
          z-index: 1;
          top: 0;
          right: 0;
          width: 100px;
          height: 100%;
          background: linear-gradient(90deg, transparent, $c-white 75%);
        }
      }

      button,
      a {
        @extend .btn-tag;
        @extend .btn-tag--black;
        display: inline-block;
        margin: 0 13px 13px 0;
        width: auto;

        @include bp(lg) {
          margin: 0 13px 0 0;

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    &--featured {
      .insight__image {
        height: 212px;
        max-height: 212px;
        max-width: 315px;
        width: 100%;

        @include bp(md) {
          height: 302px;
          max-height: 302px;
          max-width: 450px;
        }

        @include bp(lg) {
          flex: 0 1 50%;
          height: 302px;
          margin: 0 42px 0 0;
          width: 50%;
        }

        a {
          color: $c-white;
        }

        svg {
          fill: $c-white;
        }

        img {
          display: block;
        }
      }

      .insight__type {
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.66) 67.86%
        );
        bottom: 0;
        left: 0;
        min-height: 75px;
        padding: 0 24px;
        position: absolute;
        right: 0;
      }

      .insight__content {
        @include bp(lg) {
          flex: 1 0 50%;
          order: 0;
          width: 50%;
        }
      }

      .insight__title {

        h3,
        .heading3 {
          @extend h2;
        }

        a {
          @include animate-underline($c-purple-primary);
          color: $c-purple-primary;
        }
      }
    }
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .insight--featured {
      .insight__title {
        a {
          @include animate-underline($color);
          color: $color;
        }
      }
    }
  }
}

@each $section, $color in $sectionLightColors {
  .page-container[data-section='#{$section}'] {
    .latest-insights-insights {
      &__mobile-nav {
        &__item {
          &.active {
            .latest-insights-insights__mobile-nav__item-title {
              color: $color;

              svg {
                path {
                  fill: $color;
                }
              }
            }
          }

          &:focus,
          &:hover {
            span {
              color: $color;
            }
          }
        }
      }

      &__filters-nav {
        .filter__nav-item {
          &.hover {
            color: $color;

            svg {
              path {
                fill: $color;
              }
            }
          }
        }

        .filter__image-link-nav-item {
          &.hover {
            img[src*='.svg'] {
              filter: map.get($colorFilter, $section);
            }
          }
        }
      }

      &__items {
        &:hover {
          .latest-insights-insights__item {
            &:hover {
              span {
                color: $color;
              }
            }
          }
        }
      }

      &__item {
        span {
          color: $color;
        }

        &.active {
          .latest-insights-insights__mobile-nav__item-title {
            color: $color;

            svg {
              path {
                fill: $color;
              }
            }
          }
        }

        &--selected {
          svg {
            line {
              stroke: $color;
            }
          }

          p {
            color: $color;
          }
        }

        &.hover-selected {
          .item-selected {
            span {
              color: $color !important;
            }
          }
        }
      }
    }
  }
}

// @each $section, $color in $colorFilter {
//   .page-container[data-section='#{$section}'] {
//     &__filters-nav {
//       .filter__nav-item {
//         &.hover {
//           img {
//             fill: $color;
//           }
//         }
//       }
//     }
//   }
// }
