.glightbox-container.glightbox-clean {
  .gclose {
    color: $c-white;
    height: auto;
    opacity: 1;
    width: auto;

    .close-inner {
      align-items: center;
      display: flex;
      gap: 10px;

      span {
        @extend %button-txt;
      }
    }
  }

  .gslide-media {
    height: auto !important;

    @include bp(lg) {
      background: $c-white;
      padding: 30px;
    }

    > * {
      @include bp(lg) {
        padding: 30px;
      }
    }
  }

  .gslide-description {
    .gdesc-inner {
      @include bp(lg) {
        padding: 0 110px 30px;
      }
    }
  }

  &.glightbox--brightcove {
    .gclose {
      position: absolute;

      .close-inner {
        padding: 5px;
      }
    }

    .gslide-media {
      position: relative;

      @include bp(lg) {
        padding: 0;
      }

      > * {
        @include bp(lg) {
          padding: 0;
        }
      }
    }
  }
}
