.contact-us--container {
  position: relative;
  background-color: $c-gray-soft;
  padding-top: 110px;
  padding-bottom: 110px;

  .rich-text-basic {
    background: $c-gray-soft !important;
  }

  .navigation-header &,
  .footer-container & {
    background: none;
    overflow: visible;
    padding: 0;

    .mktoForm {
      margin: 0;
      width: auto !important;

      * {
        padding: 0;
      }
    }

    .marketo-controls-container {
      width: 100%;
    }
  }

  .navigation-header & {
    .marketo-form {
      margin-top: -35px;
    }
  }

  @include bp(sm) {
    padding-top: 107px;
    padding-bottom: 135px;
  }

  @include bp(lg) {
    padding-top: 137px;
    padding-bottom: 257px;
  }

  @include bp(xxl) {
    padding: 137px 0 271px 0;
    overflow: hidden;
  }

  .contact-us--wrapper {
    @include container-inset;

    @include bp(lg) {
      max-width: 763px;
    }

    .navigation-header &,
    .footer-container & {
      margin: 0;
      max-width: none;
      width: auto;
    }
  }

  .contact-us--form {
    > * {
      @include animate-element('text', '.contact-us--form');
      @include animate-delay(4);
    }
  }

  .contact-us--confirmation {
    text-align: center;
  }

  .contact-us--header {
    display: flex;

    .title {
      margin: auto;
      max-width: 580px;
      text-align: center;

      @include bp(lg) {
        max-width: 763px;
      }

      .navigation-header &,
      .footer-container & {
        margin: 0;
        max-width: none;
        text-align: left;
      }

      .heading1 {
        color: $c-burgundy-primary;
        font-family: Teodor;
        font-style: normal;
        font-weight: 300;
        line-height: 42px;

        .navigation-header & {
          color: $c-black;
          font-family: $font-family-sans;
          font-size: 16px;
          line-height: 28px;
        }

        .footer-container & {
          @extend h2;

          color: $c-blue-primary;
          margin: 0;
          max-width: none;
        }

        @include bp(lg) {
          font-size: 60px;
          line-height: 62px;
        }
      }

      p {
        color: $c-gray-lighter;
        font-family: $font-family-sans;
        font-weight: 600;
        font-size: 11px;
        letter-spacing: 1px;
        text-transform: uppercase;

        .footer-container & {
          max-width: none;
        }
      }
    }
  }

  .contact-us--fields {
    position: relative;
    margin-top: 12px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    min-height: 60px;
    height: auto;

    > * {
      @include animate-element('text', '.contact-us--fields');
      @include animate-delay(4, 1);
    }

    @include bp(sm) {
      margin-top: 62px;
    }

    .input--animation-container {
      display: block;
      position: relative;
      width: 100%;

      height: 0;
      overflow: hidden;

      &.active {
        height: 70px;
      }
    }

    .input--wrapper {
      &:focus-within label,
      &.has-content label {
        margin-top: -25px;
        font-size: 13.5px;
      }

      label {
        pointer-events: none;
        position: absolute;
        font-size: 24px;
        font-family: Spezia;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 60px;
        letter-spacing: 0px;
        text-align: left;
        transition: margin-top 0.35s ease 0s, font-size 0.35s;
      }

      .input {
        padding-top: 10px;
        border-bottom: 0.25px solid black;
        height: 60px;
        position: relative;
        font-size: 24px;
        line-height: 40px;
        font-family: Spezia;
        font-style: normal;
        font-weight: normal;

        &.text-area {
          height: auto;
          min-height: 60px;

          @include bp(lg) {
            min-height: 120px;
          }
        }

        &::after {
          content: '';
          width: 0;
          height: 3px;
          left: 0;
          background-color: $c-burgundy-primary;
          position: absolute;
          bottom: 0;
          transition: width 0.35s;
        }

        &:focus {
          outline: none;

          &::after {
            width: 100%;
          }
        }
      }
    }
  }

  .stepper {
    display: block;
    margin-top: 24px;
    font-family: Spezia;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    /* or 182% */

    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .chr-next-step {
    display: inline-block;
    position: absolute;
    right: 8px;
    height: 56px;
    padding: 0;
    border: 0px solid transparent;
    margin-bottom: 4px;

    img {
      filter: brightness(0%);
    }
  }

  .submit-btn {
    margin-top: 50px;
    display: none;
    transition-delay: 1.3s;
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .contact-us--header {
      .title {
        .heading1 {
          color: $color;

          .footer-container & {
            color: $color;
          }
        }
      }
    }

    .contact-us--fields {
      .input--wrapper {
        .input {
          &::after {
            background-color: $color;
          }
        }
      }
    }
  }
}
