.case-studies-container {
  .layout-container {
    max-width: 1200px;
  }
}

.case-studies--wrapper {
  @include spacer-component;
  position: relative;
  margin-bottom: 150px;

  @include bp(lg) {
    height: 100vh;
    overflow: hidden;
    margin-bottom: 0;
  }

  @include bp(xl-large) {
    min-height: 800px;
  }
}

.case-studies--content {
  width: 100%;
  height: 100%;

  @include bp(lg) {
    position: absolute;
    left: 0;
  }
}

.case-studies--carousel {
  height: 100%;

  @include bp(lg) {
    position: static;
    height: 100vh;
  }

  @include bp(xl-large) {
    min-height: 800px;
  }
}

.case-studies--carousel .glide__slides {
  @include bp(lg) {
    position: relative;
    width: 100%;
    height: 100vh;
    margin: 0;
    z-index: 1;
  }

  @include bp(xl-large) {
    min-height: 800px;
  }
}

.case-studies--carousel .carousel--controls {
  display: none;
  position: absolute;
  bottom: 10vh;
  left: 5%;
  z-index: 3;

  @include bp(lg) {
    display: flex;
  }

  @include bp(xl-large) {
    left: 120px;
    bottom: 130px;
  }

  .glide__arrow {
    &::after {
      background: $c-white;
    }
  }
}

.case-studies--carousel .glide__bullets {
  left: 0;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 250px;

  @include bp(sm) {
    top: 375px;
  }
}

.case-studies--wrapper .case-studies--title {
  transition: opacity 0.2s;

  > * {
    @include animate-element('text', '.case-studies--title');
    @include animate-delay(1);
  }
}

.case-studies--wrapper .case-studies--title .heading1 {
  @include container;
  margin-bottom: 24px;

  @include bp(lg) {
    position: absolute;
    display: flex;
    align-items: flex-end;
    top: 0;
    width: 48%;
    margin-left: 5%;
    margin-top: 125px;
    z-index: 3;
    background: $c-white;
  }

  @include bp(xl-large) {
    margin-left: 120px;
  }
}

.case-study {
  // position: relative;

  @include bp(lg) {
    // position: absolute;
    display: flex;
    transition: all 0.35s;
    width: 100%;
    height: 100%;
    will-change: transform;
    padding: 230px 0 250px;
    scroll-snap-align: start;
  }
}

.case-study .case-study--image {
  height: 250px;
  margin-bottom: 70px;

  @include bp(sm) {
    height: 375px;
  }

  @include bp(lg) {
    &-mobile {
      display: none;
    }
  }

  > div {
    height: 100%;
  }
}

.case-study--image {
  &-desktop {
    @include bp(lg) {
      &:not(.active) {
        bottom: 0;
        height: 100%;
        left: -0.1rem;
        overflow: hidden;
        padding-bottom: 0;
        position: absolute;
        right: -0.1rem;
        top: 0;
        transform: translateX(100%);
        transition: transform 0.56s cubic-bezier(0.645, 0.075, 0.275, 0.995);
        z-index: 1;

        &[data-active='true'] {
          transform: translateX(0);
          z-index: 2;
        }
      }
    }

    &.active {
      opacity: 0;
      // opacity: 1;
      // visibility: visible;
    }
  }
}

.case-study .case-study--image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.case-studies--carousel .case-study--images-container {
  display: none;

  @include bp(lg) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 390px;
    height: 100%;
    margin-bottom: 0;

    transform: scale(1);
    overflow: hidden;
    transition: transform 0.91s cubic-bezier(0.34, 0.615, 0.4, 0.985) 0.2s;

    .case-study--txt,
    .case-study--callout {
      display: none;
    }
  }
}

.case-study .case-study--txt {
  @include container;

  @include bp(lg) {
    margin-left: 5%;
    width: 420px;
  }

  @include bp(xl-large) {
    margin-left: 120px;
    width: 490px;
  }
}

.case-study .case-study--txt > * {
  @include animate-element('text', '.case-study--txt');
  @include animate-delay(3);
}

.case-study .case-study--txt > h2 {
  font-size: 36px;
  line-height: 1.33;
  color: $c-blue-primary;

  a {
    @include animate-underline($c-blue-primary);
    color: $c-blue-primary;
  }
}

.case-study .case-study--txt > h3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  @include bp(lg) {
    display: block;
    overflow: visible;
  }
}

.case-study .case-study--txt > a svg {
  width: 18px;
  height: 18px;
}

.case-study .case-study--callout {
  display: none;

  @include bp(lg) {
    display: block;
    position: absolute;
    bottom: 75px;
    right: 240px;
    width: 275px;
    height: 275px;
    padding: 40px 25px;
    background: $c-white;
  }

  @include bp(xxxl) {
    bottom: 450px;
  }
}

.case-study .case-study--callout h5 {
  @extend %copy--sm;
  font-weight: $font-weight-regular;
  margin: 0 0 20px;
}

.case-study .case-study--callout .name {
  @extend %button-txt;
  text-transform: uppercase;
  font-size: 14px;
  margin: 0 0 10px;
  letter-spacing: 2px;
}

.case-study .case-study--callout .title {
  @extend %button-txt;
  color: $c-gray-lighter;
  font-weight: $font-weight-bold;
}
