.office-strip {
  margin: 0 auto;
  max-width: 1200px;
  position: relative;

  &-container {
    background: $c-gray-soft;
  }

  > * {
    @include animate-element('text', '.office-strip');
  }

  .layout-container {
    @include container-inset;
    position: relative;
    z-index: 9;
  }

  .office-strip-wrapper {
    align-items: flex-start;
    color: $c-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 470px;
    margin: 0;
    padding: 70px 0;
    position: relative;
    z-index: 4;

    > * {
      @include animate-element('text', '.office-strip-wrapper');
      @include animate-delay-exact(0 4);
    }

    @include bp(lg) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: 470px;
      padding: 85px 0;
    }

    @include bp(xl-large) {
      width: 100%;
    }
  }

  .office-strip-img {
    @include animate-element('image', '.office-strip-wrapper');
    position: absolute;
    top: 0;
    left: 0;
    height: 470px;
    overflow: hidden;

    @media screen and (prefers-reduced-motion: no-preference) {
      position: absolute;
    }

    & > *::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      background-color: $c-black-40;
      transform: scaleX(1);
      transform-origin: right center;
      transition: transform 0.42s cubic-bezier(0.565, 0.43, 0.24, 0.92) 0.315s;
      will-change: transform;
      z-index: 6;
    }

    @include bp(lg) {
      height: 470px;
    }

    @include bp(xl-large) {
      width: 100%;
    }

    > div {
      height: 100%;
    }
  }

  &.no-overlay {
    .office-strip-img {
      & > *::before {
        display: none;
      }
    }
  }

  .office-strip-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: -800px 0;
    position: relative;

    @include bp(md) {
      object-position: -470px 0;
    }

    @include bp(lg) {
      object-position: unset;
    }
  }

  .office-strip-txt,
  .btn {
    position: relative;
    z-index: 2;
  }

  .office-strip-txt {
    @include bp(lg) {
      width: 570px;
    }
  }

  .office-strip-txt > * {
    @include animate-element('text', '.office-strip-txt');
    @include animate-delay-exact(1 2 3 3);
  }

  .office-strip-txt h2 {
    font-family: Teodor;
    font-size: 60px;
    line-height: 62px;
  }

  .office-strip-txt .time {
    display: flex;
    margin-bottom: 12px;

    .hours {
      font-family: Teodor;
      font-weight: 300;
      font-size: 48px;
      line-height: 54px;
    }

    .ampm {
      margin-bottom: 5px;
      margin-top: auto;
      font-family: Spezia;
      font-size: 13.5px;
      line-height: 24px;
    }
  }

  .office-strip-txt .address {
    font-family: Spezia;
    font-size: 13.5px;
    line-height: 24px;
    max-width: 138px;
    margin-bottom: 24px;
  }

  .office-strip-txt .details {
    display: flex;
  }

  .office-strip-button {
    @include animate-element('text', '.office-strip-wrapper');
    transition-delay: 0.7s;
    margin-top: 30px;

    @include bp(lg) {
      margin-top: 0px;
      flex-basis: 120px;
      margin-left: auto;
    }
  }
}
