.btn-tag {
  @extend %btn;
  font-size: 13px !important;
  font-weight: $font-weight-regular !important;
  padding: 8px 15px;
  text-transform: capitalize !important;

  &::after {
    bottom: auto;
    height: 0;
    top: 0;
  }

  &:focus,
  &:hover,
  &.hover {
    &::after {
      bottom: 0;
      height: 100%;
      top: auto;
    }
  }
}

.btn-tag--black {
  border-color: $c-black;
  color: $c-black;

  &:visited {
    color: $c-black;
  }

  &::after {
    background: $c-black;
  }

  &:focus,
  &:hover,
  &.hover {
    color: $c-white;
  }
}

.btn-tag--white {
  border-color: $c-white;
  color: $c-white;

  &:visited {
    color: $c-white;
  }

  &::after {
    background: $c-white;
  }

  &:focus,
  &:hover,
  &.hover {
    color: $c-black;
  }
}
