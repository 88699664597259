.page-container {
  padding-top: 160px;

  .page-container {
    padding-top: 0;
  }
}

body.no-scroll {
  position: fixed;
  width: 100%;
}

.layout-container {
  max-width: 1920px;
  margin: 0 auto;
}

.background-offset-container {
  > *:first-child {
    @include background-offset($c-white);

    .layout-container {
      padding-top: 30px;
    }

    ~ * .layout-container {
      @include bp(xl) {
        padding: 0 66px 0 96px;
      }
    }
  }

  &--gray {
    > *:first-child {
      @include background-offset($c-gray-soft);

      .layout-container {
        padding-top: 30px;
      }

      ~ * .layout-container {
        @include bp(xl) {
          padding: 0 66px 0 96px;
        }
      }
    }
  }

  &--no-overlap {
    > *:first-child {
      @include background-offset($c-white);

      .layout-container {
        padding-top: 30px;
      }

      @include bp(lg) {
        > * {
          margin: 0;
          padding: 0 0 0 35px !important;
        }
      }

      @include bp(xl) {
        > * {
          padding: 0 66px 0 96px !important;
        }
      }

      ~ * .layout-container {
        @include bp(xl) {
          padding: 0 66px 0 96px;
        }
      }
    }
  }
}

.background-offset-container--right {
  > *:first-child {
    @include background-offset-right($c-white);

    .layout-container {
      padding-top: 30px;
    }

    ~ * .layout-container {
      @include bp(xl) {
        padding: 0 66px 0 96px;
      }
    }
  }

  &--gray {
    > *:first-child {
      @include background-offset-right($c-gray-soft);

      .layout-container {
        padding-top: 30px;
      }

      ~ * .layout-container {
        @include bp(xl) {
          padding: 0 66px 0 96px;
        }
      }
    }
  }
}
