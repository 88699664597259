.article-heading-wrapper {
  background-color: $c-white;
  position: relative;
}

.article-heading--content {
  @include container-inset;
  position: relative;
  z-index: 1;

  > * {
    @include animate-element('text', '.article-heading--content');
    @include animate-delay(4);
  }
}

.article-heading--header {
  margin-top: 10px;

  @include bp(md) {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }

  @include bp(lg) {
    margin-top: 10px;
  }

  .article-heading--title-container {
    .article-heading--title {
      font-family: Teodor;
      color: $c-burgundy-primary;
      font-size: 34px;
      line-height: 42px;
      margin: 0;
      max-width: 252px;
      font-weight: normal;

      @include bp(md) {
        max-width: 303px;
      }

      @include bp(lg) {
        font-size: 60px;
        line-height: 62px;
        font-weight: 300;
        max-width: 588px;
      }
    }

    .article-heading--tags-in-title {
      display: block;
      margin-top: 23px;

      &.has-overlay {
        max-height: 158px;
        overflow: hidden;
        position: relative;

        &::after {
          content: '';
          display: block;
          position: absolute;
          z-index: 1;
          top: 0;
          right: 0;
          width: 100px;
          height: 100%;
          background: linear-gradient(90deg, $c-white-0, $c-white 75%);
        }
      }

      @include bp(md) {
        display: none;
      }

      .tag {
        @extend .btn-tag;
        @extend .btn-tag--black;

        display: inline-block;
        font-family: Spezia;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        margin: 0 13px 13px 0;
        text-transform: capitalize;
      }
    }
  }

  .article-heading--info {
    min-width: 184px;
    display: flex;
    flex: 0 0 270px;
    flex-direction: column;
    font-family: Spezia;
    font-style: normal;
    margin-top: 20px;

    > * {
      margin-bottom: 22px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include bp(md) {
      margin-top: 0;
      margin-left: 100px;
    }

    .type {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
      letter-spacing: 1px;
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;
      }
    }

    .authors {
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
      letter-spacing: 1px;
      display: flex;
      align-items: center;

      @include bp(md) {
        margin-top: 0px;
      }

      span {
        @extend %button-txt;
        display: block;
        margin-left: 25px;
      }

      .author {
        position: relative;
        width: 30px;
        height: 30px;
        margin-right: -15px;

        img {
          border-radius: 50%;
          height: 100%;
          object-fit: cover;
          width: 100%;
        }

        &:nth-of-type(1) {
          z-index: 3;
        }

        &:nth-of-type(2) {
          z-index: 2;
        }
      }
    }

    .date {
      font-size: 13.5px;
      line-height: 24px;
      display: flex;

      @include bp(md) {
        margin-top: 0px;
      }

      :not(:first-child) {
        padding-left: 12px;
      }

      :not(:last-child) {
        padding-right: 12px;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          height: 12px;
          top: 6px;
          width: 1px;
          right: 0;
          border-right: 1px solid black;
        }
      }
    }
  }
}

.article-heading--intro {
  display: flex;
  width: 100%;
  margin-top: 45px;
  margin-bottom: 13px;

  @include bp(lg) {
    margin-bottom: 70px;
  }

  .article-heading--tags {
    display: none;

    @include bp(md) {
      display: block;

      &.has-overlay {
        max-height: 106px;
        overflow: hidden;
        position: relative;

        &::after {
          content: '';
          display: block;
          position: absolute;
          z-index: 1;
          top: 0;
          right: 0;
          width: 100px;
          height: 100%;
          background: linear-gradient(90deg, $c-white-0, $c-white 75%);
        }
      }
    }

    .tag {
      @extend .btn-tag;
      @extend .btn-tag--black;
      display: inline-block;
      margin-right: 13px;
      font-family: Spezia;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      text-transform: capitalize;
      margin-bottom: 13px;
    }
  }

  .article-heading--summary {
    flex: 0 0 270px;
    width: 270px;
    height: 270px;
    overflow: hidden;
    padding: 32px 26px;
    background-color: $c-burgundy-primary;
    color: $c-white;
    font-family: Spezia;
    margin-bottom: -54px;

    @include bp(md) {
      margin-bottom: -170px;
      margin-left: auto;
    }

    .title {
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    .text {
      margin-top: 14px;
      font-size: 16px;
      line-height: 28px;
      height: 166px;
      max-height: 166px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.article-heading--image-container {
  @include animate-element('image', '.article-heading-container');
  width: 100%;
  overflow: hidden;
  height: 250px;

  div {
    height: 100%;
  }

  @include bp(md) {
    height: 300px;
  }

  @include bp(lg) {
    height: 450px;
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .article-heading--header {
      .article-heading--title-container {
        .article-heading--title {
          color: $color;
        }
      }
    }

    .article-heading--intro {
      .article-heading--summary {
        background-color: $color;
      }
    }
  }
}

// Updated to adjust margin 10.25.24 BM
