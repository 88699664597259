.rich-text-related-article {
  background: $c-white;
  padding: 90px 0 60px;
  position: relative;

  @include bp(lg) {
    padding: 125px 0 136px;
  }

  .layout-container {
    @include container-inset;
  }

  &-wrapper {
    @include bp(lg) {
      display: flex;
      flex-flow: row nowrap;
    }
  }

  &__media {
    align-items: center;
    display: flex;
    margin: 0 0 88px;
    max-width: 315px;

    @include bp(lg) {
      display: block;
      margin: 0 90px 0 0;
      max-width: 172px;
    }

    h4 {
      &.mobile {
        margin: 0;

        @include bp(lg) {
          display: none;
        }
      }

      &.desktop {
        display: none;

        @include bp(lg) {
          display: block;
        }
      }
    }

    p {
      margin: 0;

      a {
        @include animate-underline($c-burgundy-primary);
        color: $c-burgundy-primary;

        // TODO - update with additional styles for Color themes - tdeluca
      }
    }

    > * {
      @include animate-element('text', '.rich-text-related-article__media');
    }
  }

  &__image {
    height: 97px;
    margin: 0 10px 0 0;
    overflow: hidden;
    width: 172px;

    @include bp(lg) {
      margin: 0 0 10px 0;
    }

    img {
      transition: transform 0.35s;
    }

    &:hover {
      img {
        transform: scale(110%) !important;
        // added !important because animate-element @include overwrites any transform - tdeluca - 07092021
      }
    }
  }

  &__content {
    h2 {
      margin: 0 0 20px;
    }

    .rich-text-basic__text {
      > * {
        @include animate-delay(20);
      }
    }

    > * {
      @include animate-element('text', '.rich-text-related-article__content');
    }
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .rich-text-related-article {
      &__media {
        a {
          @include animate-underline($color);
          color: $color;
        }
      }
    }
  }
}
