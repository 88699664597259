.landing-hero-image--container {
  position: relative;
}

.landing-hero-image--wrapper {
  position: relative;
}

.landing-hero-image--strip {
  height: 375px;
  width: 100%;
  display: none;
  position: absolute;
  top: 125px;
  z-index: -1;

  @include bp(md) {
    display: block;
  }
}

.landing-hero-image--image {
  @include animate-element('image', '.landing-hero-image--image');

  .image {
    height: 250px;
    width: 100%;
    z-index: 1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include bp(md) {
      max-width: 674px;
    }

    @include bp(lg) {
      height: 350px;
      max-width: 954px;
    }

    @include bp(xl-large) {
      height: 500px;
      width: 80%;
      max-width: 1432px;
    }
  }
}
