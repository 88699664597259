.postcard-landing--container {
  margin-top: -57px;
  padding: 70px 0;
  position: relative;

  .event-registration--container {
    padding-bottom: 0;

    .event-registration--wrapper {
      width: inherit;
    }
  }

  &.bg-image {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    &:before {
      background-color: rgba($color: #000000, $alpha: 0.6);
      content: '';
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }

    +.footer-container {

      footer {
        border: none !important;
      }
    }
  }

  &.bg-box {

    .postcard-landing__wrapper--col:not(.form) {
      background-color: rgba($color: #000000, $alpha: 0.6);
      margin-right: 0;
      padding: 50px 44px 50px 49px;
    }
  }

  &.bg-border {

    .postcard-landing__wrapper--col:not(.form) {
      border: 1px solid $c-white;
      margin-right: 0;
      padding: 50px 44px 50px 49px;
    }
  }

  &.bg-color {

    &.blue {
      background-color: $c-blue-primary;
    }

    &.black {
      background-color: $c-blue-primary;
    }

    &.purple {
      background-color: $c-purple-primary;
    }

    &.green {
      background-color: $c-green-primary;
    }

    &.burgundy {
      background-color: $c-burgundy-primary;
    }

    .postcard-landing__wrapper {

      &--col {

        &.form {
          background-color: transparent;
          border: 1px solid $c-white;
        }
      }
    }
  }

  &.bg-video {

    video {
      min-height: 100%;
      min-width: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &:before {
      background-color: rgba($color: #000000, $alpha: 0.7);
      content: '';
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 2;
    }

    .postcard-landing__wrapper {
      z-index: 3;
    }

    +.footer-container {

      footer {
        border: none !important;
      }
    }
  }

  &.transparent {
    margin-top: -177px;
    padding: 190px 0 70px;
  }

  .postcard-landing__wrapper {
    display: flex;
    flex-direction: column;
    font-family: "Spezia", sans-serif;
    font-size: 16px;
    line-height: 28px;
    margin: 0 auto;
    max-width: 960px;
    position: relative;
    width: calc(100% - 40px);
    z-index: 1;

    @include bp(lg) {
      flex-direction: row;
      width: calc(100% - 230px);
    }

    &--col {
      flex-basis: 480px;

      &:first-child {
        margin-right: 40px;
      }

      &.form {
        background-color: $c-white;

        &.bg-border {
          background-color: transparent;
          border: 1px solid $c-white;
        }
      }
    }

    h1,
    h3,
    p,
    li,
    a {
      color: $c-white;
    }

    h1 {
      font-family: "Teodor", serif;
      font-size: 60px;
      font-weight: 300;
      line-height: 62px;
      margin-bottom: 40px;
    }

    h3 {
      font-family: "Spezia", sans-serif;
      font-size: 24px;
      line-height: 40px;
      margin-bottom: 30px;
    }

    p {
      margin: 20px 0;
    }

    ul {
      list-style-type: disc;
      list-style-position: inside;
    }

    ol {
      list-style-type: decimal;
      list-style-position: inside;
    }

    ul ul,
    ol ul {
      list-style-type: circle;
      list-style-position: inside;
      margin-left: 15px;
    }

    ol ol,
    ul ol {
      list-style-type: lower-latin;
      list-style-position: inside;
      margin-left: 15px;
    }
  }
}
