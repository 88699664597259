.podcast-text--wrapper {
  background-color: $c-gray-soft;
  padding-top: 190px;
  padding-bottom: 125px;

  @include bp(md) {
    margin-top: -100px;
    padding-top: 213px;
  }

  @include bp(lg) {
    margin-top: 0;
    padding-top: 74px;
  }
}

.podcast-text--container {
  @include container-inset;

  @include bp(lg) {
    padding: 0 50px;
  }
}

.podcast-text--content {
  display: flex;
  flex-direction: column;

  @include bp(lg) {
    flex-direction: row;
  }
}

.podcast-text--item {
  > * {
    @include animate-delay(4);
    @include animate-element('text', '.podcast-text--item');
  }

  @include bp(lg) {
    margin-right: 102px;
  }

  h2 {
    font-family: Teodor;
    font-size: 36px;
    line-height: 48px;
  }

  .layout-container {
    margin: unset !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}

.podcast-text--side {
  min-width: 172px;
  width: 172px;

  @include bp(lg) {
    margin-left: auto;
  }

  > * {
    @include animate-element('text', '.podcast-text--side');
    @include animate-delay(4, 4);

    @include bp(lg) {
      @include animate-delay(4);
    }
  }

  h3 {
    font-family: Spezia;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
  }

  .button-container {
    margin-bottom: 30px;
  }

  .icon-text {
    font-family: Spezia;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1px;
    padding: 0 0 0 15px;
    position: relative;
    text-transform: uppercase;
    margin-bottom: 25px;

    svg {
      left: 0;
      position: absolute;
      top: 4px;
    }
  }

  .similar-title {
    margin-top: 56px;
    margin-bottom: 36px;
    display: none;

    @include bp(md) {
      display: block;
    }
  }

  .similar-content {
    display: none;

    @include bp(md) {
      display: block;
    }

    > img {
      margin: 0 0 10px 0;
    }

    > a {
      @include animate-underline($c-purple-primary);
      color: $c-purple-primary;
      font-family: Spezia;
      font-size: 16px;
      line-height: 28px;
      text-decoration: unset;
    }
  }
}

.podcast-text--transcript-title {
  margin-top: 60px;
}

.podcast-text--transcript-body {
  margin-bottom: 60px;

  .transcript-btn-more,
  .transcript-btn-less {
    display: inline-block;
    cursor: pointer;
    width: -moz-fit-content;
    width: fit-content;
    color: $c-purple-primary;
    border-bottom: 1px solid $c-purple-primary;
  }

  .transcript-btn-less,
  .podcast-text--transcript {
    display: none;
    opacity: 0;
    visibility: hidden;
  }

  .podcast-text--transcript {
    > * {
      @include animate-element('text', '.podcast-text--transcript');
    }
  }

  &--open {
    .podcast-text--transcript {
      display: block;
    }

    .transcript-btn-less {
      display: inline-block;
    }

    .podcast-text--transcript,
    .transcript-btn-less {
      opacity: 1;
      visibility: visible;
    }

    .transcript-btn-more {
      display: none;
      opacity: 0;
      visibility: hidden;
    }
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .podcast-text--side {
      .similar-content {
        > a {
          @include animate-underline($color);
          color: $color;
        }
      }
    }
  }
}
