.header--callout {
  position: relative;
  margin: 200px 0 0;

  @include bp(xl-large) {
    width: 1080px;
  }
}

.header--callout .header--callout-box {
  @include animate-element('text', '.header--callout');
  position: absolute;
  top: -130px;
  left: 30px;
  min-height: 250px;
  width: 250px;
  padding: 22px 24px;
  color: $c-white;
  background: $c-blue-primary;
  z-index: 2;

  @include bp(lg) {
    left: 120px;
    transition-delay: 0.7s;
  }
}

.header--callout .header--callout-box {

  h4,
  .heading4 {
    font-size: 16px;
    line-height: 1.75;

    @include bp(lg) {
      font-size: 18px;
      line-height: 1.66;
    }

    a {
      @include animate-underline($c-white);
      color: $c-white;
    }
  }
}

.header--callout .header--callout-box .date {
  @extend %button-txt;
  margin-bottom: 14px;
}

.header--callout .header--callout-box svg {
  width: 15px;
  height: 9px;
}

.header--img {
  @include animate-element('image', '.header--callout');
  width: 100%;
  height: 250px;
  max-width: 1200px;

  @include bp(md) {
    height: 500px;
  }

  @include bp(xxl-large) {
    max-width: none;
  }

  @include bp(xxl-larger) {
    margin: 0 auto;
    max-width: 1920px;
  }
}

.header--img div {
  height: 100%;

  @include bp(lg) {
    transition-delay: 0.7s;

    &::after {
      transition-delay: 1s;
    }
  }
}

.header--img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
