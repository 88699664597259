.rich-text-event-detail {
  padding: 35px 0 56px;
  position: relative;

  @include bp(lg) {
    padding: 0;
  }

  .layout-container {
    @include container-inset;

    @include bp(lg) {
      padding: 0 0 85px;
      position: relative;
    }
  }

  &-wrapper {
    @include bp(lg) {
      display: flex;
      flex-flow: row nowrap;
    }
  }

  &__content {
    h2 {
      margin: 0 0 20px;
    }

    .rich-text-basic__text {
      > * {
        @include animate-delay(20);
      }
    }

    > * {
      @include animate-element('text', '.rich-text-event-detail__content');
    }
  }

  &__details {
    margin: 60px 0 0;

    @include bp(lg) {
      flex: 0 0 196px;
      margin: 0 0 0 98px;
    }

    img,
    span {
      display: block;
    }

    img {
      max-width: 316px;

      @include bp(lg) {
        max-width: 196px;
      }
    }

    > * {
      @include animate-element('text', '.rich-text-event-detail__details');
    }
  }

  &__detail {
    @extend %button-txt;
    background-repeat: no-repeat;
    background-position: left 5px;
    color: $c-black;
    margin: 25px 0 0;
    padding: 0 0 0 15px;

    &--location {
      background-image: url('/images/map-pin.svg');
    }

    &--time {
      background-image: url('/images/clock.svg');
    }

    &--audio {
      background-image: url('/images/microphone.svg');
    }

    &--cc {
      background-image: url('/images/closed-caption.svg');
    }
  }
}
