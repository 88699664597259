.cta-spotlight {
  position: relative;

  .layout-container {
    max-width: 1200px;
  }

  .cta-spotlight-wrapper {
    align-items: flex-start;
    color: $c-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 500px;
    margin: 0;
    padding: 70px 30px;
    position: relative;
    z-index: 3;

    @include bp(lg) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: 350px;
      padding: 85px 120px;
    }

    @include bp(xl-large) {
      width: 1080px;
    }
  }

  .cta-spotlight-img {
    @include animate-element('image', '.cta-spotlight-wrapper');
    position: absolute;
    top: 0;
    left: 0;
    height: 500px;

    @media screen and (prefers-reduced-motion: no-preference) {
      position: absolute;
    }

    & > *::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      background-color: $c-black-20;
      transform: scaleX(1);
      transform-origin: right center;
      transition: transform 0.42s cubic-bezier(0.565, 0.43, 0.24, 0.92) 0.315s;
      will-change: transform;
      z-index: 6;
    }

    @include bp(lg) {
      height: 350px;
    }

    @include bp(xl-large) {
      width: 1080px;
    }

    > div {
      height: 100%;
    }
  }

  &.no-overlay {
    .cta-spotlight-img {
      & > *::before {
        display: none;
      }
    }
  }

  &.has-overlap {
    @include bp(lg) {
      margin: -127px 0 0;
    }
  }

  &.has-padding {
    padding: 30px 0;
  }

  .cta-spotlight-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: -800px 0;

    @include bp(md) {
      object-position: -500px 0;
    }

    @include bp(lg) {
      object-position: unset;
    }
  }

  .cta-spotlight-txt,
  .btn {
    position: relative;
    z-index: 2;
  }

  .cta-spotlight-txt {
    z-index: 9;
    @include bp(lg) {
      width: 570px;
    }
  }

  .cta-spotlight-txt > * {
    @include animate-element('text', '.cta-spotlight-txt');
  }

  .cta-spotlight-txt h2 {
    font-size: 36px;
    line-height: 1.33;
    transition-delay: 0.7s;
  }

  .cta-spotlight-txt h3 {
    font-size: 19px;
    margin: 0 0 52px;
    transition-delay: 0.7s;

    @include bp(lg) {
      font-size: 24px;
      line-height: 1.66;
      margin-bottom: 0;
    }
  }

  .cta-spotlight-button {
    @include animate-element('text', '.cta-spotlight-wrapper');
    transition-delay: 1s;
    z-index: 9;

    @include bp(lg) {
      flex-basis: 120px;
      margin-left: auto;
    }
  }
}
