.anchor-links {

	&__title {
		font-family: $font-family-sans;
		font-size: 11px;
		font-weight: 600;
		letter-spacing: 1px;
		line-height: 20px;
		padding: 20px 26px 20px 0;
		text-transform: uppercase;
	}

	&__items {
		align-items: center;
		display: flex;
		list-style-type: none;
		margin: 0;
		max-width: 100%;
		padding: 0;

	    > * {
	      @include animate-element('text', '.anchor-links__items');
	      @include animate-delay(50);
	    }

		li {
			transition: all 250ms;
			transition-delay: inherit !important;

			.link--button {
			    background-position: 100% 100%;
			    background-size: 0 1px;
				font-family: $font-family-sans;
				font-size: 11px;
				font-weight: 600;
				letter-spacing: 1px;
				line-height: 20px;
				margin: 0 26px;
				padding: 20px 0;

				&:hover {
					background-position: 0 100%;
					background-size: 100% 1px;
				}

				&:after {
					display: none;
				}
			}
		}
	}

    // > * {
    //   @include animate-element('text', '.rich-text-basic__text');
    //   @include animate-delay(50);
    // }

	.layout-container {
		align-items: center;
		display: flex;
	    max-width: 960px;
	    margin-left: 30px;
	    margin-right: 30px;

	    @include bp('md'){
		    margin-left: auto;
		    margin-right: auto;
		    width: calc(100% - (2 * 115px));
		}

		.scrollable-container {			
			margin: 0 -30px;
			max-width: calc(100% + 60px);

			@include bp('md'){
				margin: inherit;
				max-width: inherit;
			}
		}
	}
}