.related-content {
	margin-top: 120px;

	.layout-container {
    margin: 0 auto;
    max-width: 960px;
		padding: 32px;

		@include bp('lg'){
			padding: 34px 0 68px;
		}

		h3 {
			color: $c-white;
			font-family: "Sepzia", sans-serif;
			font-size: 11px;
			font-weight: 600;
			letter-spacing: 1px;
			line-height: 20px;
			margin: 0 0 6;
			text-transform: uppercase;
		}

		h4 {
			color: $c-white;
			font-family: Teodor;
			font-size: 28px;
			font-weight: 300;
			line-height: 36px;
			margin: 0 0 24px;

			@include bp('lg'){
				font-size: 36px;
				line-height: 48px;
				margin-bottom: 21px;
			}
		}

		ul {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			list-style-type: none;
			margin: 0;
			padding: 0;

			@include bp('lg'){
				flex-direction: row;
        padding-left: 96px;
			}

			&:hover {

				li {

					a {
						color: $c-black;
					}
				}
			}

			li {
				margin: 12px 0;

				@include bp('lg'){
					flex: 0 0 calc(100% / 3);
					margin: 15px 0;
				}

				a {
					color: $c-white;
					font-family: "Spezia", sans-serif;
					font-size: 16px;
					line-height: 28px;
					text-decoration: none;
					transition: all .35s;

					&:hover {
						color: $c-white;
					}
				}
			}
		}
	}
}

@each $section, $color in $sectionColors {
	.page-container[data-section='#{$section}'] {
		.related-content {
			background-color: $color;
		}
	}
}
