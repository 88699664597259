.inthemedia {
	position: relative;
	background-color: $c-gray-soft;
	margin-top: 110px;
	padding-top: 110px;
	padding-bottom: 110px;

	@include bp(sm) {
	padding-top: 107px;
	padding-bottom: 135px;
	}

	@include bp(lg) {
	padding-top: 137px;
	padding-bottom: 137px;
	}

	@include bp(xxl) {
	padding: 137px 0 161px 0;
	overflow: hidden;
	}

	.inthemedia-container {
		@include container-inset;

		h3 {
	        font-family: Teodor;
			font-size: 60px;
	        font-style: normal;
	        font-weight: 300;
			line-height: 62px;
		}

		&__list {
			display: flex;
			overflow: hidden;

			> * {
				@include animate-element('text', '.inthemedia-container__list');
				@include animate-delay(2);
			}

			&--item {
				flex: 1;
				min-width: 100%;
				transition: all 250ms;

				@include bp(lg){
					min-width: 50%;
					padding-right: 20px;
					width: auto;
				}

				h4 {
					font-family: Spezia;
					font-size: 24px;
					font-style: normal;
					font-weight: 400;
					line-height: 40px;
					margin: 24px 0 35px;
				}

				h5 {
					font-family: Spezia;
					font-size: 11px;
					font-style: normal;
					font-weight: 600;
					line-height: 20px;
					margin: 0 0 10px;
					text-transform: uppercase;
				}

				span {
					font-family: Spezia;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 28px;
				}

				a {
					display: inline-block;
					filter: invert(1);

					&.external{
						transform: rotate(-45deg);
					}
				}
			}

			&[data-animation=true] >.inthemedia-container__list--item {
			    transition-delay: inherit;
			}
		}

		&__nav {
			display: none;
			margin-top: 50px;

			&.show {
				display: flex;
			}

			.pagination__arrow {

				&--left {
					margin: 0 10px 0 0;
				}

				&--right {
					margin: 0 0 0 10px;
				}
			}
		}
	}
}