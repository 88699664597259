.video-embed {
  background: $c-white;
  position: relative;

  .layout-container {
    margin: 0 auto;
    max-width: 1380px;
  }

  &__media {
    a {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
    }

    &-wrapper {
      position: relative;
    }
  }

  &-wrapper {
    padding: 60px 30px;

    @include bp(lg) {
      padding: 80px 30px;
    }

    > * {
      @include animate-element('text', '.video-embed-wrapper');
      @include animate-delay(1);
    }
  }

  &__play {
    align-items: center;
    bottom: 40px;
    display: flex;
    flex-flow: row wrap;
    gap: 0 16px;
    left: 40px;
    position: absolute;
  }

  &__play-icon {
    @extend .btn;
    @extend .btn--white;
    align-items: center;
    border-radius: 50%;
    display: flex;
    flex-flow: row nowrap;
    height: 65px;
    justify-content: center;
    overflow: hidden;
    width: 65px;
  }

  &__play-text {
    @extend %button-txt;
    color: $c-white;
    display: none;

    @include bp(lg) {
      display: block;
    }
  }

  &.v2 {

    &.hero {
      background-color: transparent;

      &.black {

        .video-embed__media-wrapper {

          &:before {
            background-color: transparent;
          }
        }
      }

      .video-embed-wrapper {
        padding: 0;

        .video-embed__media {
          width: fit-content;

          &-wrapper {

            &--title {
              max-width: fit-content;
              position: initial;

              .video-embed__play {
                margin-top: 0;
              }
            }
          }
        }
      }
    }

    &.black {

      .video-embed__media-wrapper {

        &:before {
          background-color: rgba(255, 255, 255, 0.6);
        }

        &--title {

          .title {
            color: $c-black;
          }

          .text {
            color: $c-black;
          }
        }

        .video-embed__play {
          border-color: $c-black;

          &:before {
            background-color: $c-black;
          }

          &:hover {

            .video-embed__play-icon {

              svg {
                fill: $c-black;
              }
            }

            .video-embed__play-text {
              color: $c-black;
            }
          }

          .video-embed__play-icon {

            svg {
              fill: $c-white;
            }
          }

          .video-embed__play-text {
            color: $c-white;
          }
        }
      }
    }

    .video-embed__media-wrapper {

      &:before {
        background-color: rgba(0, 0, 0, 0.4);
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
      }

      &--title {
        max-width: 600px;
        position: absolute;
        left: 122px;
        top: 109px;

        .title {
          color: $c-white;
          margin-bottom: 10px;
        }

        .text {
          color: $c-white;
        }

        .video-embed__play {
          background-color: transparent;
          border: 1px solid $c-white;
          bottom: auto;
          cursor: pointer;
          gap: 0;
          left: auto;
          margin-top: 42px;
          max-height: 56px;
          position: relative;
          width: fit-content;

          &:before {
            backface-visibility: hidden;
            background-color: $c-white;
            bottom: 0;
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: auto;
            transition: all .35s;
            transform: translate3d(0,0,0);
          }

          &:hover {

            &:before {
              bottom: auto;
              height: 0;
              top: 0;
            }

            .video-embed__play-icon {

              svg {
                fill: $c-white;
              }
            }

            .video-embed__play-text {
              color: $c-white;
            }
          }

          &-icon {
            @extend .btn;
            align-items: center;
            border: none;
            border-radius: 0;
            display: flex;
            flex-flow: row nowrap;
            height: 56px;
            justify-content: center;
            overflow: hidden;
            padding: 16px 20px;

            svg {
              fill: $c-black;
              height: 22px;
              width: 18px;
            }

            &:after {
              display: none;
            }
          }

          &-text {
            color: $c-black;
            margin-right: 20px;
            position: relative;
          }
        }
      }
    }
  }
}
