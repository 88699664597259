// Colors variables
// Color variables should start with 'c-'
$c-black: #000000;
$c-black-0: rgba($c-black, 0);
$c-black-20: rgba($c-black, 0.2);
$c-black-40: rgba($c-black, 0.4);
$c-black-70: rgba($c-black, 0.7);
$c-white: #ffffff;
$c-white-0: rgba($c-white, 0);

// Monotone
$c-gray-dark: #212121;
$c-gray-light: #585656;
$c-gray-light-fade: #696969;
$c-gray-lighter: #959392;
$c-gray-lighter-fade: #787878;
$c-gray-pale: #dfdddd;
$c-gray-soft: #f2f2f2;

// Accent 1
$c-blue-primary: #0a2fb5;
$c-blue-mid: #2d53ed;
$c-blue-light: #488cff;
$c-blue-pale: #bcdcfb;

// Accent 2
$c-purple-primary: #4a0080;
$c-purple-mid: #6500ad;
$c-purple-light: #a167ff;
$c-purple-pale: #dab8ff;

// Accent 3
$c-green-primary: #117c96;
$c-green-mid: #00b2c7;
$c-green-light: #21d8ed;
$c-green-pale: #a6f5ff;

// Accent 4
$c-burgundy-primary: #990c47;
$c-burgundy-mid: #c7105c;
$c-burgundy-light: #ed5a8f;
$c-burgundy-pale: #ffd9e5;

// Filters
$f-white: invert(100%) sepia(100%) saturate(1%) hue-rotate(94deg)
  brightness(105%) contrast(102%);

// Font-related variables
// Font variables should start with 'font-'
$font-family-serif: 'Teodor', serif;
$font-family-sans: 'Spezia', sans-serif;
$font-family-icons: 'icons';

// Font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-black: 700;
$font-weight-xblack: 900;

// Breakpoint variables
// Used in bp mixin in _mixins.scss
$breakpoints: (
  sm: 480px,
  md: 768px,
  lg: 960px,
  xl: 1024px,
  xl-large: 1200px,
  xxl: 1260px,
  xxl-large: 1440px,
  xxl-larger: 1920px,
  xxxl: 2060px,
);

// Animations
$easeOutCubic: cubic-bezier(0.25, 0.46, 0.45, 0.94);
