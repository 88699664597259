.spotlight-text--wrapper {
  padding: 0 0 100px;

  @include bp(md) {
    padding-top: 0;
  }

  .layout-container {
    @include container-inset;
  }

  .spotlight-text {
    margin: 40px 0;

    @include bp(lg) {
      margin: 45px 0;
    }

    > * {
      @include animate-element('text', '.spotlight-text');
      @include animate-delay(3);
    }

    .title {
      font-family: Teodor;
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      line-height: 48px;
    }

    .text {
      margin-top: 21px;
      font-family: Spezia;
      font-size: 24px;
      line-height: 40px;
    }

    .link {
      margin-top: 21px;
    }
  }
}
