.sticky-bar {
  background-color: transparent;
  z-index: 5;

  &__filters-container {
    > * {
      @include animate-element('text', '.sticky-bar__filters-container');
      @include animate-delay(2);
    }
    background-color: transparent;
  }

  @include bp(lg) {
    margin-left: calc((100vw - 978px)/2);
  }

  &__filters {
    padding: 30px 0;
    position: relative;
    z-index: 3;
    color: $c-black !important;
    background-color: $c-black;

    @include bp(md) {
      // background: linear-gradient(to right, transparent 50%, $c-black 50%);
      color: $c-white !important;
      margin-left: calc((100vw - 960px)/2);
      padding: 0;

      &.full-width {
        margin-left: inherit;
      }

      .layout-container {
        background-color: transparent;
      }
    }
  }

  &__mobile-nav {
    background: $c-black;
    bottom: 0;
    color: $c-white;
    position: fixed;
    right: 0;
    top: 0;
    width: 0;
    z-index: 10;

    @include bp(md) {
      display: none;
    }

    &__headers {
      margin: 75px 10px 0 30px;
      overflow: auto;
      padding-right: 20px;
    }

    &__header,
    &__header-link {
      margin-bottom: 24px;

      > a {
        color: $c-white;
        display: block;
        font-family: Teodor;
        font-size: 30px;
        line-height: 48px;
        position: relative;
        text-decoration: none;

        // Only for submenus
        &:before,
        &:after {
          content: '';
          @extend .navigation-header--transition;
          position: absolute;
          background-color: $c-white;
          width: 2px;
          height: 12px;
          right: 21px;
          top: 12px;
          pointer-events: none;
        }

        &:before {
          transform: translate(8px, 8px) rotate(-45deg);
        }

        &:after {
          transform: translate(16px, 8px) rotate(45deg);
        }

        &.open {

          &:before {
            transform: translate(8px, 8px) rotate(45deg);
          }

          &:after {
            transform: translate(16px, 8px) rotate(-45deg);
          }

          + .sticky-bar__mobile-nav__items {
            max-height: 1024px;
          }
        }
      }
    }

    &__header-link {

      > a {
        overflow: hidden;
        padding-right: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;

        &::before {
          transform: translate(16px, 4px) rotate(-45deg);
        }

        &::after {
          transform: translate(16px, 12px) rotate(45deg);
        }
      }
    }

    &__inner {
      display: flex;
      flex-flow: column nowrap;
      height: 100vh;
      position: relative;
    }

    &-close {
      @extend %button-txt;

      align-items: center;
      color: $c-white;
      display: flex;
      flex-flow: row nowrap;
      opacity: 0;
      right: 30px;
      position: absolute;
      text-decoration: none;
      top: 30px;
      visibility: hidden;
      z-index: 10;

      img {
        margin: 0 0 0 10px;
      }
    }

    *::-webkit-scrollbar {
      width: 1px;
    }

    /* Track */
    *::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    *::-webkit-scrollbar-thumb {
      background: $c-gray-light;
      border: 0px;
    }

    &__items {
      flex: 1;
      max-height: 0;
      overflow: auto;
      scrollbar-color: $c-gray-light transparent;
      scrollbar-width: thin;
      transition: max-height 0.2s;
    }

    &__item-title {
      align-items: center;
      cursor: pointer;
      display: flex;
      font-family: $font-family-serif;
      font-weight: $font-weight-light;
      font-size: 30px;
      line-height: 48px;
      padding: 10px 0;
      position: relative;
      transition: all 0.2s;

      .sticky-bar__mobile-nav__item.active & {
        color: $c-purple-light;
      }

      svg {
        height: 12px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.2s;
        width: 18px;

        .sticky-bar__mobile-nav__item.active & {
          transform: translateY(-50%) rotate(180deg);
        }

        path {
          fill: $c-white;
          transition: all 0.2s;

          .sticky-bar__mobile-nav__item.active & {
            fill: $c-purple-light;
          }
        }
      }
    }

    // &__item-filters {
    //   max-height: 500px;
    //   overflow: hidden;
    //   transition: max-height 0.2s;

    //   .sticky-bar__mobile-nav__item.active & {
    //     max-height: 500px;
    //   }
    // }

    &__bottom {
      border-top: 1px solid $c-gray-light;
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin: 0 30px;
      padding: 30px 0;
    }

    &__clear-filters {
      &::after {
        display: none;
      }
    }
  }

  &__mobile-nav-link {
    align-items: center;
    box-sizing: border-box;
    color: $c-white !important;
    display: flex;
    font-family: $font-family-sans;
    font-weight: 600;
    font-size: 11px;
    justify-content: space-between;
    letter-spacing: 1px;
    line-height: 24px;
    padding: 0 48px 0 0;
    position: relative;
    text-transform: uppercase;

    @include animate-underline($c-black);

    &:first-child {
      &::after {
        background: #c2c9d1;
        content: '';
        height: 27px;
        width: 1px;
        position: absolute;
        left: 0px;
      }
    }
    @include bp(md) {
      display: none;
    }

    img {
      margin: 0 8px 0 0;
    }

    &::after {
      display: none;
    }

    &__container {
      display: flex;
      justify-content: space-between;

      @include bp('md'){
        display: none;
      }

      .backtotop {
        margin-left: 31px;
        opacity: 0;
      }
    }

    span {
      position: relative;

      &::after,
      &::before {
        content: "";
        position: absolute;
        background-color: $c-white;
        width: 1px;
        height: 7px;
        right: 21px;
        top: -4px;
        pointer-events: none;
      }

      &::before {
        transform: translate(38px, 14px) rotate(45deg);
      }

      &::after {
        transform: translate(33px, 14px) rotate(-45deg);
      }
    }
  }

  &__filters-nav {
    display: none;
    min-height: 70px;

    @include bp(md) {
      @include container-inset;

      margin-left: 0 !important;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      align-items: center;

      > div {
        flex: 1 1 auto;
      }
    }

    .filter__nav-item,
    .filter__dropdown-nav-item {
      @extend %button-txt;

      cursor: pointer;
      position: relative;
      transition: all 0.35s;

      svg {
        margin: 0 0 0 5px;
        transition: all 0.35s;
        vertical-align: middle;

        path {
          fill: $c-black;
        }
      }

      &.hover {
        a {
          color: $c-purple-light;
        }

        &::after {
          height: 4px;
        }

        svg {
          transform: rotate(180deg);

          path {
            fill: $c-purple-light;
          }
        }

        [role='listbox'] {
          border-color: $c-white;
        }
      }

      [role='listbox'] {
        border-bottom: 4px solid $c-black;
        display: inline-block;
        padding: 28px 0 24px;
        white-space: nowrap;

        a {
          color: inherit;
        }
      }
    }

    &__menuitem {
      text-align: center;
      position: relative;
      color: $c-black;
      border-top: none;

      &.active {
        a {
          color: $c-black;
          background: $c-white;
        }

        &::after {
          background: $c-black;
          bottom: -33px;
          content: '';
          height: 4px;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 60%;
          position: absolute;
          transition: height 0.35s;
        }
      }

      div {
        margin: 0 10px;
        text-align: center;
        font-weight: 600;
      }

      a:not(.backtotop) {
        @include animate-underline($c-white);
        text-decoration: none;
        text-transform: uppercase;
        font-family: $font-family-sans;
        font-size: 11px;
        line-height: 20px;
        letter-spacing: 1px;
        color: $c-white;
        font-weight: 600;
        &.sb-button {
          padding: 10px 20px;
        }
      }

      &:not(:first-child) {
        &::before {
          background: $c-black;
          content: '';
          height: 27px;
          width: 1px;
          position: absolute;
          left: 0px;
        }
      }

      &--button {
        @extend .sticky-bar__filters-nav__menuitem;
        margin: auto;
        max-width: 90px;

        >div {
          text-align: left;

          a:not(.backtotop) {
            @include animate-underline($c-white);
            color: $c-white;
            background-color: $c-black;
          }
        }
      }
    }
  }

  &__filters-dropdown {
    background: linear-gradient(to right, transparent 50%, $c-black 50%);
    left: 0;
    max-height: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    transition: max-height 0.7s;
    top: 100%;

    // .layout-container {
    //   @include container-inset;
    // }

    &.hover {
      max-height: 500px;
    }

    &-inner {
      background: $c-black;
      border-top: 0.5px solid $c-gray-light;
    }
  }

  &__content {
    opacity: 0;
    padding: 55px 0 25px;
    transition: opacity 0.7s;

    @include bp(md) {
      padding: 55px 10% 25px;
    }

    &.show {
      opacity: 1;
    }
  }

  &__items {
    display: flex;
    flex-flow: row wrap;

    &:focus,
    &:hover {
      a {
        color: $c-gray-lighter-fade;
      }

      span {
        color: $c-purple-light;
      }

      .sticky-bar__item {
        &:focus,
        &:hover {
          a {
            color: $c-white;
          }

          span {
            color: $c-purple-light;
          }
        }

        text-decoration: none;
      }
    }

    &.hover-selected {
      a {
        color: $c-gray-lighter-fade;
      }

      span {
        color: $c-gray-lighter-fade;
      }

      .item-selected {
        a {
          color: $c-white !important;
        }

        span {
          color: $c-purple-light !important;
        }
      }
    }

    .sticky-bar__mobile-nav__item & {
      display: block;

      &:focus,
      &:hover {
        a {
          color: $c-white;
        }

        span {
          color: $c-purple-light;
        }

        .sticky-bar__item {
          &:focus,
          &:hover {
            a {
              color: $c-white;
            }

            span {
              color: $c-purple-light;
            }
          }
        }
      }
    }
  }

  &__item {
    align-items: center;
    display: flex;
    cursor: pointer;
    flex-flow: row nowrap;
    flex: 0 1 50%;
    margin: 0 0 30px;
    position: relative;
    transition: all 0.35s;
    width: 50%;

    @include bp(md) {
      padding-right: 30px;
      flex: 0 1 33.33%;
      width: 33.33%;
    }

    @include bp(lg) {
      padding-right: 30px;
      flex: 0 1 33.33%;
      width: 33.33%;
    }

    svg {
      fill: $c-gray-dark;
      height: 10px;
      width: 10px;
      margin: 0 0 0 -20px;
      opacity: 0;
      position: absolute;
      transition: all 0.35s;
    }

    a {
      text-decoration: none;
      color: $c-white;
      font-family: $font-family-sans;
      margin: 0;
      transition: all 0.35s;
    }

    span {
      @extend %copy--sm;
      color: $c-purple-light;
      margin: -15px 0 0 4px;
    }

    &--selected {
      padding: 0 0 0 30px;

      @include bp(lg) {
        margin-left: -30px;
        margin-right: 30px;
      }

      svg {
        opacity: 1;

        line {
          stroke: $c-purple-light;
        }
      }

      a {
        text-decoration: none;
        color: $c-purple-light;
      }
    }

    .sticky-bar__mobile-nav__item & {
      flex: 0 0 100%;
      margin: 24px 0;
      padding: 0;
      width: 100%;

      svg {
        display: none;
        margin: 0 10px 0 0;
        opacity: 1;
        position: static;

        line {
          stroke: $c-purple-light;
        }
      }

      span {
        color: $c-purple-light;
      }

      &--selected {
        svg {
          display: block;
        }

        a {
          color: $c-purple-light;
        }
      }
    }
  }

  &__tags {
    display: block;
    margin: 45px 0 31px;
    position: relative;
    margin-bottom: 60px;

    @include bp(lg) {
      display: flex;
      flex-wrap: wrap;
      margin: 50px 0 64px;
      padding-right: 175px;
    }
  }

  &__tag {
    @extend .btn-tag;
    @extend .btn-tag--black;

    border: 0;
    font-size: 16px;
    line-height: 28px;
    margin-right: 13px;
    margin-bottom: 13px;
    padding: 5px 10px;
    background: #efefef;

    a {
      text-decoration: none;
    }

    img {
      filter: invert(1);
      height: 10px;
      width: 10px;
      margin-right: 5px;
    }

    &:focus,
    &:hover {
      img {
        filter: invert(0);
      }
    }
  }

  &__clear-tags {
    @include bp(lg) {
      display: block;
      margin-left: auto;
      position: absolute;
      top: 0;
      right: 0;
    }

    &::after {
      display: none;
    }
  }

  &__insights {
    > * {
      @include animate-element('text', '.sticky-bar__insights');
      @include animate-delay(20, 2);
    }

    &[data-animation='true'] {
      .insight {
        transform: none !important;
        // added !important because animate-element @include overwrites any transform - tdeluca - 07222021
        will-change: auto !important;
        // needed to overwrite animate-element @include rule so filter position is respected - tdeluca - 07222021
      }
    }
  }

  &.fixed-bar {
    width: 100%;
    position: fixed;
    top: 0;

    .sticky-bar__filters-nav__menuitem--button,
    .sticky-bar__mobile-nav-link__container {

      a.backtotop {
        opacity: 1;
      }
    }
  }
}

.sticky-bar--initial-white {
  @include bp(lg) {
    .filter__nav-item,
    .filter__dropdown-nav-item {
      &::after {
        background: $c-white;
      }

      svg {
        path {
          fill: $c-white;
        }
      }
    }

    .sticky-bar__filters-nav {
      &__menuitem {
        color: $c-white;

        &.active {
          a {
            color: $c-white;
            background: transparent;
          }

          &::after {
            background: $c-white !important;
          }
        }

        a:not(.backtotop) {
          @include animate-underline($c-white);
          color: $c-white;
        }

        // &:not(:first-child) {
        //   &::before {
        //     background: $c-white;
        //   }
        // }

        &--button {
          a {

            &.backtotop {
              display: inline-block;
              margin: 20px 30px;
              opacity: 0;
              transition: all 250ms;
            }
          }
        }
      }
    }

    .sticky-bar__mobile-nav-link {
      color: $c-white !important;
      @include animate-underline($c-white);
      img {
        filter: brightness(1);
      }
    }
  }
}

.sticky-bar--white {
  .filter__nav-item,
  .filter__dropdown-nav-item {
    &::after {
      background: $c-white;
    }

    svg {
      path {
        fill: $c-white;
      }
    }
  }

  .sticky-bar__filters-nav {
    &__menuitem {
      color: $c-white;

      &.active {
        a {
          color: $c-white;
          background: $c-black;
        }

        &::after {
          background: $c-white !important;
        }
      }

      a {
        @include animate-underline($c-white);
        color: $c-white;
      }

      &:not(:first-child) {
        &::before {
          background: $c-white;
        }
      }

      &--button {
        a {
          color: $c-black;
          background-color: $c-white;
        }
      }
    }
  }

  .sticky-bar__mobile-nav-link {
    color: $c-white !important;
    @include animate-underline($c-white);
    img {
      filter: brightness(1);
    }
  }

  background-color: $c-black;

  .sticky-bar__filters {
    background: $c-black;
  }
}

@each $section, $color in $sectionLightColors {
  .page-container[data-section='#{$section}'] {
    .sticky-bar {

      &__mobile-nav {

        &__item {

          &.active {

            .sticky-bar__mobile-nav__item-title {
              a {
                color: $color;
              }

              svg {
                path {
                  fill: $color;
                }
              }
            }
          }

          &:focus,
          &:hover {
            span {
              color: $color;
            }
          }
        }
      }

      &__filters-nav {

        .filter__nav-item {
          &.hover {
            a {
              color: $color;
            }

            svg {
              path {
                fill: $color;
              }
            }
          }
        }

        .filter__dropdown-nav-item {

          &.hover {

            [role="listbox"] {
              color: $color;
            }
          }
        }
      }

      &__items {
        &:hover {
          .sticky-bar__item {
            &:hover {
              span {
                color: $color;
              }
            }
          }
        }
      }

      &__item {
        span {
          color: $color;
        }

        &--selected {
          svg {
            line {
              stroke: $color;
            }
          }

          a {
            color: $color;
          }
        }

        &.hover-selected {
          .item-selected {
            span {
              color: $color !important;
            }
          }
        }
      }
    }

    .sticky-bar__filters-nav {
      &__menuitem {
        &.active {
          &::after {
            background: $color;
          }
        }
      }
    }
  }
}
