.video-header-subnav {
  &--wrapper {
    ~ * {
      @include bp(lg) {
        position: relative;
        z-index: 1;
      }
    }

    .background-offset-container {
      .layout-container {
        max-width: 975px;

        > * {
          @include animate-element('text', '.layout-container');
          @include animate-delay-exact(3 null 4);

          @include bp(lg) {
            @include animate-delay-exact(null 3 4);
          }
        }
      }
    }

    .header-subnav--outside-nav {
      background: $c-white;
      padding: 0;

      .header-subnav--filters {
        margin: 0 auto 55px;
        max-width: 315px;
        @include animate-delay(1, 2);
      }
    }
  }

  &--container {
    min-height: 530px;
    padding: 0 0 60px;
    background-repeat: no-repeat;
    background-size: cover;
    color: $c-white;
    position: relative;

    &::before {
      background: $c-black-40;
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    &.no-overlay {
      &::before {
        display: none;
      }
    }

    @include bp(lg) {
      margin-bottom: -125px;
      min-height: 800px;
      padding-bottom: 230px;
    }
  }

  &--content {
    @include container-inset;
    padding-top: 161px;
    margin-top: -161px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    > * {
      @include animate-element('text', '.video-header-subnav--content');
      @include animate-delay-exact(1 3);

      @include bp(xl) {
        @include animate-delay(2);
      }
    }

    .video-embed__play {
      position: relative !important;
      left: 0;
    }

    .video-embed__play-button {
      @extend .btn;
      @extend .btn--white;
      align-items: center;
      border-radius: 50%;
      display: flex;
      flex-flow: row nowrap;
      height: 100px;
      justify-content: center;
      overflow: hidden;
      width: 100px;

      &::after {
        height: 0;
      }

      svg {
        path {
          fill: $c-white;
        }
      }

      &:focus,
      &:hover {
        svg {
          path {
            fill: $c-black;
          }
        }

        &::after {
          height: 100%;
        }
      }
    }

    @include bp(lg) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &--headline {
    font-family: 'Teodor';
    font-size: 34px;
    font-weight: 300;
    line-height: 42px;
    margin-bottom: 0;
    margin-top: 10px;
    max-width: 320px;

    @include bp(xl) {
      font-size: 60px;
      line-height: 62px;
      margin-bottom: 32px;
      margin-top: 0;
      max-width: none;
    }

    &--mobile {
      @include bp(xl) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include bp(xl) {
        display: block;
      }
    }
  }

  &--text {
    margin: 0 0 75px;
  }

  &--intro {
    margin-top: 123px;

    > * {
      @include animate-element('text', '.video-header-subnav--intro');
      @include animate-delay(2);
    }
  }
}
