.mktoForm {
  position: relative;
  @include container-inset;

  > * {
    @include animate-element('text', '.mktoForm');
    @include animate-delay-exact(null 1 null null 2);
  }

  padding-left: 0 !important;
  padding-right: 0 !important;

  @include bp(md) {
    width: calc(100% - 60px) !important;
  }

  @include bp(lg) {
    width: calc(100% - (2 * 115px)) !important;
  }

  .mktoFormCol {
    position: relative;
    float: none !important;
  }

  .mktoFormRow,
  .mktoButtonRow {
    position: relative;
    display: block;
  }

  .mktoFormRow {
    position: relative;
    z-index: 1;

    .mktoFormCol {
      .mktoFieldWrap.mktoCheckbox {
        .mktoLabel {
          display: flex;
          font-size: 24px;
          line-height: 1.25;
          margin: 0 0 0 50px;
          order: 2;
          position: relative;
          margin-left: 0 !important;
        }

        .mktoCheckboxList {
          order: 1;
        }

        .mktoAsterix {
          margin-left: -15px;
        }
      }
    }

    // .mktoFieldWrap {

    //   > #LblmktoOptIn,
    //   > #Lblmsdyn_gdproptout,
    //   > #LblrequestedConsultation {
    //     display: flex;
    //     font-size: 24px;
    //     line-height: 1.25;
    //     margin: 0 0 0 50px;
    //     position: relative;

    //     .mktoAsterix {
    //       margin-left: -15px;
    //     }
    //   }
    // }
  }

  .mktoButtonWrap,
  .mktoButtonWrap.mktoDownloadButton[style] {
    margin: 0 auto !important;

    .mktoButton {
      @extend .btn;
      @extend .btn--black;

      background: none !important;
      border-color: $c-black !important;
      border-style: solid !important;
      border-width: 1px !important;
      box-shadow: none !important;
      font-size: 11px !important;
      left: 0 !important;
      padding: 8px 25px !important;

      &:focus,
      &:hover {
        border-color: $c-black !important;
        color: $c-black !important;
      }
    }
  }

  .mktoGutter,
  .mktoOffset {
    display: none;
  }

  .mktoError {
    right: auto !important;

    .mktoErrorArrow {
      background-color: $c-burgundy-mid !important;
      border-top-color: $c-burgundy-mid !important;
      border-left-color: $c-burgundy-mid !important;
    }

    .mktoErrorMsg {
      background: $c-burgundy-mid !important;
    }
  }

  .mktoFieldWrap,
  .mktoButtonWrap {
    display: flex;
    float: none;
    position: relative;
    transition: height 0.28s 0s;
    height: auto;
    overflow: inherit;
    margin-top: 35px !important;
    flex-grow: 1;
    width: 100%;

    &.active {
      height: 70px;
    }

    &.has-value label.mktoLabel,
    &:focus-within label.mktoLabel,
    &.has-content label.mktoLabel {
      margin-top: -25px;
      font-size: 13.5px;
    }

    &:focus-within .mktoClear,
    &.has-content .mktoClear {
      &::after {
        width: 100%;
      }
    }

    &:nth-child(4) {
      @include bp(lg) {
        margin-top: 62px;
      }
    }

    label.mktoLabel {
      pointer-events: none;
      position: absolute;
      z-index: 1;
      font-size: 24px;
      font-family: Spezia;
      font-size: 24px;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 60px;
      letter-spacing: 0px;
      padding: 0;
      text-align: left;
      transition:
        margin-top 0.35s ease 0s,
        font-size 0.35s;
      width: auto !important;

      .mktoAsterix {
        color: $c-burgundy-mid;
        float: right;
      }

      .footer-container & {
        font-size: 16px;
      }
    }

    input[type='text'],
    input[type='url'],
    input[type='email'],
    input[type='tel'],
    input[type='number'],
    input[type='date'],
    select,
    select.mktoField,
    textarea,
    textarea.mktoField {
      padding: 0;
      padding-top: 10px;
      border: 0;
      border-bottom: 0.25px solid black;
      height: 60px;
      position: relative;
      font-size: 24px;
      line-height: 40px;
      font-family: Spezia;
      font-style: normal;
      font-weight: normal;
      width: 100% !important;
      background-color: transparent;

      &:focus {
        outline: none;
      }
    }

    select,
    select.mktoField {
      appearance: none;
      min-height: 60px;

      &.mktoField {
        padding: 0;
      }
    }

    textarea,
    textarea.mktoField {
      height: auto;
      min-height: 60px;
      margin-top: 10px !important;

      @include bp(lg) {
        min-height: 120px;
      }
    }

    .mktoCheckboxList {
      position: relative;
      width: auto !important;

      ~ .mktoClear {
        display: none;
      }

      input {
        align-items: center;
        display: flex;
        height: 30px;
        justify-content: center;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 30px;
        z-index: 9;

        &:checked {
          ~ label {
            &::before {
              border-color: $c-purple-primary;
            }

            &::after {
              background: $c-purple-primary;
              content: '';
              display: block;
              height: 12px;
              width: 12px;
              position: absolute;
              left: 9px;
              top: 9px;
            }
          }
        }
      }

      label {
        display: block;
        font-family: Spezia;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0px;
        margin-left: 40px;

        &::before {
          border: 1px solid $c-black;
          content: '';
          display: block;
          height: 30px;
          left: 0;
          position: absolute;
          top: 0;
          width: 30px;
        }
      }
    }

    .mktoClear {
      &::before {
        border-color: $c-purple-primary;
      }

      &::after {
        background: $c-purple-primary;
        content: '';
        width: 0;
        height: 3px;
        left: 0;
        background-color: $c-purple-primary;
        position: absolute;
        bottom: 0;
        transition: width 0.35s;
      }
    }
  }

  .not-a-marketo-field {
    display: none;
  }

  .marketo-logic-field {
    min-height: 100px;

    .mktoFieldWrap label.mktoLabel {
      transition: none;
      font-family: Spezia;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0px;
      margin-left: 50px;
      margin-top: 8px;
    }
  }
}

.multi-step-form {
  .hidden {
    display: none;
  }

  .marketo-stepper-container {
    display: flex;
    margin-top: 24px;
    font-family: Spezia;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;

    &--hidden {
      display: none;
    }

    .marketo-stepper-step {
      &:after {
        content: '/';
        margin: 0px 6px;
      }
    }
  }

  .marketo-controls-container {
    padding: 0px;
    // width: calc(100% - 60px);
    width: 100% !important;
    top: 14px;

    position: absolute;
    display: flex;
    z-index: 9;
    pointer-events: none;

    @include bp(lg) {
      padding: 0px;
      width: auto;
      top: 0px;
    }

    button {
      display: inline-block;
      pointer-events: all;
      margin-left: auto;
      margin-right: 8px;
      height: 56px;
      padding: 0;
      border: 0px solid transparent;
      margin-top: 35px;
      margin-bottom: 4px;
      background-color: transparent;

      img {
        filter: brightness(0%);
      }
    }
  }

  @include bp(md) {
    &.mobile-multi-step-form {
      .hidden {
        display: block !important;
      }

      .marketo-stepper-container,
      .marketo-controls-container {
        display: none;
      }
    }
  }
}

[data-wrapper-for='mktoOptIn']
  .mktoFieldDescriptor
  .mktoFieldWrap:focus-within
  label.mktoLabel,
[data-wrapper-for='msdyn_gdproptout']
  .mktoFieldDescriptor
  .mktoFieldWrap:focus-within
  label.mktoLabel,
[data-wrapper-for='requestedConsultation']
  .mktoFieldDescriptor
  .mktoFieldWrap:focus-within
  label.mktoLabel {
  font-size: inherit;
  margin-top: 0;
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .mktoCheckboxList {
      input {
        &:checked {
          ~ label {
            &::before {
              border-color: $color;
            }

            &::after {
              background: $color;
            }
          }
        }
      }
    }

    .mktoClear {
      &::after {
        background-color: $color;
      }
    }
  }
}

/* CONTACT US FORM */
.contact-us--container {
  .contact-us--wrapper {
    @include bp(md) {
      max-width: 960px !important;
    }
  }
}

.multi-set-form {
  .mktoForm > .mktoFormRow fieldset {
    border: none;
  }
  .mktoForm > .mktoFormRow,
  .mktoForm > .mktoButtonRow,
  .mktoForm > .mktoFormRow fieldset legend {
    display: none;
  }
  .mktoForm > .mktoButtonRow button[type='button'] {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .mktoForm .mktoCheckboxList > .mktoError {
    right: auto !important;
    bottom: -54px !important;
  }

  .marketo-logic-field .mktoFieldWrap.has-value label.mktoLabel {
    margin-top: -25px;
    font-size: 13.5px;
  }

  .marketo-logic-field .mktoFieldWrap label.mktoLabel {
    font-size: 24px;
    line-height: 60px;
    letter-spacing: normal;
    margin-left: 0px;
    margin-top: -25px;
  }
}

.gated-content--form:has(> .multi-set-form) {
  height: auto;
}
