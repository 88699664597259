.related-reading-container {
  background: $c-white;
  position: relative;

  .related-reading {
    @include container-inset;
    padding: 45px 0 90px;

    @include bp(lg) {
      padding: 115px 0 125px;
    }

    &__button-wrapper {
      margin: 0 0 24px;
      text-align: center;

      > * {
        @include animate-element('text', '.related-reading__button-wrapper');
      }

      &--bottom {
        display: block;
        text-align: center;
        margin-top: 94px;

        > * {
          @include animate-element(
            'text',
            '.related-reading__button-wrapper--bottom'
          );
        }
      }
    }

    &__button {
      &--mobile {
        @include bp(lg) {
          display: none;
        }
      }
    }

    &__header {
      margin-bottom: 55px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      position: relative;

      > * {
        @include animate-element('text', '.related-reading__header');
      }

      @include bp(lg) {
        .heading1 {
          margin: 0;
          max-width: 564px;
          font-size: 36px;
          font-weight: 300;
          line-height: 36px;
        }
      }
    }

    &__readings {
      > * {
        @include animate-element('text', '.related-reading__readings');
        @include animate-delay(4);
      }
    }
  }

  .reading {
    border-bottom: 0.25px solid $c-black;
    margin: 0 0 32px;
    padding: 0 0 16px;

    @include bp(lg) {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      padding: 0 0 32px;
    }

    &:last-child {
      border: 0;
      margin: 0;
      padding: 0;
    }

    &__image {
      margin: 0 0 26px;
      overflow: hidden;
      position: relative;

      @include bp(lg) {
        flex: 0 1 40%;
        height: 221px;
        margin: 0 0 0 42px;
        width: 40%;
      }

      a {
        @extend %button-txt;
        color: $c-black;
        text-decoration: none;

        @include bp(lg) {
          color: $c-white;
        }
      }

      svg {
        fill: $c-black;
        margin: 0 6px 0 0;

        @include bp(lg) {
          fill: $c-white;
        }
      }

      img {
        display: none;
        height: 100%;
        left: 50%;
        object-fit: cover;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.35s;
        width: 100%;

        @include bp(lg) {
          display: block;
        }
      }

      &:hover,
      &--hover {
        img {
          transform: translate(-50%, -50%) scale(110%) !important;
          filter: brightness(0.9);
          // added !important because animate-element @include overwrites any transform - tdeluca - 07092021
        }
      }
    }

    &__type {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;

      span {
        margin: 0 0 0 7px;
      }

      @include bp(lg) {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.66) 67.86%
        );
        bottom: 0;
        left: 0;
        min-height: 75px;
        padding: 0 24px;
        position: absolute;
        right: 0;
      }
    }

    &__content {
      @include bp(lg) {
        flex: 1 0 60%;
        order: -1;
        width: 60%;
      }
    }

    &__meta {
      @include bp(lg) {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        margin: 0 0 22px;
      }
    }

    &__authors {
      cursor: pointer;
      margin: 0 0 20px;

      @include bp(lg) {
        margin: 0 20px 0 0;
      }
    }

    &__date-read {
      margin: 0 0 20px;

      @include bp(lg) {
        margin: 0;
      }

      p {
        margin: 0;
        &.no-bar {
          span {
            &:first-child {
              &::after {
                content: '' !important;
                display: inline-block;
                margin: 0 10px 0 15px;
              }
            }
          }
        }
      }

      span {
        &:first-child {
          &::after {
            content: '|';
            display: inline-block;
            margin: 0 10px 0 15px;
          }
        }
      }
    }

    &__title {
      margin: 0 0 30px;

      a {
        @include animate-underline($c-black);
        color: $c-black;
      }

      h2 {
        margin: 0;
      }
    }

    &__tags {
      position: relative;

      @include bp(lg) {
        display: flex;
        flex-flow: row nowrap;
        overflow: hidden;

        &::after {
          content: '';
          display: block;
          position: absolute;
          z-index: 1;
          top: 0;
          right: 0;
          width: 100px;
          height: 100%;
          background: linear-gradient(90deg, transparent, $c-white 75%);
        }
      }

      a {
        @extend .btn-tag;
        @extend .btn-tag--black;
        display: inline-block;
        margin: 0 13px 13px 0;
        width: auto;

        @include bp(lg) {
          margin: 0 13px 0 0;

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    &--featured {
      .reading__image {
        height: 212px;
        max-height: 212px;
        max-width: 315px;
        width: 100%;

        @include bp(md) {
          height: 302px;
          max-height: 302px;
          max-width: 450px;
        }

        @include bp(lg) {
          flex: 0 1 50%;
          height: 302px;
          margin: 0 42px 0 0;
          width: 50%;
        }

        a {
          color: $c-white;
        }

        svg {
          fill: $c-white;
        }

        img {
          display: block;
        }
      }

      .reading__type {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.66) 67.86%
        );
        bottom: 0;
        left: 0;
        min-height: 75px;
        padding: 0 24px;
        position: absolute;
        right: 0;
      }

      .reading__content {
        @include bp(lg) {
          flex: 1 0 50%;
          order: 0;
          width: 50%;
        }
      }

      .reading__title {
        h3 {
          @extend h2;
        }

        a {
          @include animate-underline($c-purple-primary);
          color: $c-purple-primary;
        }
      }
    }
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .reading--featured {
      .reading__title {
        a {
          @include animate-underline($color);
          color: $color;
        }
      }
    }
  }
}
