.events-container {
  background: $c-gray-soft;
  padding: 102px 0 53px;

  @include bp(lg) {
    padding: 150px 0 263px;
  }
}

.events-container .events--content {
  @include container-inset;
}

.events-container .events--carousel-title {
  margin: 0 0 30px;

  @include bp(md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 60px;
  }

  > * {
    @include animate-element('text', '.events--carousel-title');
  }
}

.events-container .carousel--controls {
  display: none;

  @include bp(md) {
    display: flex;
  }
}

.events-container .glide__bullets {
  margin-left: 0;

  @include bp(md) {
    display: none;
  }
}

.events-container .events--carousel-title h2 {
  margin-bottom: 0;
}

.events-container .glide__slides > .event {
  @include animate-element('text', '.events-container .glide__slides');
  @include animate-delay(6);
  height: auto;
}

.events-container .event .event--date {
  display: flex;
  align-items: center;
  margin: 0 0 18px;
  color: $c-blue-primary;
}

.events-container .event .event--date .event--date-day {
  @extend %h1;
  margin-right: 10px;
}

.events-container .event .event--date .event--date-month-year {
  width: 50px;
  font-family: $font-family-sans;
  font-size: 12px;
  line-height: 2;
}

.events-container .event {

  h4,
  .heading4 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

    a {
      @include animate-underline($c-black);
      color: $c-black;
    }
  }
}

.events-container .event p {
  @extend %copy--sm;
}
