#onetrust {
  &-banner-sdk {
    background: $c-blue-primary !important;
    box-shadow: none !important;
    min-height: 220px !important;
    padding: 20px 0 !important;
    overflow: visible !important;

    @include bp(md) {
      padding: 20px 40px 32px !important;
    }

    * :not(button) {
      color: $c-white !important;
      font-family: $font-family-sans !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0 0 7px !important;
      font-size: 23px !important;
      font-weight: 600 !important;
      line-height: 30px !important;
    }

    p {
      margin: 0 0 20px !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      line-height: 28px !important;
    }

    #onetrust-policy {
      margin: 0 40px 0 0 !important;

      @include bp(md) {
        margin: 0 90px 0 0 !important;
      }
    }

    #onetrust-button-group-parent {
      text-align: left !important;
      top: 0 !important;
      transform: translate(0, 0) !important;
    }

    #onetrust-button-group {
      display: block !important;
      margin: 0 !important;
      width: auto !important;

      @include bp(lg) {
        display: inline-block !important;
      }
    }

    #onetrust-pc-btn-handler,
    #onetrust-accept-btn-handler {
      margin: 12px 0 !important;
      min-width: 182px !important;

      &:focus,
      &:hover {
        opacity: 1 !important;
      }

      @include bp(md) {
        margin: 0 0 12px 0 !important;
      }
    }

    button:not(.onetrust-close-btn-ui) {
      background: transparent !important;
      border-color: $c-white !important;
      border-style: solid !important;
      border-width: 0.5px !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      color: $c-white !important;
      cursor: pointer !important;
      display: block !important;
      font-family: $font-family-sans !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      line-height: 28px !important;
      height: auto !important;
      width: 100% !important;
      outline: none !important;
      padding: 10px 12px !important;
      position: relative !important;
      text-align: center !important;
      text-decoration: none !important;
      transition: color 0.56s !important;
      white-space: nowrap !important;
      z-index: 1 !important;

      &::after {
        background: $c-white;
        bottom: auto;
        left: 0;
        content: '';
        display: block;
        height: 0;
        position: absolute;
        transition: all 0.35s;
        right: 0;
        top: 0;
        z-index: -1;
      }

      &:hover {
        color: $c-blue-primary !important;

        &::after {
          bottom: 0;
          height: 100%;
          top: auto;
        }
      }
    }

    #onetrust-pc-btn-handler {
      color: $c-blue-primary !important;
      background: none !important;
      border-color: $c-white !important;
      border-style: solid !important;
      border-width: 0.5px !important;

      &::after {
        background: $c-white;
        bottom: 0;
        height: 100%;
        top: auto;
      }

      &:hover {
        color: $c-white !important;

        &::after {
          bottom: auto;
          height: 0;
          top: 0;
        }
      }
    }

    #onetrust-close-btn-container {
      top: 0 !important;
      transform: translateY(0) !important;
    }

    .onetrust-close-btn-ui {
      filter: brightness(0) invert(1) !important;
      outline: none !important;
      right: 0 !important;
      top: 12px !important;

      &:focus,
      &:hover {
        opacity: 1 !important;
      }
    }
  }

  &-consent-sdk {
    #onetrust-pc-sdk {
      box-shadow: none !important;

      * :not(button) {
        color: $c-black !important;
        font-family: $font-family-sans !important;
      }

      .pc-close-button {
        margin-right: 10px;
      }

      a {
        @include animate-underline($c-blue-primary);
        color: $c-blue-primary !important;
        padding: 0 !important;
      }

      button:not(.pc-close-button) {
        background: $c-black !important;
        border-color: $c-black !important;
        border-style: solid !important;
        border-width: 0.5px !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        color: $c-white !important;
        cursor: pointer !important;
        display: block !important;
        font-family: $font-family-sans !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 28px !important;
        height: auto !important;
        min-width: 182px !important;
        width: auto !important;
        outline: none !important;
        padding: 10px 12px !important;
        position: relative !important;
        text-align: center !important;
        text-decoration: none !important;
        transition: color 0.56s !important;
        white-space: nowrap !important;
        z-index: 1 !important;

        &::after {
          background: $c-white;
          bottom: auto;
          left: 0;
          content: '';
          display: block;
          height: 0;
          position: absolute;
          transition: all 0.35s;
          right: 0;
          top: 0;
          z-index: -1;
        }

        &:focus,
        &:hover {
          opacity: 1 !important;
        }

        &:hover {
          color: $c-black !important;

          &::after {
            bottom: 0;
            height: 100%;
            top: auto;
          }
        }
      }

      .ot-switch {
        input {
          &:focus {
            border: none !important;
          }
        }

        .ot-switch-inner {
          &::before {
            background-color: $c-blue-pale !important;
          }
        }

        .ot-switch-label {
          background-color: $c-white !important;
          border-color: $c-blue-primary !important;
          outline: none !important;
        }

        .ot-switch-nob {
          background-color: $c-blue-primary !important;
          border-color: $c-blue-primary !important;
        }
      }
    }
  }
}
