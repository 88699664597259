.speakers {
  cursor: pointer;
  display: flex;
  align-items: center;

  &--hide-author-list {
    cursor: auto;
  }
}

.speakers span {
  @extend %button-txt;
  display: block;
  margin-left: 25px;
}

.speakers .speaker {
  position: relative;
  width: 30px;
  height: 30px;

  + .speaker {
    margin-left: -15px;
  }

  img {
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &:nth-of-type(1) {
    z-index: 2;
  }

  &:nth-of-type(2) {
    z-index: 2;
  }
}
