.quote-container {
  background: $c-purple-primary;
  color: $c-white;
  position: relative;
  text-align: center;

  .quote {
    @include container-inset;
    padding: 60px 0 36px;

    @include bp(lg) {
      max-width: 755px;
      padding: 80px 0 56px;
    }

    >* {
      @include animate-element('text', '.quote');
      @include animate-delay(2);
    }

    .icon-wrapper {
      align-items: center;
      background: $c-white;
      border-radius: 50%;
      display: inline-flex;
      height: 38px;
      justify-content: center;
      margin: 0 0 28px;
      width: 38px;

      img,
      svg {
        height: 13px;
        width: 14px;
      }

      svg {
        fill: $c-purple-primary;
      }
    }
  }
}

@each $section,
$color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .quote-container {
      background-color: $color;

      .icon-wrapper {
        svg {
          fill: $color;
        }
      }
    }
  }
}
