.splash {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $c-white;
  z-index: 1000;
  animation: fadeOut 0.7s 2s;
  animation-fill-mode: forwards;
}

.splash > video {
  width: 100%;
  height: 100%;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
}
