.rich-text-basic {
  background: $c-white;
  position: relative;

  .layout-container {
    @include container-inset;
  }

  &__text {
    margin: 0 0 40px;

    h1,
    h2,
    h3,
    .heading1,
    .heading2,
    .heading3 {
      color: $c-purple-primary;
    }

    table {
      display: block;
      margin: 0 auto 20px;
      overflow-x: auto;
      white-space: normal;

      @include bp-down(lg) {
        tbody {

          tr {
            display: flex;
            flex-direction: column;

            td {
              width: 100% !important;
              padding: 10px !important;
              display: flex;
              flex-direction: column;

              img {
                margin: 0 auto;
              }

              p {
                white-space: normal;
              }
            }
          }
        }
      }

      @include bp(lg) {
        display: table;
        margin: 0 0 20px;
        max-width: none;
        width: 100%;
      }
    }

    >* {
      @include animate-element('text', '.rich-text-basic__text');
      @include animate-delay(50);
    }
  }
}

@each $section, $color in $sectionColors {

  .page-container[data-section='#{$section}'] {

    .rich-text-basic {

      &__text {
        margin: 0 0 40px;

        h1,
        h2,
        h3,
        .heading1,
        .heading2,
        .heading3 {
          color: $color;
        }
      }
    }
  }
}
