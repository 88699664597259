.header-container {
  .layout-container {
    max-width: 1200px;
  }
}

.header--intro {
  @include container;
  @include spacer-component;
  position: relative;
}

.header--intro h1 {
  @include animate-element('text', '.header--intro');
  color: $c-blue-primary;

  @include bp(md) {
    max-width: 860px;
  }
}

.header--intro .header--intro-txt {
  @include bp(md) {
    position: absolute;
    top: 82%;
    right: 4%;
    width: 375px;
  }

  @include bp(xl) {
    right: 7%;
    width: 415px;
  }

  // One-off media query sweet spot for header text spacing.
  @media (min-width: 1140px) {
    right: 18%;
  }
}

.header--intro .header--intro-txt > * {
  @include animate-element('text', '.header--intro-txt');
}

.header--intro .header--intro-txt p {
  @extend %copy--sm;
  transition-delay: 0.175s;
}

.header--intro .header--intro-button {
  transition-delay: 0.35s;
}
