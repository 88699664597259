// Global form styling should go here.
// Styling for individual forms those should go in their own partials.

input,
textarea {
  -webkit-appearance: none;
  border: none;
  vertical-align: top;
  margin: 0;
  max-width: 100%;
  font-family: $font-family-sans;
  font-size: 16px;

  @include placeholder {
    // Probably a different color
  }
}

input:focus,
textarea:focus {

}

input:active,
textarea:active {

}

input[type="checkbox"] {
  -webkit-appearance: checkbox;
  width: auto;
}

input[type="radio"] {
  -webkit-appearance: radio;
  width: auto;
}

select {

}

input[type="submit"],
button {
  //probably start by extending .btn
}

label {

}

// sass-lint:disable no-important
fieldset {
  min-width: 0 !important; // Override for Firefox silliness
}

legend {

}

