.insights-container {
  background: $c-gray-soft;
  padding: 60px 0;
}

.insights--wrapper-inner {
  @include bp(lg) {
    @include container-inset;
  }
}

.insights--wrapper .insights--header {
  @include container;
  margin-bottom: 30px;
  // margin-bottom: 60px; // revert back to commented out margin when filters brought back - 092221 - tdeluca
  padding-top: 1px;

  @include bp(lg) {
    @include container-reset;
    margin-bottom: 60px;
    // margin-bottom: 100px; // revert back to commented out margin when filters brought back - 092221 - tdeluca
  }
}

.insights--wrapper .insights--header > * {
  @include animate-element('text', '.insights--header');
  @include animate-delay(3);
  @include bp(lg) {
    @include animate-delay-exact(1 null 2);
  }
}

.insights--wrapper .insights--header .insights--filters-wrap {
  display: none; // revert back to commented out display when filters brought back - 092221 - tdeluca

  @include bp(lg) {
    // display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.insights--wrapper .insights--header .all-insights-desktop {
  display: none;

  @include bp(lg) {
    display: inline-flex;
    flex-shrink: 0;
    margin-top: -8px;
  }
}

.insights--wrapper .insights--header-button-wrapper {
  margin: 0 0 24px;
  text-align: center;
}

.insights--wrapper .insights--header .all-insights-mobile {
  @include bp(lg) {
    display: none;
  }
}

.insights--wrapper .insights--header .insights--filters {
  width: 100%;
  height: 38px;
  border: 0.5px solid $c-black;
  overflow: hidden;

  @include bp(lg) {
    position: relative;
    width: 85%;
    height: auto;
    overflow: hidden;
    display: flex;
    border: 0;

    // Fadeout edge/cutoff.
    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      width: 100px;
      height: 100%;
      background: linear-gradient(90deg, transparent, $c-gray-soft 75%);
    }
  }
}

.insights--wrapper .insights--header .insights--filters button,
.insights--wrapper .insights--header .insights--filters a {
  @extend .btn-tag;
  @extend .btn-tag--black;

  @include bp(lg) {
    display: block;
    width: auto;
    margin-right: 13px;
    margin-bottom: 13px;
    flex-shrink: 0;

    &:last-of-type {
      margin-right: 0;
      border-bottom: 0.5px solid $c-black;
    }
  }
}

.insights--wrapper .insights--header .insights--filters button.mobile-label {
  @extend %btn;
  @include caret(12px);
  display: flex;
  color: $c-black;
  font-weight: $font-weight-medium !important;
  font-size: 11px !important;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  text-transform: uppercase !important;
  width: 100%;

  ~ button {
    width: 100%;

    @include bp(lg) {
      width: auto;
    }
  }

  &::after {
    position: static;
  }

  &:focus {
    color: $c-black;
  }

  &:hover {
    &::after {
      transform: none;
    }
  }

  @include bp(lg) {
    display: none;
  }
}

.insights--wrapper
  .insights--header
  .insights--filters.active
  button.mobile-label::after {
  transform: rotate(-180deg);
}

.insights--carousel .glide {
  position: relative;
}

.insights--content .glide__slides {
  @include bp(lg) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    grid-auto-flow: row dense;
  }
}

@for $rows from 0 through 6 {
  .insights--content .glide__slides.rows-#{$rows} {
    @include bp(lg) {
      @if $rows == 1 {
        grid-template-rows: 300px;
      } @else if $rows == 2 {
        grid-template-rows: 300px 300px;
      } @else if $rows == 3 {
        grid-template-rows: 300px 300px 300px;
      } @else if $rows == 4 {
        grid-template-rows: 300px 300px 300px 300px;
      } @else if $rows == 5 {
        grid-template-rows: 300px 300px 300px 300px 300px;
      } @else if $rows == 6 {
        grid-template-rows: 300px 300px 300px 300px 300px 300px;
      }
    }
  }
}

.insights--content .insight--featured:last-of-type {
  @include bp(lg) {
    grid-column-start: 2;
    grid-row: 3 / 5;
  }
}

.insights--content .insight--featured:first-of-type {
  @include bp(lg) {
    grid-column-start: 1;
    grid-row: 1 / 3;
  }
}

.insights--wrapper .all-insights--bottom {
  display: none;

  @include bp(lg) {
    display: block;
    text-align: center;
    margin-top: 94px;
  }
}

.insights--wrapper .glide__bullets {
  left: 0;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 250px;

  @include bp(sm) {
    top: 500px;
  }
}
