.featured-consultant {
  @include bp(xxl) {
    margin: 0 auto;
    max-width: 960px;
  }

  > * {
    @include animate-element('text', '.featured-consultant');
    @include animate-delay(2);
  }

  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
  margin-top: 70px;
  padding: 0 30px;

  @include bp(lg) {
    flex-direction: row;
    gap: 80px;
    justify-content: space-between;
    margin-bottom: 140px;
    margin-top: 140px;
    padding: 0;
  }

  &--txt {
    flex: 0 1 50%;
    align-self: center;

    @include bp(lg) {
      padding-left: 30px;
    }

    .featured-consultant--name {
      font-size: 60px;
      line-height: 62px;
      color: $c-purple-primary;
      margin-top: 34px;

      @include bp(lg) {
        margin-top: unset;
      }

      h1,
      h2 {
        line-height: 1;
        margin-bottom: 16px;
      }
    }
  }

  &--get-in-touch {
    @extend .btn--black;

    color: $c-white;
    display: inline-block;
    margin-top: 16px;

    @include bp(lg) {
      margin-top: 0;
    }
  }

  &--button {
    display: block;
    margin-top: 32px;
  }

  &--picture {
    flex: 0 1 50%;

    @include bp(lg) {
      order: 1;
    }

    img {
      display: block;
    }
  }

  &--role {
    color: $c-black;
    display: inline-block;
    padding-left: 20px;
    position: relative;
    text-transform: uppercase;

    @include bp(md) {
      position: static;
      padding-left: 0;
    }

    img {
      transform: scale(1.5);
      filter: invert(1);
      margin-right: 7px;
      left: 0;
      position: absolute;
      top: 10px;

      @include bp(md) {
        position: static;
      }
    }

    span {
      display: block;

      @include bp(md) {
        display: inline-block;

        &::after {
          content: '|';
          display: inline-block;
          padding: 0 2px 0 6px;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}
