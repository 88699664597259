.related-business-challenges {
  background: $c-white;
  padding: 50px 0 100px;

  &--gray {
    background: $c-gray-soft;
  }

  .layout-container {
    @include container-inset;
  }

  .link-items {
    > * {
      @include animate-element('text', '.link-items');
      @include animate-delay(9, 2);

      &:nth-child(n + 3) {
        display: none;

        @include bp(lg) {
          display: block;
        }
      }
    }
  }
}
