.freeform-landing-container {
  margin-top: -57px;

  &.section-menu--white {
    margin-top: -161px;
  }

  .layout-container {
    max-width: none;
  }

  &.video {
    position: relative;

    .layout-container {
      margin: 0 auto;
      max-width: 960px;
      padding: 70px;
      position: relative;
      z-index: 1;

      iframe {
        aspect-ratio: 16/9;
        width: 100%;
      }
    }

    &:before {
      background-color: rgba($color: #000000, $alpha: 0.7);
      content: '';
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }

    &.full {

      .layout-container {
        max-width: none;
        padding: 0;
        width: 100%;
      }
    }
  }

  + .postcard-landing--container {
    margin-top: 0;
  }
}

footer {
  width: calc(100% - 60px);
  max-width: 960px;
  margin-left: auto !important;
  margin-right: auto !important;

  @include bp(lg) {
    width: calc(100% - 230px);
  }
}
