.navigation-header--transition {
	transition: all 0.175s ease-in-out;
}

.navigation-header {
	display: none; // flex
	flex-direction: column;
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 5;
	// to animate bg
	background-image: linear-gradient($c-white, $c-white);
	background-size: 0 100%;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	// end animate

	@include bp(lg) {
		flex-direction: row;
	}

	&.no-overlay {

		.navigation-header--image::after {
			display: none;
		}
	}

	&--menu-container {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
    margin-bottom: 60px;
		margin-top: 105px;
		padding: 20px 0 0;
		z-index: 2;
		overflow-y: auto;

		@include bp(md) {
			padding: 40px 30px 40px 29px;
		}

		ul {
			list-style: none;
			padding: 0;

			a {
				text-decoration: none;
				color: $c-black;
				margin: 0;
				text-transform: none;
			}
		}
	}

	&--image {
		display: none;
		overflow: hidden;
		position: relative;
		height: 100%;
		width: 33%;

		&::after {
			background: linear-gradient(to bottom,
				$c-black-40 0,
				$c-black-0 50%,
				$c-black-40 100%);
			bottom: 0;
			content: '';
			display: block;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&--info {
			color: $c-white;
			margin-right: 40px;
			margin-bottom: 40px;
			text-align: end;
			z-index: 3;

			p {
				margin: 0px;
				font-family: $font-family-sans;
				font-weight: 400;
				font-size: 13.5px;
				line-height: 24px;
			}

			&--time {
				margin-bottom: 20px !important;

				span {
					font-family: $font-family-serif;
					font-weight: 300;
					font-size: 48px;
					line-height: 54px;
				}
			}
		}

		@include bp(lg) {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-end;
		}
	}

	&--fixed-background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: black;

		@include bp(lg) {
			display: none;
		}
	}

	&--accordion {
		list-style-type: none;
		margin: 0;
		padding: 0;

		@include bp(lg) {
		}

		&.languages {
			display: flex;

			&:before {
				border-left: 1px solid $c-black;
				content: '';
				height: 1em;
				margin-top: 2px;
				width: 1px;
			}
		}

		li {
			position: relative;
			padding: 0 15px 0 0;
			margin: 0 28px 0 0;
			width: -moz-fit-content;
			width: fit-content;

			&:nth-of-type(1) {
				animation-delay: 0.5s;
			}

			&:nth-of-type(2) {
				animation-delay: 0.75s;
			}

			&:nth-of-type(3) {
				animation-delay: 1s;
			}

			&:last-of-type {
				padding-bottom: 0;
			}

			& > a {
				width: -moz-fit-content;
				width: fit-content;

				&:before,
				&:after {
					content: '';
					@extend .navigation-header--transition;
					background-color: $c-black;
					height: 7px;
					pointer-events: none;
					position: absolute;
						right: 21px;
						top: -4px;
					width: 1px;
				}

				&:before {
					transform: translate(14px, 8px) rotate(45deg);
				}

				&:after {
					transform: translate(18px, 8px) rotate(-45deg);
				}
			}

			input[type='checkbox'] {
				cursor: pointer;
				position: absolute;
				width: 100%;
				height: 100%;
				opacity: 0 !important;

				&:checked {

					& ~ div {
						margin-top: 0;
						max-height: 0;
						opacity: 0 !important;
						transform: translate(0, 50%);
						visibility: hidden;
					}

					& ~ a {

						&:before {
							transform: translate(18px, 8px) rotate(45deg);
						}

						&:after {
							transform: translate(14px, 8px) rotate(-45deg);
						}
					}
				}
			}
		}
	}

	&--title {
		color: $c-black;
		font-size: 20px;
		line-height: 32px;
		font-weight: 400;
		margin: 0;

		@include bp(lg) {
			font-size: 13px;
			font-weight: 600;
			letter-spacing: 1px;
			line-height: 1.15;
			text-transform: uppercase;
			width: fit-content;
		}

		&.language {
			background-image: url(/images/language-icon.svg);
			background-position: left top;
			background-repeat: no-repeat;
			background-size: 16px;
			display: block;
			padding-left: 25px;
		}
	}

	&--tags {
		color: $c-black;
		letter-spacing: 1px;
		position: relative;
		width: -moz-fit-content;
		width: fit-content;
		max-height: 800px;
		@extend .navigation-header--transition;
		opacity: 1;
		transform: translate(0, 0);
		margin-top: 14px;
		font-family: $font-family-sans;
		font-weight: 400;
		font-size: 16px;
		line-height: 28px;

		&:hover span:not(:hover) {
			color: #696969;
		}

		span {
			display: block;
			cursor: pointer;
			width: -moz-fit-content;
			width: fit-content;
			margin-top: 10px;

			&:first-child {
				margin-top: 20px;
			}
		}
	}

	&--contact {
		display: none;
		margin: 45px 0 0 30px;
		width: 490px;

		@include bp(lg) {
			display: block;
		}
	}

	&--default-nav {
		margin: 60px 0 0 0;

		@include bp(lg) {
			margin-left: 30px;
			margin-top: 0;
		}

		li {
			margin-bottom: 21px;
			width: -moz-fit-content;
			width: fit-content;
			@include animate-underline($c-black);

			@include bp(md) {
				margin-bottom: 51px;
			}

			a {
				color: $c-black;
				font-family: $font-family-serif;
				font-size: 20px;
				line-height: 32px;
				font-weight: 400;
				margin: 0;

				@include bp(lg) {
					font-size: 27px;
					line-height: 32px;
				}
			}
		}
	}

	&--utility-navigation-container {
		display: flex;
		flex-direction: column;
		font-size: 16px;
		opacity: 0;
		position: absolute;
		transition: all 0.2s ease-in-out, visibility 0s ease-in-out 0.2s;
		transform: translate(-120%, 0) !important;
		visibility: hidden;
		width: 100%;

		@include bp(lg) {
			display: none;
		}

		.navigation-header--breadcrumbs {

			@include bp(lg) {
				display: none;
			}

			cursor: pointer;
			display: block;
			margin: 10px 29px 0 13px;

			li {
				width: -moz-fit-content;
				width: fit-content;
				font-family: $font-family-sans;
				font-size: 11px;
				font-weight: 600;
				line-height: 20px;
				letter-spacing: 1px;
				text-transform: uppercase;

				&:not(last-child) {
					margin-right: 15px;
				}

				&::before {
					content: '<';
					padding: 2px;
					margin-right: 10px;
					font-weight: 600;
				}

				&.l3-overview {

					&::before {
						display: none;
					}

					a {
						border-bottom: 1px solid $c-black;
						color: $c-gray-light;
						display: block;
						font-family: $font-family-sans;
						font-size: 16px;
						font-weight: 400;
						line-height: 26px;
						margin-left: 17px;
						padding: 20px 0;
					}
				}
			}
		}

		.navigation-header--utility-navigation {
			display: flex;
			flex-direction: column;
			height: 100%;
			margin-left: 30px;
			margin-right: 30px;
			position: relative;

			li {
				cursor: pointer;
				margin-top: 33px;
				font-family: $font-family-serif;
				font-weight: 300;
				line-height: 32px;
				text-decoration: none;
				color: $c-black;
				font-size: 27px;
				text-transform: none;
				padding: 1px;
				position: relative;

				&:first-child {
					margin-top: 0;
				}

				span[data-child],
				span>a {
					@include animate-underline($c-black);
				}

				a {
					display: inline;
					font-family: $font-family-sans;
					font-weight: 400;
					font-size: 16px;
					line-height: 26px;
					color: $c-gray-light;
					width: -moz-fit-content;
					width: fit-content;
				}

				i {
					transform: translate(-6px, 0);
					position: absolute;
					right: 0;
					top: calc(50% - 23px);

					&:before,
					&:after {
						content: '';
						@extend .navigation-header--transition;
						position: absolute;
						background-color: $c-black;
						width: 3px;
						height: 9px;
					}

					&:before {
						transform: translate(-4px, 16px) rotate(-45deg);
					}

					&:after {
						transform: translate(-4px, 20px) rotate(45deg);
					}
				}

				&.language {
					margin-top: 60px;

          &:before {
            content: '';
            border-top: 1px solid $c-gray-lighter;
            width: 100%;
            display: block;
            position: absolute;
            top: -30px;
          }
				}

        &.login {

          @include bp-down('md'){
            margin-top: 18px;
          }
        }

				&.language,
				&.login {
					font-size: 20px;

					span {
						align-items: center;
						display: flex;

						img {
							margin-right: 10px;
							width: 16px;
						}
					}
				}
			}
		}

	    .banner {
	      aspect-ratio: 56/95;
	      background-position: center;
	      background-repeat: no-repeat;
	      background-size: cover;
	      flex: 0 0 33%;
	      padding: 38px;
	      position: relative;

	      &:before {
	        background-color: rgba(0,0,0,0.7);
	        content: '';
	        position: absolute;
	          bottom: 0;
	          left: 0;
	          right: 0;
	          top: 0;
	        z-index: 1;
	      }

	      > * {
	        color: $c-white;
	        position: relative;
	        z-index: 9;
	      }

	      h2 {
	        display: -webkit-box;
	        -webkit-line-clamp: 8;
	        -webkit-box-orient: vertical;
	        overflow: hidden;
	        font-family: $font-family-serif;
	        font-size: 28px;
	        font-weight: 300;
	        line-height: 36px;
	      }

	      a:not(.page-link) {
	        background-image: linear-gradient($c-white, $c-white);
	        background-size: 0 1px;
	        background-position: 100% 100%;
	        background-repeat: no-repeat;
	        transition: background-size .35s;
	        color: $c-white;
	        text-decoration: none;

	        &:hover {
	          background-position: 0 100%;
	          background-size: 100% 1px;
	        }
	      }

	      p {
	        display: -webkit-box;
	        -webkit-line-clamp: 4;
	        -webkit-box-orient: vertical;
	        overflow: hidden;

	        a {
	        	font-size: 13.5px;
	        	line-height: 24px;
	        }
	      }

	      a {

	        &.insight-link,
	        &.insight-link-alt {
	          border: 1px solid $c-white;
	          display: inline-block;
	          font-family: $font-family-sans;
	          font-size: 13px;
	          margin-right: 5px;
	          padding: 10px 20px;
	          text-decoration: none;
	        }
	      }

	      .banner__date {
	        display: inline-grid;
	        grid-template-columns: repeat(3, 1fr);
	        grid-template-rows: repeat(3, 1fr);
	        column-gap: 10px;
	        margin-bottom: 22px;

	        .date-day,
	        .date-month,
	        .date-year {
	          font-family: $font-family-serif;
	          font-weight: 300;
	        }

	        .date-month,
	        .date-year {
	          font-size: 18px;
	          line-height: 26px;
	        }

	        .date-day {
	          grid-column: 1/2;
	          grid-row: 1/4;
	          font-size: 90px;
	          line-height: 80px;
	          letter-spacing: -2px;
	        }

	        .date-month {
	          grid-column: 2/3;
	          grid-row: 1/2;
	        }

	        .date-year {
	          grid-column: 2/3;
	          grid-row: 2/3;
	        }
	      }
	    }
	}
}

.nhun-active {
	height: 100%;
	opacity: 1;
	position: relative;
	transition: all 0.2s ease-in-out;
	transform: translate(0, 0) !important;
	visibility: visible;
}

.nh-active {
	// used to mark whether navigation header is active or not
}

.white-over-image {

	.navigation-header {

		&--title {

			img {
				filter: brightness(0) invert(1);

				.mega-menu--open & {
					filter: brightness(0);
				}
			}

			&.language {
				background-image: url(/images/language-icon-white.svg);
			}
		}
	}

	.mega-menu--open:not(.login--open):not(.language--open) {

		.navigation-header {

			&--title {

				&.language {
					background-image: url(/images/language-icon.svg);
				}

				img {
					filter: brightness(0);
				}
			}
		}

		> nav > .search--trigger svg,
		> .search-mobile > .search--trigger svg {
			filter: brightness(0);
		}
	}
}
