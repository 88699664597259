.simple-cta {
  align-items: center;
  display: flex;
  margin-bottom: 20px;

  &.has-icon {

    &:before {
      background-color: $c-blue-primary;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M10.5376 8.77442C10.3775 8.89832 10.1888 8.96021 10 8.96021C9.81118 8.96021 9.62246 8.89832 9.4624 8.77442L1.81818 2.85477L6.06059e-05 1.44684L0 15.0402C6.06059e-05 15.5704 0.407029 16.0002 0.909088 16.0002L19.0909 16.0002C19.593 16.0002 20 15.5704 20 15.0402V1.44678L18.1818 2.85477L10.5376 8.77442Z' fill='white'/%3e%3cpath d='M10.0003 6.80956L18.7934 6.39645e-05L1.20703 0L10.0003 6.80956Z' fill='white'/%3e%3c/svg%3e ");
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 28px;
      content: '';
      display: block;
      height: 56px;
      justify-content: center;
      margin-right: 10px;
      padding: 28px;
      width: 56px;
    }
  }

  a {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .simple-cta {
      &.has-icon {
        &:before {
          background-color: $color;
        }
      }
    }
  }
}
