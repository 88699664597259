.breadcrumbs-wrapper {
  @include container-inset;
  margin-top: 12px;

  > * {
    @include animate-element('text', '.breadcrumbs-wrapper');
    @include animate-delay(1);
  }

  .breadcrumbs--item {
    font-family: Spezia;
    display: inline-block;
    position: relative;
    vertical-align: top;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: width 0.35s;

    @include bp(xl) {
      white-space: nowrap;
    }

    img {
      width: 5px;
      height: 3.5px;
      margin-bottom: 3px;
      transform: rotate(90deg);
    }

    a {
      color: black;
      text-decoration: none;
      word-break: break-all;
    }

    &:not(:first-child) {
      @include bp(lg) {
        width: 0px;
        overflow: hidden;
      }
    }

    &:last-of-type {
      background: -webkit-linear-gradient(180deg, transparent, #333 30%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

// Updated to adjust margin 10.25.24 BM
