.gated-content--authors {
  > * {
    @include animate-element('text', '.gated-content--authors');
    @include animate-delay(10);
  }
}

.gated-content--wrapper {
  background-color: rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  overflow: hidden;
  cursor: pointer;
  display: block;
  z-index: 500;

  a {
    text-decoration: none;
    color: inherit;
  }

  *::-webkit-scrollbar {
    width: 1px;
  }

  /* Track */
  *::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  *::-webkit-scrollbar-thumb {
    background: #888;
    border: 0px;
  }

  /* Handle on hover */
  *::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &.close {
    animation: GatedContentFadeOut 0.7s 0.7s;
    animation-fill-mode: forwards;
    opacity: 1;
    width: 100%;
    height: 100%;
  }

  &.open {
    animation: GatedContentFadeIn 0.7s;
    animation-fill-mode: forwards;
    opacity: 1;
    width: 100%;
    height: 100%;
    z-index: 500;
  }
}

.gated-content--wrapper:not(.open) .gated-content--container,
.gated-content--wrapper.close .gated-content--container {
  bottom: -100%;
  transition-delay: 0s;
  display: block;
}

.gated-content--wrapper.open .gated-content--container {
  transition-delay: 0.7s;
  display: block;
  bottom: 0;
}

.gated-content--container {
  cursor: auto;
  background-color: white;
  width: 100%;
  height: 100vh;
  transition: all 0.7s;
  bottom: -100%;
  display: none;
  position: absolute;
  z-index: 500;
}

.gated-content--content {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  @include bp(lg) {
    flex-flow: row nowrap;
  }
}

.gated-content--img {
  display: none;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  .show-confirmation & {
    display: block;
    height: 330px;

    @include bp(md) {
      height: 442px;
    }

    @include bp(lg) {
      height: 100%;
    }
  }

  @include bp(lg) {
    display: block;
    width: 33%;
    height: 100%;
  }
}

.gated-content--side {
  height: 100%;
  padding: 32px;

  .show-confirmation & {
    height: calc(100vh - 330px);

    @include bp(md) {
      height: calc(100vh - 442px);
    }

    @include bp(lg) {
      height: 100%;
    }
  }

  @include bp(lg) {
    width: 67%;
  }

  .first-line {
    display: flex;
    right: 32px;
    position: absolute;
    top: 32px;
    width: 100%;
  }

  .gated-content--btn-close {
    margin-left: auto;
    background-color: transparent;
    border: 0px;
    color: $c-black;
    font-family: Spezia;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    height: 20px;
    text-align: center;
    display: flex;
    align-items: center;

    .show-confirmation & {
      color: $c-white;

      @include bp(lg) {
        color: $c-black;
      }
    }

    img {
      filter: grayscale(1) invert(1);
      margin-left: 10px;
      width: 20px;
      height: 20px;

      .show-confirmation & {
        filter: none;

        @include bp(lg) {
          filter: grayscale(1) invert(1);
        }
      }
    }
  }
}

.gated-content--confirmation {
  display: flex;
  flex-flow: column nowrap;
  overflow-y: auto;
  height: 100%;
  justify-content: center;
  align-items: center;

  p {
    font-family: Spezia;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    max-width: 294px;
    margin-left: auto;
    margin-right: auto;
  }

  .heading1 {
    font-family: Teodor;
    font-weight: 300;
    font-size: 60px;
    line-height: 62px;
    text-align: center;
  }
}

.gated-content--form {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: center;
  overflow-x: auto;
  margin: auto;
  max-width: 580px;

  .multi-step-form .marketo-controls-container {
    width: 100% !important;
  }

  .mktoForm {
    max-width: 100%;
    width: 100% !important;
    margin-left: unset;
    margin-right: unset;
  }

  &--flat {
    background: $c-white;
    max-width: none;
    padding: 30px;

    > * {
      margin: 0 auto;
      max-width: 580px;

      @include bp(md) {
        width: 100%;
        max-width: 960px !important;
      }
    }

    form {
      > * {
        @include animate-element('text', '.gated-content--form--flat form');
        @include animate-delay-exact(
          null 1 2 3 4 5 null null null null null null null 6
        );
      }
    }
  }
}

.gated-content--header {
  display: flex;

  .title {
    margin: auto;
    max-width: 580px;

    @include bp(lg) {
      max-width: 763px;
    }

    .heading1 {
      color: $c-black;
      font-family: Teodor;
      font-style: normal;
      font-size: 60px;
      font-weight: 300;
      line-height: 62px;
    }

    p {
      color: $c-gray-lighter;
      font-family: $font-family-sans;
      font-weight: 600;
      font-size: 11px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
}

@keyframes GatedContentFadeOut {
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0.01;
    width: 100%;
    height: 100%;
  }

  100% {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

@keyframes GatedContentFadeIn {
  100% {
    opacity: 1;
  }

  1% {
    opacity: 0.01;
    width: 100%;
    height: 100%;
  }

  0% {
    opacity: 0;
    width: 0;
    height: 0;
  }
}
