.insights--wrapper .insight {
  position: relative;

  @include bp(lg) {
    color: $c-white;
    background: $c-gray-soft;
    padding: 40px;
    overflow: hidden;

    &:hover {
      .insight--image {
        img {
          transform: scale(110%);
        }
      }
    }
  }
}

.insights--wrapper .insight {

  h2,
  .heading2 {
    color: $c-blue-primary;

    a {
      @include animate-underline($c-blue-primary);
      color: $c-blue-primary;
      text-decoration: none;
    }

    @include bp(lg) {
      color: $c-white;
      margin-bottom: 30px;
      order: -1;

      a {
        @include animate-underline($c-white);
        color: $c-white;
      }
    }
  }
}

.insights--wrapper .insight .insight--txt-event {

  h2,
  .heading2 {
    @include bp(lg) {
      margin-bottom: 0;
      order: 0;
    }
  }
}

.insights--wrapper .insight--featured {

  h2,
  .heading2 {
    @include bp(lg) {
      font-size: 60px;
      line-height: 62px;
    }
  }
}

.insights--wrapper .insight .link--button {
  margin-top: 15px;

  @include bp(lg) {
    display: none;
  }
}

.insights--wrapper .insight:not(.insight--featured) {

  h2,
  .heading2 {
    @include bp(lg) {
      font-size: 36px;
    }
  }
}

.insights--wrapper .insight:not(.insight--featured) p {
  display: block;

  @include bp(lg) {
    display: none;
  }
}

.insights--wrapper .insight--image {
  width: 100%;
  height: 250px;
  overflow: hidden;
  margin: 0 0 70px;

  img {
    transition: all 0.35s;
  }

  @include bp(sm) {
    height: 500px;
  }

  @include bp(lg) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 0 40px;

    &::after {
      content: '';
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $c-black-70;
      transition: opacity 0.35s $easeOutCubic;
    }
  }

  &.no-overlay::after {
    display: none;
  }
}

.insights--wrapper .insight.active .insight--image {
  &::after {
    opacity: 1;
  }
}

.insights--wrapper .insight--image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.insights--wrapper .insight--txt {
  @include container;
  padding: 0 0 30px;

  @include bp(lg) {
    @include container-reset;
    display: flex;
    flex-flow: column nowrap;
    padding: 0;
    position: relative;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.35s $easeOutCubic, visibility 0.35s $easeOutCubic;
    z-index: 2;
  }
}

.insights--wrapper .insight--txt p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  @include bp(lg) {
    display: block;
    overflow: visible;
  }
}

.insights--wrapper .insight--txt .date {
  align-items: center;
  display: flex;
  font-family: Teodor;
  margin: 0 0 30px;

  .day {
    font-size: 90px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: -2px;
    line-height: 40px;
    margin-right: 15px;
  }

  .month-year {
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 26px;
  }
}

.insights--wrapper .insight.active .insight--txt {
  @include bp(lg) {
    opacity: 1;
    visibility: visible;
  }
}

.insights--wrapper .insight .insight--tags {
  display: flex;
  flex-wrap: wrap;

  @include bp(lg) {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    overflow: hidden;
    margin-bottom: 25px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      opacity: 0.5;
      width: 100px;
      height: 100%;
      background: linear-gradient(90deg, transparent, $c-black-70 75%);
    }
  }
}

.insights--wrapper .insight .insight--tags button,
.insights--wrapper .insight .insight--tags a {
  @extend .btn-tag;
  @extend .btn-tag--black;
  margin: 0 13px 13px 0;

  @include bp(lg) {
    border-color: $c-white;
    color: $c-white;
    margin: 0 13px 0 0;

    &:visited {
      color: $c-white;
    }

    &::after {
      background: $c-white;
    }

    &:focus,
    &:hover {
      color: $c-black;
    }
  }
}

.insights--wrapper .insight .link--button {
  @include bp(lg) {
    color: $c-white;
    background-image: linear-gradient($c-white, $c-white);

    &::after {
      filter: invert(100%);
    }
  }
}

.insights--wrapper .glide__slides > * {
  @include animate-element('text', '.insights--wrapper .glide__slides');
  @include animate-delay(6);
}
