.cross-links {
  background: $c-gray-soft;
  padding: 50px 0 100px;

  @include bp(lg) {
    padding: 110px 0 100px;
  }

  &--white {
    background: $c-white;
  }

  .layout-container {
    @include container-inset;
  }

  &__carousel {
    @include bp(lg) {
      display: none;
    }
  }

  &__list {
    display: none;

    @include bp(lg) {
      display: block;
    }
  }

  .glide__bullets {
    justify-content: center;
  }

  .link-items {
    > * {
      @include animate-delay(9, 2);
    }
  }

  .glide__slide {
    > * {
      @include animate-element('text', '.glide__slide');
      @include animate-delay(2);
    }
  }
}
