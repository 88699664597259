.single-consultant-profile-alt {
  display: flex;
  flex-direction: column;

  @include bp(lg){
    flex-direction: row;
  }

  > * {
    @include animate-element('text', '.single-consultant-profile-alt');
    @include animate-delay(3);
  }

  &--content {
    margin: 0 auto;
    max-width: 1020px;
  }

  &--image {
    flex: 0 0 auto;
    padding: 0 30px;
    width: 100%;

    .single-consultant-profile-left & {
      @include bp(lg){
        display: none;
      }
    }

    .single-consultant-profile-right & {
      @include bp(lg){
        margin-bottom: 40px;
      }
    }

    @include bp(lg) {
      flex-basis: 392px;
      height: 100%;
      max-height: 463px;
      overflow: hidden;
      padding-right: 30px;
      width: 392px;
      z-index: 0;
    }

    &-background {
      display: flex;
      position: relative;

      .single-consultant-profile-left & {
        height: 142px;
        padding: 71px;
        width: 142px;
      }

      @include bp(lg){
        height: 100%;
        padding: 100% 40px 40px;
        width: 100%;
      }

      .image-background {
        background-position: center;
        background-size: cover;
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;

        &--tablet-portrait,
        &--tablet-landscape,
        &--desktop {
          display: none;
        }

        @include bp(md) {
          &--mobile,
          &--tablet-landscape,
          &--desktop {
            display: none;
          }

          &--tablet-portrait {
            display: block;
          }
        }

        @include bp(xl) {
          &--mobile,
          &--tablet-portrait,
          &--desktop {
            display: none;
          }

          &--tablet-landscape {
            display: block;
          }
        }

        @include bp(xl-large) {
          &--mobile,
          &--tablet-portrait,
          &--tablet-landscape {
            display: none;
          }

          &--desktop {
            display: block;
          }
        }
      }

      @include bp(lg) {
        background-position: center;

        // &::after {
        //   background: linear-gradient(
        //     180deg,
        //     rgba(0, 0, 0, 0) 0%,
        //     rgba(0, 0, 0, 0.51) 67.86%
        //   );
        //   bottom: 0;
        //   content: '';
        //   display: block;
        //   height: 126px;
        //   left: 0;
        //   opacity: 0;
        //   position: absolute;
        //   transition: opacity 0.35s;
        //   right: 0;
        //   z-index: 0;
        // }
      }

      a {
        display: none;
        margin-top: auto;
        margin-right: 33px;
        position: relative;
        z-index: 1;

        @include bp(lg) {
          display: inline;
        }

        img {
          width: 21px;
          filter: brightness(0) invert(1);
        }
      }

      // &:focus,
      // &:hover {
      //   @include bp(lg) {
      //     &::after {
      //       opacity: 1;
      //     }
      //   }
      // }
    }
  }

  &--txt {
    order: 2;
    padding: 0 30px;

    @include bp(lg) {
      flex: 1 1 auto;
      order: 1;
    }

    .single-consultant-profile-alt--name {
      font-size: 60px;
      line-height: 62px;
      color: $c-purple-primary;
      margin-top: 34px;

      @include bp(lg) {
        margin-top: unset;
      }

      h1,
      h2 {
        font-size: 34px;
        line-height: 42px;
        margin-bottom: 16px;

        @include bp(lg) {
          font-size: 60px;
          line-height: 62px;
        }
      }
    }
  }

  &--tags {
    display: flex;
    margin-right: 30px;
    margin-bottom: 34px;
    padding-left: 20px;
    position: relative;

    img {
      transform: scale(1.5);
      filter: invert(1);
      position: absolute;
      left: 5px;
      top: 5px;
    }

    @include bp(lg) {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 40px;
    }

    .location {
      font-family: $font-family-sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      a {
        color: $c-black;
        display: inline-block;
        text-decoration: none;

        &:visited {
          color: $c-black;
        }

        span {
          display: block;
        }
      }
    }
  }

  &--social-network-links {
    margin-bottom: 31px;

    @include bp(lg) {
      display: none;
    }

    > a {
      margin-right: 33px;
      text-decoration: none;

      img {
        width: 21px;
        height: 21px;
      }
    }
  }

  &--description {
    font-size: 20px;
    line-height: 32px;

    @include bp(lg){
      font-size: 24px;
      line-height: 40px;
    }
  }

  &--get-in-touch {
    @extend .btn--black;

    color: white;
    display: inline-block;
    margin-bottom: 70px;
    margin-top: 16px;

    @include bp(lg) {
      margin-top: 0;
    }
  }

  &--getintouch {

    h4,
    .heading4 {
      border-bottom: 1px solid #000000;
      font-size: 11px;
      line-height: 20px;
      margin-bottom: 25px;
      padding-bottom: 5px;
      text-transform: uppercase;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      margin: 0;
      max-width: 200px;
      padding: 0;

      li {
        margin: 0 30px 30px 0;

        &.linkedin,
        &.twitter {
          width: 34px;

          a {

            img {
              width: 100%;
            }
          }
        }

        a {

          &.desktop-only {
            display: none;

            @include bp(lg){
              display: list-item;
            }
          }

          &.mobile-only {
            display: list-item;

            @include bp(lg){
              display: none;
            }
          }
        }
      }
    }
  }

  &--social-share {
    position: absolute;
    // display: flex;
    display: none; // temporarily hide until integration - tdeluca - 20210825  right: 0;
    margin: 50px 0;
    top: 0;
    width: 56px;

    @include bp(sm) {
      width: 97px;
    }

    @include bp(lg) {
      bottom: 0;
      height: calc(100vh - 194px);
      margin: 104px 0px;
      width: 212px;
    }

    > div {
      margin: auto;
    }

    a {
      display: flex;
      width: 19px;
      height: 24px;
      margin: 44px 0px;

      @include bp(lg) {
        border: 0.5px solid $c-gray-lighter;
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      img {
        margin: auto;
        filter: brightness(0) invert(1);

        @include bp(lg) {
          filter: unset;
        }
      }
    }
  }
}

// For the cloned navbar
.navigation-blend-single-consultant-profile-alt {
  z-index: 2;
  position: absolute;
  color: white;
  overflow-x: hidden;
  width: 100vw;

  @include bp(lg) {
    width: 392px;
  }

  .navigation {
    width: calc(100vw - 60px);

    @include bp(lg) {
      width: calc(100vw - 72px);
    }

    border-bottom-color: white;
    fill: white;

    svg * {
      filter: brightness(0) invert(1);
    }

    > .menu--trigger span {
      background: white;
    }

    nav a {
      color: white;

      &.expanded::after {
        filter: brightness(0) invert(1);
      }
    }
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .single-consultant-profile-alt--name {
      color: $color;
    }
  }
}
