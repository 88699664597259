.sc-order-item:nth-of-type(4) {
  display: none;
}

@include bp(lg) {
  .sc-order-item:nth-of-type(2) {
    display: none;
  }

  .sc-order-item:nth-of-type(4) {
    display: block;
  }
}

.related-consultants--view-all {
  display: flex;
  justify-content: center;
  font-family: $font-family-sans;
  font-weight: $font-weight-bold;
  font-size: 11px;
  line-height: 20px;
  margin-top: 32px;
  margin-bottom: 48px;
}

.related-consultants-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $c-white;
  padding: 135px 0 135px;

  > * {
    @include animate-element('text', '.related-consultants-container');
    @include animate-delay(4);
  }

  @include bp(lg) {
    > * {
      @include animate-delay-exact(1 null 2 6);
    }
  }

  &--no-view-all {
    > * {
      @include animate-element('text', '.related-consultants-container');
      @include animate-delay-exact(1 null 2);
    }

    .related-consultants--card-row ul {
      > * {
        @include animate-delay(4, 1);
      }
    }

    .related-consultants--view-all.sc-order-item {
      display: none;
    }
  }
}

.related-consultants--carousel-title {
  .heading1 {
    margin: 0 auto;
    padding: 0 68px;
    text-align: center;
    font-family: $font-family-serif;
    font-weight: $font-weight-light;
    font-size: 28px;
    line-height: 36px;

    @include bp(lg) {
      font-weight: $font-weight-regular;
      font-size: 36px;
      line-height: 48px;
    }
  }
}

.related-consultants-container .related-consultants--carousel-title {
  margin: 0 0 30px;

  @include bp(md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 60px;
  }
}

.related-consultants-container .glide__bullets {
  margin: 0;

  @include bp(md) {
    display: none;
  }
}

.related-consultants-container .event p {
  @extend %copy--sm;
}

.related-consultants--card-row {
  max-width: 957px;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style-type: none;

    > * {
      @include animate-element('text', '.related-consultants--card-row ul');
      @include animate-delay(4, 2);
    }

    @include bp(lg) {
      > * {
        @include animate-delay(4, 1);
      }
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: $font-family-sans;
      font-weight: $font-weight-regular;
      margin-bottom: 40px;
      text-decoration: none;
      color: $c-black;

      @include bp(lg) {
        align-items: flex-start;
        margin-bottom: 0;
      }

      &:hover,
      &:visited,
      &:link,
      &:active {
        text-decoration: none;
        color: $c-black;
      }
    }

    .related-consultants--picture {
      height: 130px;
      width: 130px;
      border-radius: 50%;
      align-self: center;
      overflow: hidden;

      @include bp(lg) {
        align-self: flex-start;
      }

      img {
        height: 100%;
        object-fit: cover;
        transition: all 0.35s;
        width: 100%;
      }

      &:hover {
        img {
          transform: scale(110%) !important;
          // added !important because animate-element @include overwrites any transform - tdeluca - 07092021
        }
      }
    }

    .related-consultants--info {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      margin-top: 10px;
      align-content: space-between;

      h4 {
        margin: 0;
        padding: 0;
        font-weight: $font-weight-regular;
        font-size: 16px;
      }

      > span {
        // city
        display: block;
        font-size: 11px;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        line-height: 20px;
        padding-left: 15px;
        position: relative;
        text-align: left;

        &::before {
          content: '';
          display: inline-block;
          background: url('../images/pinpoint.svg') no-repeat;
          width: 8px;
          height: 10px;
          margin-right: 7px;
          left: 0;
          position: absolute;
          top: 5px;
        }

        span {
          display: block;
        }
      }

      .related-consultants--description {
        margin: 8px 0 14px 0;

        span {
          display: block;
          font-size: 13.5px;
          line-height: 24px;
        }
      }

      @include bp(lg) {
        align-items: flex-start;
      }
    }

    @include bp(md) {
      flex-flow: row wrap;
      justify-content: center;
      margin-bottom: 60px;
      li {
        flex-basis: 50%;
      }
    }

    @include bp(lg) {
      flex-flow: row nowrap;
      li {
        flex-basis: auto;
        &:nth-child(-n + 3) {
          margin-right: 30px;
        }
      }
    }
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .related-consultants-container {
      background-color: $color;
      color: $c-white;
      a {
        color: $c-white !important;
        &:hover,
        &:visited,
        &:link,
        &:active {
          color: $c-white;
        }

        &::after,
        span::before {
          filter: invert(1);
        }

        &.link--button {
          background-image: linear-gradient($c-white, $c-white);
        }
      }
    }
  }
}
