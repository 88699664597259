.split-blockquote-image {
  background: $c-white;
  position: relative;

  .layout-container {
    @include bp(xxl) {
      margin: 0 auto;
      max-width: 960px;
    }
  }

  &-wrapper {
    display: flex;
    flex-flow: column nowrap;
    padding: 80px 30px;

    @include bp(lg) {
      flex-flow: row nowrap;
      gap: 40px;
      padding: 160px 0 100px;
    }

    > * {
      @include animate-element('text', '.split-blockquote-image-wrapper');
      @include animate-delay(2);
    }
  }

  &__content {
    width: 100%;

    @include bp(lg) {
      padding: 70px 0 70px 30px;
    }

    @include bp(xxl) {
      padding-left: 0;
    }

    &-container {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      height: 100%;

      blockquote {
        @extend %h3;
        color: $c-black;
        margin: 0;
        position: relative;

        > * {
          @include animate-element('text', 'blockquote');
          @include animate-delay(2);
        }

        .icon-wrapper {
          margin: 0 0 15px;

          @include bp(lg) {
            float: left;
            height: 38px;
            position: absolute;
            top: 0;
            width: 38px;

            ~ .sbi-paragraphs {
              > *:first-of-type {
                text-indent: 55px;
              }
            }
          }
        }

        p {
          @extend %h3;
        }
      }

      .sbi-paragraphs {
        > * {
          @include animate-element('text', '.sbi-paragraphs');
          @include animate-delay(4);
        }
      }
    }
  }

  &__image {
    margin: 0 0 35px;
    order: -1;

    @include bp(lg) {
      flex: 0 0 470px;
      order: 0;
      width: 470px;

      &-container {
        position: sticky;
        top: 0;
      }
    }
  }

  img {
    width: 100%;

    @include bp(lg) {
      width: auto;
    }
  }

  &--left {
    @include bp(lg) {
      .split-blockquote-image__content {
        padding: 70px 30px 70px 0;
      }

      .split-blockquote-image__image {
        order: -1;
      }
    }

    @include bp(xxl) {
      .split-blockquote-image__content {
        padding-right: 0;
      }
    }
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .split-blockquote-image {
      &__content {
        p {
          color: $color;
        }
      }
    }
  }
}
