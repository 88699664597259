.accordion-container {

  @include bp(lg) {

    &.small {

      .accordion-body {
        grid-column: 1 /span 6;
      }
    }

    &.medium {

      .accordion-body {
        grid-column: 1 /span 8;
      }
    }

    &.large {

      .accordion-body {
        grid-column: 1 /span 10;
      }
    }

    &.full {

      .accordion-body {
        grid-column: 1 /span 12;
      }
    }
  }

  &.fullbleed {
    background-color: $c-gray-soft;

    .accordion {

      &-header {
        background-color: $c-gray-soft;
      }

      &-body {
        background-color: $c-gray-soft;
      }
    }
  }

  &.background {

    .accordion {

      &-header {
        background-color: $c-blue-primary;
        grid-column: 1 /span 12;
        padding-bottom: 72px;

        @include bp(lg) {
          margin-left: calc(((100dvw - 956px) / 2)* -1);
          padding: 48px 96px 72px calc((100dvw - 956px) / 2);
        }

        h2, p {
          color: $c-white;
        }
      }

      &-body {
        margin-top: -48px;
        padding: 0 16px 60px;
      }
    }
  }

  &.background-image {

    .accordion {

      &-header {
        //background-image: url('/images/accordion-default.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        grid-column: 1 /span 12;
        padding-bottom: 72px;

        @include bp(lg) {
          margin-left: calc(((100dvw - 956px) / 2)* -1);
          padding: 48px 96px 72px calc((100dvw - 956px) / 2);
        }

        h2,
        p {
          color: $c-white;
        }
      }

      &-body {
        margin-top: -48px;
        padding: 0 16px 60px;
      }
    }
  }

  .layout-container {
    max-width: 956px;
  }

  .accordion {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    &-header {
      background-color: $c-white;
      grid-column: 1 /span 12;
      padding: 48px 30px 8px;

      @include bp(lg) {
        padding: 48px 96px 8px 0;
      }

      h2 {
        margin-bottom: 20px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
      }
    }

    &-body {
      background-color: $c-white;
      grid-column: 1 /span 12;
      margin: 0 30px;
      padding-bottom: 60px;

      @include bp(lg) {
        margin: 0;
      }

      &.gray {
        background-color: $c-gray-soft;
      }

      &__item {
        border-bottom: 1px solid $c-black;
        padding: 12px 0;

        &--title {
          align-items: flex-start;
          cursor: pointer;
          display: flex;
          justify-content: space-between;

          h3 {
            font-size: 24px;
            font-weight: 400;
            line-height: 40px;
            margin-bottom: 0;
          }

          .item-icon {
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZmlsbD0ibm9uZSIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTYuMzQzMTcgNy43NTczMkw0LjkyODk2IDkuMTcxNTRMMTIgMTYuMjQyNkwxOS4wNzExIDkuMTcxNTdMMTcuNjU2OSA3Ljc1NzM1TDEyIDEzLjQxNDJMNi4zNDMxNyA3Ljc1NzMyWiIgZmlsbD0iY3VycmVudENvbG9yIi8+PC9zdmc+);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            margin: 6px;
            padding: 14px;
          }
        }

        &--body {
          display: none;
          padding-top: 10px;

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
          }
        }

        &.expand {

          .accordion-body__item--title {

            h3 {
              color: $c-blue-primary;
            }

            .item-icon {
              transform: rotate(180deg);
            }
          }

          .accordion-body__item--body {
            display: block;
          }
        }
      }
    }
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {

    .accordion-container {

      &.background {

        .accordion {

          &-header {
            background-color: $color;
            color: $c-white;
          }
        }
      }

 //     &.background-image {

 //       .accordion {

 //         &-header {
 //           background-image: url('/images/accordion-#{$section}.png');
 //         }
 //       }
 //     }

      .accordion {

        &-body {

          &__item {

            &.expand {

              .accordion-body__item--title {

                h3 {
                  color: $color;
                }
              }
            }
          }
        }
      }
    }
  }
}
