button {
  cursor: pointer;
}

.btn,
%btn {
  @extend %button-txt;
  cursor: pointer;
  display: block;
  height: 40px;
  width: auto;
  padding: 8px 25px;
  background: none;
  border-style: solid;
  border-width: 0.5px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 0.56s;
  white-space: nowrap;
  z-index: 1;

  svg path {
    transition: fill 0.56s;
  }

  &::after {
    background: transparent;
    bottom: 0;
    left: 0;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.35s;
    right: 0;
    top: auto;
    z-index: -1;
  }

  &:focus,
  &:hover {
    &::after {
      bottom: auto;
      height: 0;
      top: 0;
    }
  }

  @include bp(lg) {
    width: auto;
  }
}

%btn-reset {
  width: auto;
  padding: 0;
}

.btn--black {
  @extend %btn;
  border-color: $c-black;
  color: $c-white;

  &:visited {
    color: $c-white;
  }

  &::after {
    background: $c-black;
  }

  &:focus,
  &:hover {
    color: $c-black;

    svg {
      path {
        fill: $c-black;
      }

      line {
        stroke: $c-black;
      }
    }
  }
}

.btn--white {
  @extend %btn;
  border-color: $c-white;
  color: $c-black;

  &:visited {
    color: $c-black;
  }

  &::after {
    background: $c-white;
  }

  &:focus,
  &:hover {
    color: $c-white;

    svg {
      path {
        fill: $c-white;
      }

      line {
        stroke: $c-white;
      }
    }
  }
}

.link--button,
%link--button {
  @extend %button-txt;
  @include animate-underline-reverse($c-black);
  display: inline-flex;
  align-items: center;
  color: $c-black;

  &:visited {
    color: $c-black;
  }

  &::after {
    content: '';
    display: block;
    background: url('../images/arrow.svg') center right no-repeat;
    width: 15px;
    height: 9px;
    margin-top: -1px;
    margin-left: 12px;
    filter: brightness(0.5) sepia(1) hue-rotate(-70deg) saturate(5);
  }
}

.link--button-white,
%link--button-white {
  @extend %link--button;
  color: $c-white;
  background-image: linear-gradient($c-white, $c-white);

  &:visited {
    color: $c-white;
  }

  &::after {
    filter: invert(100%);
  }
}

.filter--button,
%filter--button {
  display: block;
  padding: 4px 16px;
  font-family: $font-family-sans;
  font-size: 13px;
  line-height: 2.15;
  border: 0.5px solid $c-black;
  margin-right: 13px;
  margin-bottom: 13px;
  background: none;

  &:last-of-type {
    margin-right: 0;
  }
}

.btn:focus {
}

.btn:hover,
.btn:focus {
}

.btn:active {
}
