.share-flyout {
  align-items: center;
  // display: flex;
  display: none; // temporarily hide until integration - tdeluca - 20210825  right: 0;
  flex-flow: row nowrap;
  margin: 20px 0;
  min-height: 44px;
  overflow: hidden;

  &__icon {
    align-items: center;
    background: $c-white;
    border: 1px solid $c-gray-lighter;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    height: 34px;
    justify-content: center;
    position: relative;
    transition: all 0.35s;
    width: 34px;
    z-index: 1;

    &:focus,
    &:hover {
      height: 44px;
      width: 44px;
    }
  }

  &__links {
    align-items: center;
    display: flex;
    opacity: 0;
    transform: translateX(-100%);
    transition: all 0.35s;

    a {
      display: inline-block;
      padding: 9px;
    }
  }

  &.show-links {
    .share-flyout__icon {
      height: 44px;
      width: 44px;
    }

    .share-flyout__links {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
