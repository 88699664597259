.search-form {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  height: 100vh;
  margin: -162px 0 0;
  max-height: 100vh;
  position: relative;
  transition: max-height 0.2s;
  z-index: 2;

  .layout-container {
    @include container-inset;

    > * {
      @include animate-element('text', '.layout-container');
      @include animate-delay(2);
    }
  }

  h1 {
    color: $c-blue-primary;
    margin: 0 0 40px;
    max-width: 315px;

    @include bp(md) {
      font-size: 34px;
      line-height: 42px;
    }

    @include bp(lg) {
      font-size: 60px;
      line-height: 62px;
      margin: 0 0 50px;
      max-width: 450px;
    }
  }

  &__input-wrapper {
    border-bottom: 1px solid $c-black;
    display: flex;
    flex-flow: row nowrap;
    padding: 0 0 25px;
    position: relative;

    &::after {
      background: $c-blue-primary;
      bottom: 0;
      content: '';
      display: block;
      height: 4px;
      left: 0;
      position: absolute;
      transition: all 0.2s;
      width: 0;
    }
  }

  &__button {
    background: none;
    border: 0;
    margin: 0 25px 0 0;
    padding: 0;

    img,
    svg {
      height: 20px;
      width: 20px;

      path {
        fill: $c-black;
      }
    }
  }

  &__input {
    @extend %h2;

    appearance: none;
    background: none;
    border: 0;
    outline: none;
    width: 100%;

    &::-webkit-search-cancel-button {
      display: none;
    }
  }

  .search_autocomplete {
    background: $c-white;
    left: 0;
    margin: 0;
    max-height: 300px;
    overflow: auto;
    padding: 0 0 0 5px;
    position: absolute;
    right: 0;
    top: calc(100% + 1px);
    transition: all 0.2s;

    #cludo-search-results & {
      ul {
        margin: 0;
        padding: 0;

        li {
          cursor: pointer;
          margin: 25px 0;
        }

        &:hover {
          color: $c-gray-light-fade;

          li {
            transition: all 0.2s;

            &:hover {
              color: $c-black;
            }
          }
        }
      }
    }
  }

  &__form {
    position: relative;

    &.active {
      .search-form__input-wrapper {
        &::after {
          width: 100%;
        }
      }
    }
  }
}
