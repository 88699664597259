.event-registration--container {
  position: relative;
  padding-bottom: 110px;

  @include bp(sm) {
    padding-bottom: 135px;
  }

  @include bp(lg) {
    padding-bottom: 137px;
  }

  @include bp(xxl) {
    padding: 0 0 161px 0;
  }

  .event-registration--wrapper {
    @include container-inset;

    background: $c-white;

    @include bp(lg) {
      margin-top: -60px;
      padding: 30px;
    }

    h2 {
      text-align: center;
      color: $c-purple-primary;
    }
  }

  .event-registration--form {
    .mktoForm {
      margin-left: 0;
      margin-right: 0;
      width: 100% !important;
      max-width: 100%;

      > * {
        @include animate-delay-exact(
          null 1 2 3 4 5 6 7 null null null null 8 9 10
        );
      }
    }

    .mktoFormRow {
      width: 100%;
    }

    @include bp(md) {
      width: 100%;
    }

    @include bp(lg) {
      margin: 0 auto;
    }
  }

  .event-registration--confirmation {
    text-align: center;
  }

  .event-registration--header {
    display: flex;

    .title {
      color: $c-purple-primary;

      .heading1 {
        font-family: Teodor;
        font-style: normal;
        font-weight: 300;
        font-size: 34px;
        line-height: 42px;

        @include bp(lg) {
          font-size: 60px;
          line-height: 62px;
        }
      }

      @include bp(lg) {
        margin-right: 74px;
      }
    }
  }

  .event-registration--fields {
    // position: relative;
    // margin-top: 12px;
    // width: 100%;
    // display: flex;
    // flex-direction: column;
    // flex-wrap: wrap;
    // min-height: 60px;
    // height: auto;

    // .input--wrapper {
    //   display: block;
    //   position: relative;
    //   transition: height 0.4s 0s;

    //   &.active {
    //     height: 70px;
    //   }

    //   height: auto;
    //   overflow: inherit;
    //   margin-top: 35px;

    //   flex-grow: 1;
    //   width: 100%;

    //   &:focus-within label,
    //   &.has-content label {
    //     margin-top: -25px;
    //     font-size: 13.5px;
    //   }

    //   &:nth-child(4) {
    //     @include bp(lg) {
    //       margin-top: 62px;
    //     }
    //   }

    //   label {
    //     pointer-events: none;
    //     position: absolute;
    //     font-size: 24px;
    //     font-family: Spezia;
    //     font-size: 24px;
    //     font-style: normal;
    //     font-weight: 400;
    //     line-height: 60px;
    //     letter-spacing: 0px;
    //     text-align: left;
    //     transition: margin-top 0.5s ease 0s, font-size 0.5s;
    //   }

    //   .input {
    //     padding-top: 10px;
    //     border-bottom: 0.25px solid black;
    //     height: 60px;
    //     position: relative;
    //     font-size: 24px;
    //     line-height: 40px;
    //     font-family: Spezia;
    //     font-style: normal;
    //     font-weight: normal;

    //     &.text-area {
    //       height: auto;
    //       min-height: 60px;

    //       @include bp(lg) {
    //         min-height: 120px;
    //       }
    //     }

    //     &::after {
    //       content: '';
    //       width: 0;
    //       height: 3px;
    //       left: 0;
    //       background-color: $c-purple-primary;
    //       position: absolute;
    //       bottom: 0;
    //       transition: width 0.5s;
    //     }

    //     &:focus {
    //       outline: none;

    //       &::after {
    //         width: 100%;
    //       }
    //     }
    //   }
    // }
  }

  .submit-btn {
    @include animate-delay(2, 2);
    margin-top: 50px;
    display: inline-block;
    transition-delay: 0.8s !important;
  }
}

@each $section, $color in $sectionColors {
  .page-container[data-section='#{$section}'] {
    .event-registration--header {
      .title {
        color: $color;
      }
    }
  }
}
